import React, { Component } from "react";
import GlobalConfig from "../../Config";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import * as XLSX from "xlsx";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { green, grey } from "@material-ui/core/colors";
import helpers from "../../UtilitiesFunctions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { connect } from "react-redux";
import Loader from "../../MobileComponents/Loader";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import * as localForage from "localforage";

import { Link } from "react-router-dom";
import { CreateRounded, PrintOutlined } from "@material-ui/icons";
import { Info, QrCode, ReceiptLong, SyncOutlined } from "@mui/icons-material";
import { ReceiptEditModal } from "../../components/print/receiptEditModal";
import { ThermalPrinterView } from "../../components/print/thermalPrinterView";
import { DotMatrixPrinterView } from "../../components/print/dotMatrixPrinterView";
import Store from "../../index.js";
import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  cardRoot: {
    maxWidth: 345,
    marginBottom: "10px",
    padding: "0px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: green[500],
  },
  ordersecondheader: {
    // marginLeft: "50%",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    // width: "100%",

    [theme.breakpoints.only("xs")]: {
      justifyContent: "flex-start",
      display: "flex",
      flexDirection: "row",
      marginLeft: "0%",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const options = [
//   { label: "Create Purchase Order", path: "/ViewPurchaseOrders" },
//   { label: "Printer Receipt Setting", path: "/printer-receipt-setting" },
//   { label: "Print Server Setting", path: "/print-server-setting" },
// ];

const ITEM_HEIGHT = 48;

class ViewPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    console.log("ORDERS PROPS: ", this.props);
    this.state = {
      printReciept: false,
      showReceiptEditModal: false,
      goingForPrinting: false,
      ordersFetched: false,
      categoryModal: false,
      orders: this.props.g_orders ? this.props.g_orders : [],
      singleOrderDetail: [],
      rows: [],
      dataRows: [],
      loader: false,
      syncingLoader: true,
      anchorEl: null,
      returnedItems: [],
      customerData: null,
      filterOrders: this.props.g_orders ? this.props.g_orders : [],
      syncOrderBtn: true,
      syncBtnLoader: false,
      indexDbOrders: 0,
      indexDbOrdersKeys: [],
      customerdetail: [],
      tempOrder: [
        {
          expanded: false,
          active: true,
          close: false,
          createdAt: "2021.09.20.16.28.15.764",
          customerId: "2",
          draft: false,
          fulfilled: false,
          fulfillmentDate: "null",
          lineItems: [
            {
              costPrice: 75,
              fulfilled: false,
              fulfillmentDate: "null",
              id: "1031",
              price: 80,
              productId: "1",
              productName: "Lays Chilli Flavour",
              quantity: 4,
              rejected: false,
              returned: false,
              status: "Unfulfilled",
              subTotal: 320,
              tax: 11,
              taxAmount: 35.2,
              total: 355.2,
              unitOfMeasure: "1",
            },
            {
              costPrice: 9,
              fulfilled: false,
              fulfillmentDate: "null",
              id: "1032",
              price: 10,
              productId: "2",
              productName: "Prince Biscuit",
              quantity: 5,
              rejected: false,
              returned: false,
              status: "Unfulfilled",
              subTotal: 50,
              tax: 11,
              taxAmount: 5.5,
              total: 55.5,
              unitOfMeasure: "2",
            },
          ],
          orderId: "1027",
          paid: true,
          partiallyFulfilled: false,
          paymentDate: "2021-09-20",
          paymentId: "17",
          returned: false,
          returnedAmount: 0,
          status: "Active",
          subTotal: 370,
          taxAmount: 40.700012,
          taxRate: 11.000004,
          total: 410.7,
        },
        {
          expanded: true,
          active: false,
          close: true,
          createdAt: "2021.09.15.18.34.40.286",
          customerId: "2",
          draft: false,
          fulfilled: true,
          fulfillmentDate: "2021-09-16",
          lineItems: [
            {
              costPrice: 75,
              fulfilled: true,
              fulfillmentDate: "2021-09-16",
              id: "1021",
              price: 80,
              productId: "1",
              productName: "Lays Chilli Flavour",
              quantity: 1,
              rejected: false,
              returned: false,
              status: "fulfilled",
              subTotal: 80,
              tax: 11,
              taxAmount: 8.8,
              total: 88.8,
              unitOfMeasure: "1",
            },
          ],
          orderId: "1020",
          paid: true,
          partiallyFulfilled: false,
          paymentDate: "2021-09-16",
          paymentId: "7",
          returned: false,
          returnedAmount: 0,
          status: "Closed",
          subTotal: 80,
          taxAmount: 8.800003,
          taxRate: 11.000004,
          total: 88.8,
        },
      ],
      filterOrder: [],
      expanded: false,
      purchaseorders: [],
      vendorname: "",
      itemDetails: "",
    };
  }

  scanBarCodeOrOrderID = (barCode) => {
    this.setState({ itemDetails: [] });
    const foundOrders = this.state?.purchaseorders?.filter(
      (order) => order.POID === barCode
    );
    let itemDetails = foundOrders[0].purchaseOrderItems.map((item) => {
      let product = this.props.g_active_products.find(
        (prod) => prod.id === item.Product_Id
      );
      //let uniqueProducts = new Set(...product);
      return {
        purchaseOrderItemId: item.id,
        productName: product ? product.name : "Product Not Found",
      };
    });

    console.log("here is items detail", itemDetails);

    this.setState(
      {
        singleOrderDetail: foundOrders,
        vendorname:
          foundOrders[0].vendor_Id === "null"
            ? "GUEST"
            : this.findVendorById(foundOrders[0].vendor_Id),
        itemDetails: itemDetails,
      },
      () => {
        this.openCategoryModal();
      }
    );
    console.log("forders::::", foundOrders);
    if (foundOrders.length > 0) {
      this.setState({ singleOrderDetail: [foundOrders[0]] }, () => {
        this.openCategoryModal();
      });
      if (foundOrders.length > 1) {
        helpers.toastNotify(
          "warning",
          "More than one orders were found with same order id / barcode; showing top most order."
        );
      }
    } else if (foundOrders.length === 0) {
      helpers.toastNotify(
        "error",
        "No orders were found with given barcode / order id!"
      );
    }
  };

  handleClickmenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    console.log("here is customer", this.props.customer.active_customers);
    if (this.props.customer.active_customers?.length === 0) {
      this.fetchCustomerifnotExists();
    }
    // this.orderstosync();
    // if (this.props.g_inventoryItems.length < 1) {}

    this.purchaseorderstosync();
    this.timer = setInterval(() => {
      // this.orderstosync();
    }, 600000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  fetchCustomerifnotExists() {
    fetch(`${this.props.global_config.basePath}/customers?status=Active`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          customers: data,
          previousCustomers: data,
          loader: false,
        });

        Store.dispatch({
          type: "GET_ACTIVE_CUSTOMERS",
          active_customers: data,
        });
      })
      .catch((err) => {
        // let localCustomers = JSON.parse(localStorage.getItem("customers"));
        this.setState({
          //  customers: localCustomers,
          loader: false,
        });
      });
  }

  orderstosync() {
    this.setState({
      syncingLoader: true,
    });
    fetch(`${this.props.global_config.basePath}/orders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data);
        if (data.length > 0) {
          this.setState({
            orders: data,
            syncingLoader: false,
          });
          Store.dispatch({ type: "GET_ORDERS", orders: data });
        } else {
          this.setState({
            syncingLoader: false,
          });
          Store.dispatch({ type: "GET_ORDERS", orders: [] });
          // helpers.toastNotify("error", "Orders Not Found!");
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          syncingLoader: false,
        });
        // helpers.toastNotify("error", "Error Fetching Orders!");
      });
  }

  purchaseorderstosync() {
    this.setState({
      loader: true,
    });
    fetch(
      `${this.props.global_config.basePath}/PurchaseOrderManagement/purchaseOrdersWithoutDetails`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.length > 0) {
          this.setState({
            purchaseorders: data,
            loader: false,
          });
          Store.dispatch({ type: "GET_PURCHASE_ORDERS", purchaseorders: data });
        } else {
          this.setState({
            loader: false,
          });
          Store.dispatch({ type: "GET_PURCHASE_ORDERS", purchaseorders: [] });
          helpers.toastNotify("error", "No orders Available");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
        helpers.toastNotify("error", "Error Fetching Orders!");
      });
  }

  checkRecordsInDb = () => {
    let keyArray = [];
    localForage
      .iterate((value, key, iterationNumber) => {
        keyArray.push(iterationNumber);
      })
      .then(() => {
        console.log("Iteration has completed", keyArray);
        if (keyArray.length > 0) {
          this.setState({
            syncOrderBtn: false,
            indexDbOrders: keyArray.length,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  addItem = (item) => {
    console.log(item);
    this.setState(function (currentState) {
      return { returnedItems: [...currentState.returnedItems, item] };
    });
  };

  getReturnedItems = (items) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].returned) {
        this.addItem(items[i]);
      }
    }
  };
  onHandleBack = (props) => {
    this.props.history.push("/");
  };

  onPrintFinish = () => {
    this.setState({ printReciept: false });
  };

  findCustomerById = (id) => {
    const customerName = this.props.customer.active_customers.filter(
      (customer) => customer.id === id
    );
    return customerName[0];
  };
  findVendorById = (id) => {
    console.log("here is single vendor id", id);
    const vendor = this.props.g_vendors.find((vendor) => {
      if (vendor.individuals?.length > 0 && vendor.individuals[0].POID === id) {
        console.log("inside individual");
        return true; // Found in individuals
      } else if (
        vendor.organizations?.length > 0 &&
        vendor.organizations[0].POID === id
      ) {
        console.log("inside organization");
        return true; // Found in organizations
      }
      return false; // Not found
    });

    if (vendor) {
      // Check which type of vendor was found
      if (vendor.individuals?.length > 0) {
        return vendor.individuals[0].fullName;
      } else if (vendor.organizations?.length > 0) {
        return vendor.organizations[0].tradingName;
      }
    }

    return null; // Return null if no vendor is found
  };

  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  handleClick = () => {
    this.setState({ open: !this.setState.open });
  };

  postOrders(e) {
    let orderObject = {};
    let orderKey = "";
    if (navigator.onLine) {
      this.setState({ syncBtnLoader: true });
      localForage
        .iterate((value, key, iterationNumber) => {
          console.log([key, value]);
          Object.assign(orderObject, value);
          orderKey = key;
          fetch(this.props.global_config.basePath + "/orders", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
            // convert the React state to JSON and send it as the POST body
            body: JSON.stringify(orderObject),
          })
            .then((response) => {
              if (response.status === 201) {
                localForage.removeItem(orderKey, (err, value) => {
                  console.log(err, value);
                });
              }
            })
            .then((response) => {
              localForage.clear();
            })
            .then(() => {
              this.setState({
                syncOrderBtn: true,
                syncBtnLoader: false,
                indexDbOrders: 0,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          console.log("orderKey", orderKey);
          console.log("orderObject", orderObject);
        })
        .then(() => {
          console.log("Iteration has completed");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // alert("No Internet")
      helpers.toastNotify("error", "No Internet Connection Found!");
    }
  }

  exportToExcel = () => {
    // Get vendor data and product data from Redux state
    const vendors = this.props.g_vendors;
    const products = this.props.g_active_products;
    // Format the order data with vendor name and product names
    const formattedData = this.state.purchaseorders.map((order) => {
      // Assuming each order has a vendor ID that we can filter against
      const vendor = vendors.find((v) => v.id === order.Vendor_Id) || {};
      console.log("here is all vendors:", vendor);
      // Calculate total price for the order
      const organizations =
        vendor?.organizations?.length > 0
          ? vendor?.organizations[0].tradingName
          : "";

      console.log("here is all trading name", organizations);
      const totalProducts = order.purchaseOrderItems?.length;
      const productNames = order.purchaseOrderItems
        .map((item) => {
          const product = products.find((p) => p.id === item.product_Id);
          return product ? product.name : "Unknown Product";
        })
        .join(", ");

      return {
        ID: order.id,
        Vendor:
          (vendor?.organizations?.length > 0 &&
            vendor?.organizations[0].tradingName) ||
          (vendor?.individuals?.length > 0 &&
            vendor?.individuals[0].fullName) ||
          (vendor?.individuals?.length === 0 &&
          vendor?.organizations?.length === 0
            ? "GUEST"
            : ""),
        TotalProducts: totalProducts,
        ProductNames: productNames,
      };
    });

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase Orders");

    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "purchase_orders.xlsx");
  };

  SendServerService(orderObject) {
    console.log(
      "here is sendservice to server is called",
      this.state.purchaseorders
    );

    // const allpurchaseorderproducts = this.state.purchaseorders.map(
    //   (item) => item
    // );
    // console.log("here is order object from print service", orderObject);

    // console.log("here is product purchase order", allpurchaseorderproducts);

    const productMap = this.props.g_active_products.reduce((acc, product) => {
      acc[product.id] = product.name; // Use 'id' as key and 'name' as value
      return acc;
    }, {});

    // Create a new array to hold the mapped purchase order items
    const mappedPurchaseOrders = this.state.purchaseorders.map((order) => ({
      POID: order.POID,
      vendor_Id: order.vendor_Id, // Add vendor_Id here
      items: order.purchaseOrderItems.map((item) => ({
        Product_Id: item.Product_Id,
        Product_Name: productMap[item.Product_Id] || null, // Map to product name or null if not found
        pricePerUnit: item.pricePerUnit,
        quantity: item.quantity,
        totalPrice: item.totalPrice,
        remarks: item.remarks,
        status: item.status,
      })),
    }));

    console.log("here is purchased order", mappedPurchaseOrders);

    const payload = {
      // pageSize: this?.props?.printer_Setting?.printer_settings
      //   ? this?.props?.printer_Setting?.printer_settings?.printerPaperWidth
      //   : "80mm",
      purchaseorder: mappedPurchaseOrders,
      pageSize: "A4",
      type: "PURCHASEORDER",
      companyinfo: this.props?.company_info?.receipt_metadata,
      // productslist:
      // data: data,

      // OrderscreatedAt: helpers.convertTime(orderObject?.createdAt),
      // OrderObject: orderObject,
      // type: "SalesReceipt",
      // printrecieptdata: this?.props?.company_info?.receipt_metadata,
      // payableAmount: addCommasToNumber(
      //   orderObject?.total -
      //     (orderObject?.discountAmount ? orderObject?.discountAmount : 0)
      // ),
      // discountAmount:
      //   orderObject?.discountAmount > 0
      //     ? addCommasToNumber(orderObject?.discountAmount)
      //     : "",
      // TotalAmount: addCommasToNumber(orderObject?.total),
    };

    fetch(
      `${
        this?.props?.printer_Setting?.printer_settings
          ? this?.props?.printer_Setting?.printer_settings?.printerUrlAddress
          : "http://127.0.0.1:3000/printserver"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        body: JSON.stringify(payload),
      }
    )
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(async ({ response, status }) => {
        console.log("Jawad response is here", response);
        console.log("RESPONSEEEE");
        console.log(response);

        if (status !== 200) {
          // setLoader(false);
          // helpers.toastNotify("error", response.errors[0]);
          return;
        }

        if (status === 200) {
          // setLoader(false);
          //helpers.toastNotify("success", "Send To Printer Successfully!");
          // Optional: If needed, handle the response further
        }
      })
      .catch(function (err) {
        // error = 1;
        console.log(err);
        // setLoader(false);
        helpers.toastNotify("error", "Check Print Server is running");
      });
  }

  findOrderInDb = () => {
    let keyArray = [];
    localForage
      .iterate((value, key, iterationNumber) => {
        keyArray.push(key);
      })
      .then(() => {
        console.log("Iteration has completed", keyArray);
        if (keyArray.length > 0) {
          this.setState({ indexDbOrdersKeys: keyArray });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  handleNavigation = (path) => {
    this.handleClose(); // Close the menu
    this.props.history.push(path); // Navigate to the specified path
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const columns = [
      { field: "id", headerName: "ID", width: 100, type: "number", align:"left",   headerAlign: "left", 

        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Id</h5>
          </span>
        ),





      },

      {
        field: "vendor",
        headerName: "Vendor",
        type: "number",
        width: 140,
          align: "left",
          headerAlign: "left",
          renderHeader: (params) => (
            <span style={{marginTop:"10px"}}>
            <h5 className="poppins-medium" style={{fontSize:"14px"}}>Vendor</h5>
            </span>
          ),


      },

      {
        field: "productsname",
        headerName: "Products name",
        type: "number",
        width: 250,
        headerAlign: "left",
        align: "left",
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Products name</h5>
          </span>
        ),


      },
      {
        field: "totalproducts",
        headerName: "Total Products",
        type: "number",
        width: 250,
        align: "left",
        headerAlign: "left",
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Total Products</h5>
          </span>
        ),

      },
    ];
    const { loader } = this.state;
    const customer = this.props.location.state;
    const data = this.state;
    const obj = this.state.purchaseorders;
    console.log("here is po obj", obj);

    const updatedPurchaseOrders = obj.map((purchaseOrder) => {
      const uniqueItems = [];
      const productIds = new Set();

      purchaseOrder.purchaseOrderItems.forEach((item) => {
        if (!productIds.has(item.product_Id)) {
          productIds.add(item.product_Id);
          uniqueItems.push(item);
        }
      });

      return {
        ...purchaseOrder,
        purchaseOrderItems: uniqueItems,
      };
    });

    console.log(
      "Updated purchase orders with unique products",
      updatedPurchaseOrders
    );

    console.log("here is all products", this.props.g_active_products);

    console.log("customer from edit customer page", customer?.POID);

    var rows = [];
    for (var i = 0; i < updatedPurchaseOrders.length; i++) {
      rows[i] = {
        id: updatedPurchaseOrders[i]?.POID || i,

        vendor:
          updatedPurchaseOrders[i].Vendor_Id === "null"
            ? "guest"
            : this.findVendorById(updatedPurchaseOrders[i].Vendor_Id),
        totalproducts: updatedPurchaseOrders[i].purchaseOrderItems.length,
        productsname: updatedPurchaseOrders[i].purchaseOrderItems.map(
          (item) => {
            let product = this.props.g_active_products.find(
              (prod) => prod.id === item.Product_Id
            );

            console.log("item name:", item.Product_Id);
            console.log("items name here:", product);
            return product ? product.name : "Product Not Found";
            //console.log("product console here:", product);
          }
        ),
      };
    }

    const { classes } = this.props;
    const handlePrint = () => {
      this.setState({ goingForPrinting: true });
      setTimeout(() => {
        window.print();
        this.setState({ goingForPrinting: false });
      }, 2000);
    };

    const showReceiptEditModal = () => {
      this.setState({ showReceiptEditModal: true,anchorEl:null });
    };
    const hideReceiptEditModal = () => {
      this.setState({ showReceiptEditModal: false });
    };

    function CustomToolbar() {
      const handlePrint = () => {
        window.print();
      };

      return (
        <div
          style={{
            width: "400px",
          }}
        >
          <GridToolbarContainer style={{}}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "5px",
                  backgroundColor: "#EFEFEF",
                  border: "1px solid black",
                  margin: "20px",
                  width: "100px",
                  height: "50px",
                }}
              >
                <GridToolbarExport />
              </div>
            </div>
          </GridToolbarContainer>
        </div>
      );
    }

    return (
      <>
        {loader ? (
          <Loader value={"Loading Orders"} />
        ) : (
          <div className={classes.root}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <Paper style={{ backgroundColor: SKY_BLUE }}>
                  <Box className={classes.box} p={1} width="100%">
                    <Grid container alignItems="center">
                      <Grid item></Grid>
                      <Grid item>
                        <Typography variant="h5" style={{ color: WHITE, fontFamily:"Poppins" }}>
                          All Puchased Orders
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid> */}
              <Grid item xs={12} style={{marginTop:"20px"}}>
           
           <Grid style={{display:"flex", justifyContent:"space-between",flexDirection:"row"}}>
           <Grid>
                   <div>
                  <h5
                  className={`${classes.productDisplayTite} poppins-medium`}
                  style={{ marginTop: "8px" }}
                  onClick={() => this.props.history.push("/Products")}
                  >
                  Purchase Orders
                  </h5>
                  </div>
</Grid>
                  <Grid className={classes.ordersecondheader} item>
                  {/* <Box
                    pl={1}
                    pr={1}
                    ml={1}
                    mr={1}
                    style={{
                      // padding: "10px",
                      height: "38px",
                      border: "1px solid #dfe1e5",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      hoverBackgroundColor: "#eee",
                      color: "#212121",
                      fontSize: "16px",
                      fontFamily: "Arial",
                      iconColor: "grey",
                      lineColor: "rgb(232, 234, 237)",
                      placeholderColor: "grey",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <QrCode fontSize="small" sx={{ color: grey[600] }} />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        marginLeft: "10px",
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        placeholder="Receipt Barcode / Order ID"
                        style={{
                          height: "100%",
                          outline: "none",
                          border: "none",
                          width: "100%",
                          padding: 0,
                          margin: 0,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.scanBarCodeOrOrderID(e.target.value);
                            e.target.value = "";
                          }
                        }}
                      />
                    </div>
                  </Box> */}
                  <div style={{ display: "flex", flex: 1 }}></div>

                  <div
                    style={
                      {
                        // marginTop: "30px",
                        // marginRight: 20,
                      }
                    }
                  >
                    <Stack direction="row" spacing={2}>
                      {/* <LoadingButton
                              loading={ImportButtonLoader}
                              loadingPosition="start"
                              startIcon={<FileUploadIcon />}
                              variant="conatined"
                              onClick={() => this.refs.fileInput.click()}
                              sx={{ backgroundColor: WHITE, color: MARS }}
                            >
                              Import
                            </LoadingButton> */}
                      {/* <LoadingButton
                        loadingPosition="start"
                        startIcon={<FileDownloadIcon />}
                        variant="conatined"
                        onClick={this.exportToExcel}
                        sx={{ backgroundColor: WHITE, color: SKY_BLUE }}
                      >
                        Export
                      </LoadingButton> */}
                    </Stack>
                  </div>
                  {/* <div
                    style={{
                      marginRight: "10px",

                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  > */}
                    {/* <Link to="/ViewPurchaseOrders">
                      <Button variant="contained">Create Purchase Order</Button>
                    </Link> */}
                  {/* </div> */}

                  {/* <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  > */}
                  {/* <Button variant="contained" onClick={showReceiptEditModal}>
                      <ReceiptLong />
                    </Button> */}
                  {/* </div> */}

                  {/* <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  > */}
                  {/* <Button variant="contained" onClick={handlePrint}>
                      <PrintOutlined />
                    </Button> */}

                  {/* <Button
                      variant="contained"
                      onClick={() => this.SendServerService()}
                    >
                      <PrintOutlined />
                    </Button> */}
                  {/* </div> */}

                  <div
                    style={{
                      marginRight: "10px",
                      display: `${this.state.goingForPrinting ? "none" : ""}`,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => this.orderstosync()}
                      style={{ backgroundColor: MARS, color: "white",    borderRadius: "50%",
                        width: "35px",  // Ensure width and height are equal
                        height: "35px",
                        minWidth: "unset",  }}
                    >
                      <SyncOutlined
                        sx={
                          this.state.syncingLoader
                            ? {
                                animation: "rotateReverse 2s linear infinite",
                                "@keyframes rotateReverse": {
                                  "0%": {
                                    transform: "rotate(0deg)",
                                  },

                                  "100%": {
                                    transform: "rotate(-360deg)",
                                  },
                                },
                              }
                            : {}
                        }
                      />
                    </Button>
                
                  </div>



                  <div style={{marginRight:"10px"}}>
                    <div>
                      <Link to="/ViewPurchaseOrders">
                        <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        style={{
                        backgroundColor: SKY_BLUE,
                        color: "white",
                        borderRadius: "50%",
                        width: "35px",  // Ensure width and height are equal
                        height: "35px",
                        minWidth: "unset", // Override Material-UI's default minWidth
                        }}
                          >
                    <AddIcon />
                    </Button>

                      </Link>
         
                    </div>
                  </div>













                   <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClickmenu}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={this.handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "30ch",
                          },
                        },
                      }}
                    >
                      {/* <MenuItem
                        onClick={() =>
                          this.handleNavigation("/ViewPurchaseOrders")
                        }
                      >
                        Create Purchase Order
                      </MenuItem> */}

                      <MenuItem onClick={showReceiptEditModal}>
                      <ListItemIcon style={{minWidth:"30px"}}>
                      <BusinessIcon fontSize="small"/>
                      </ListItemIcon>
                      <Typography variant="inherit" className="poppins-regular">Business Setting</Typography>
                      </MenuItem>

                      <MenuItem onClick={this.exportToExcel}>
                    <ListItemIcon style={{minWidth:"30px"}}>
                    {this.state.ExportButtonLoader?<CircularProgress color={SKY_BLUE} size={20} />:<FileDownloadIcon fontSize="small"/>} 
                    </ListItemIcon>
                    <Typography variant="inherit" className="poppins-regular">Export</Typography>
                    </MenuItem>

                      {/* <MenuItem onClick={showPrinterSettingModal}>
                      <ListItemIcon style={{minWidth:"30px"}}>
                      <PrintIcon fontSize="small"/>
                      </ListItemIcon>
                      <Typography variant="inherit">Print Server Setting</Typography>
                      </MenuItem> */}





                      {/* <MenuItem onClick={showReceiptEditModal}>
                        Printer Receipt Setting
                      </MenuItem>
                      <MenuItem onClick={() => this.SendServerService()}>
                        Print
                      </MenuItem> */}
                    </Menu>
                  </div>
                </Grid>

           </Grid>
           
      
           
           
               
              
                {/* <Paper className={classes.paper}> */}
                <div style={{ height: 700, width: '100%',marginTop:"25px" }}>
                <div style={{ height: "100%", width: '100%' }}>
                  <DataGrid
                   rowHeight={85}
                  //  headerHeight={75}
                    loading={
                      this.state.syncingLoader && this.state.orders?.length == 0
                        ? true
                        : false
                    }
                    rows={rows}
                    // autoHeight
                    components={
                      {
                        // Toolbar: this.state.goingForPrinting ? "" : CustomToolbar,
                      }
                    }
                    // sortModel={[
                    //   {
                    //     field: "id",
                    //     sort: "desc",
                    //   },
                    // ]}
                    columns={columns}
                    {...this.state.columns}
                    onRowClick={(info) => {
                      const singleOrder = updatedPurchaseOrders?.filter(
                        (order) => order.POID == info.row.id
                      );

                      console.log("here is rowclick info", singleOrder);

                      var singleOrderInfo = {
                        vendorname: info.row.vendor,
                        orderinfo: singleOrder.purchaseOrderItems,
                      };

                      let itemDetails = singleOrder[0].purchaseOrderItems.map(
                        (item) => {
                          let product = this.props.g_active_products.find(
                            (prod) => prod.id === item.Product_Id
                          );
                          //let uniqueProducts = new Set(...product);
                          return {
                            purchaseOrderItemId: item.id,
                            productName: product
                              ? product.name
                              : "Product Not Found",
                          };
                        }
                      );

                      console.log("here is items detail", itemDetails);

                      this.setState(
                        {
                          singleOrderDetail: singleOrder,
                          vendorname: info.row.vendor,
                          itemDetails: itemDetails,
                        },
                        () => {
                          this.openCategoryModal();
                        }
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                    </div>
                    </div>
                {/* </Paper> */}
              </Grid>
            </Grid>
            {this.state.singleOrderDetail?.map((order) => {
              return (
                <Dialog
                  fullWidth={true}
                  open={this.state.categoryModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.closeCategoryModal}
                >
                  <DialogTitle>{"Order Detail"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            Purchase Order Id:
                            <b> {this.state.singleOrderDetail[0].POID}</b>
                          </DialogContentText>

                          <DialogContentText id="alert-dialog-slide-description">
                            Vendor Name: <b> {this.state.vendorname}</b>
                          </DialogContentText>
                          <DialogContentText id="alert-dialog-slide-description">
                            Order Items:
                            {[
                              ...new Set(
                                this.state.itemDetails.map(
                                  (item) => item.productName
                                )
                              ),
                            ].map((productName, index, uniqueItems) => (
                              <span key={productName}>
                                {productName}
                                {index !== uniqueItems.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </DialogContentText>
                        </DialogContent>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closeCategoryModal} color="secondary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            })}
            <ReceiptEditModal
              open={this.state.showReceiptEditModal}
              close={hideReceiptEditModal}
              transition={Transition}
              receipt_metadata={this?.props?.company_info?.receipt_metadata}
              basePath={this?.props?.global_config?.basePath}
            />
            {this.state.printReciept && this.state.singleOrderDetail && (
              <>
                {(this.props?.company_info?.receipt_metadata
                  ?.printerPaperWidth === "80mm" ||
                  !this.props?.company_info?.receipt_metadata
                    ?.printerPaperWidth) && (
                  <ThermalPrinterView
                    orderObject={this.state.singleOrderDetail[0]}
                    customerObject={
                      this.state.singleOrderDetail[0]?.customerId !== "null"
                        ? this.findCustomerById(
                            this.state.singleOrderDetail[0]?.customerId
                          )
                        : ""
                    }
                    receiptMetadata={this.props?.company_info?.receipt_metadata}
                    onFinish={this.onPrintFinish}
                  />
                )}
                {this.props?.company_info?.receipt_metadata
                  ?.printerPaperWidth === "76mm" && (
                  <DotMatrixPrinterView
                    orderObject={this.state.singleOrderDetail[0]}
                    customerObject={
                      this.state.singleOrderDetail[0]?.customerId !== "null"
                        ? this.findCustomerById(
                            this.state.singleOrderDetail[0]?.customerId
                          )
                        : ""
                    }
                    receiptMetadata={this.props?.company_info?.receipt_metadata}
                    onFinish={this.onPrintFinish}
                  />
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_orders: state.ordersState.orders,
    g_unfulfilled_orders: state.ordersState.unfulfilled_orders,
    g_unpaid_orders: state.ordersState.unpaid_orders,
    g_draft_orders: state.ordersState.draft_orders,
    g_open_orders: state.ordersState.open_orders,
    g_close_orders: state.ordersState.close_orders,
    g_active: state.customersState.active_customers,
    global_config: state.globalState,
    customer: state.customersState,
    company_info: state.companyInfoState,
    g_vendors: state.vendorsState.vendors,
    g_active_products: state.productsState.active_products,
    printer_Setting: state.printerSettingState,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(useStyles)(ViewPurchaseOrder));
