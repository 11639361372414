import React, { useEffect, useState } from "react";
import backgroundJPG from "../assets/back.jpg";
import companyData from "../utilities/companies.json";
import { useSelector } from "react-redux";
import Store from "../index";

function KeycloackForm(props) {
  const [companyName, setCompanyName] = useState("");
  const [instanceId, setInstanceId] = useState("");
  const global_config = useSelector((state) => state.globalState);
  const { keycloak } = props;

  const SignIn = async () => {
    window.location.href = "https://hulmsolutions.com";
    console.log(
      `${
        window.location.href.split("//")[1].split("/")[0].split(":")[0]
      }/?companyName=${companyName}&instanceId=${instanceId}`
    );

    await Store.dispatch({ type: "USER_LOGOUT" });


  };

  const handleSelectChange = (data) => {
    let companyData = JSON.parse(data.target.value);
    setCompanyName(companyData.companyName);
    setInstanceId(companyData.instanceId);
  };
  return (
    <div
      style={{
        display: "flex",
        // position: "relative",
        // top: "200px",
        // left:"30px",
        height: "100vh",
        justifyContent: "space-around",
        alignItems: "center",
        background: `url(${backgroundJPG}) no-repeat center center fixed`,
      }}
    >
      <div
        style={{
          // position: "relative",
          // top: "200px",
          // left:"30px",
          width: "auto",
          backgroundColor: "orange",
          borderRadius: "15px",
        }}
      >
        <div className="container">
          <div className="m-4">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="./logo.png" width="100px" height="100px" />
            </div>

            <div className="row text-center mt-4">
              <h3>Welcome to HULM-POS</h3>
            </div>
            <div className="mt-3">
              {/* <label for="exampleFormControlInput1" className="form-label"> */}
              {/* Company Name */}
              <p>
                The given information is inadequate for accessing the login
                Click the link below to access your required solution.
              </p>
     
            </div>
      
            <div
              className=""
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                type="button"
                className="btn text-white "
                style={{ backgroundColor: "#25a18e" }}
                onClick={SignIn}
              >
                Access HulmSolutions
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeycloackForm;
