import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { useState } from "react";
import { SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import Store from "../../index";

export function ReceiptEditModal(props) {
  const [receiptMetadata, updateReceiptMetadata] = useState(
    props?.receipt_metadata && Object.keys(props?.receipt_metadata).length > 0
      ? props?.receipt_metadata
      : {
          businessName: "",
          businessAddress: "",
          phoneNumber: "",
          emailAddress: "",
          logoUrl: null,
          printerPaperWidth: "",
          printerType: "",
        }
  );
  const [isLoading, updateIsLoading] = useState(false);
  const [isLogoUploading, updateIsLogoUploading] = useState(false);
  const handleImages = (images) => {
    if (images?.length === 0) return;
    updateIsLogoUploading(true);
    console.log(images, "images");
    postImages(images)
      .then((response) => {
        if (response?.files[0]?.location) {
          updateReceiptMetadataSate("logoUrl", response?.files[0]?.location);
          updateIsLogoUploading(false);
        }
      })
      .catch((error) => {
        updateIsLogoUploading(false);
        helpers.toastNotify("error", "Failed to upload logo image.");
      });
  };

  const saveReceiptMetadata = () => {
    console.log("receiptMetadata", receiptMetadata);

    Store.dispatch({
      type: "RECEIPT_METADATA",
      receipt_metadata: receiptMetadata,
    });
    // if (
    //   Object.values(receiptMetadata).filter((x) => x === null || x === "")
    //     .length > 0
    // ) {
    //   helpers.toastNotify("error", "All fields must be filled properly.");
    //   return;
    // }
    console.log(
      Object.values(receiptMetadata).filter((x) => x === null || x === "")
    );
    updateIsLoading(true);

    fetch(props.basePath + "/companyInfo/receiptMetaData", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(receiptMetadata),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("receipt Response", data);
        Store.dispatch({ type: "RECEIPT_METADATA", receipt_metadata: data });
        helpers.toastNotify("success", "Receipt Metadata Saved Successfully.");
        updateIsLoading(false);
      })
      .catch((err) => {
        updateIsLoading(false);
        console.log(err);
        helpers.toastNotify("error", "Something went wrong while saving.");
      });
  };

  const postImages = async (images) => {
    return new Promise((resolve, reject) => {
      images.forEach(async (image) => {
        let formData = new FormData();
        formData.append("file", image);
        let urll = `https://s3.hulmsolutions.com/upload`;
        fetch(urll, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  };

  const updateReceiptMetadataSate = (key, value) => {
    var receiptMetadataDeepCopy = JSON.parse(JSON.stringify(receiptMetadata));
    receiptMetadataDeepCopy[key] = value;
    updateReceiptMetadata(receiptMetadataDeepCopy);
  };

  return (
    <Dialog
      fullWidth={true}
      open={props?.open}
      TransitionComponent={props?.transition}
      keepMounted
      onClose={props?.close}
    >
      <DialogTitle className="poppins-medium" style={{fontFamily:"Poppins"}}>{"Business Setting"}</DialogTitle>
      {!isLoading && (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Business Name"
                  name="businessName"
                  value={receiptMetadata?.businessName}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "businessName",
                      event.target.value
                    );
                  }}
                ></TextField>
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Business Address"
                  name="businessAddress"
                  value={receiptMetadata?.businessAddress}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "businessAddress",
                      event.target.value
                    );
                  }}
                ></TextField>
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Phone Number"
                  name="phoneNumber"
                  value={receiptMetadata?.phoneNumber}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "phoneNumber",
                      event.target.value
                    );
                  }}
                ></TextField>
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label="Email Address"
                  name="emailAddress"
                  value={receiptMetadata?.emailAddress}
                  onChange={(event) => {
                    updateReceiptMetadataSate(
                      "emailAddress",
                      event.target.value
                    );
                  }}
                ></TextField>

                {/* <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="printer-type-label">Printer Type</InputLabel>
                  <Select
                    labelId="printer-type-label"
                    label="Printer Type"
                    value={receiptMetadata?.printerType}
                    onChange={(event) => {
                      updateReceiptMetadataSate(
                        "printerType",
                        event.target.value
                      );
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="Thermal">Thermal Printer</MenuItem>
                    <MenuItem value="DotMatrix">Dot Matrix Printer</MenuItem>
                  </Select>
                </FormControl> */}
                {/* <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="printer-width-label">
                    Printer Paper Width
                  </InputLabel>
                  <Select
                    labelId="printer-width-label"
                    label="Printer Paper Width"
                    value={receiptMetadata?.printerPaperWidth}
                    onChange={(event) => {
                      updateReceiptMetadataSate(
                        "printerPaperWidth",
                        event.target.value
                      );
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="80mm">80mm</MenuItem>
                    <MenuItem value="78mm">78mm</MenuItem>
                    <MenuItem value="76mm">76mm</MenuItem>
                    <MenuItem value="58mm">58mm</MenuItem>
                    <MenuItem value="57mm">57mm</MenuItem>
                    <MenuItem value="44mm">44mm</MenuItem>
                    <MenuItem value="A4">A4</MenuItem>
                  </Select>
                </FormControl> */}

                <div
                  style={{
                    display: "flex",
                    height: "300px",
                  }}
                >
                  <DropzoneArea
                    filesLimit={1}
                    dropzoneClass={{ height: 200 }}
                    acceptedFiles={["image/*"]}
                    showAlerts={["error"]}
                    dropzoneText="Logo Upload"
                    showPreviewsInDropzone={false}
                    onChange={handleImages}
                  />
                  {receiptMetadata?.logoUrl &&
                    receiptMetadata?.logoUrl !== "" &&
                    !isLogoUploading && (
                      <div
                        style={{
                          padding: 10,
                          maxWidth: "30%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <img
                          src={receiptMetadata?.logoUrl}
                          alt="Logo"
                          style={{
                            height: "fit-content",
                            maxWidth: "100%",
                            resizeMode: "contain",
                          }}
                        />
                      </div>
                    )}

                  {isLogoUploading && (
                    <div
                      style={{
                        padding: 10,
                        maxWidth: "30%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <CircularProgress
                        size={40}
                        thickness={2.5}
                        style={{ color: SKY_BLUE }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props?.close} color="secondary">
              Close
            </Button>
            <Button onClick={saveReceiptMetadata} style={{color:"#25a18e"}}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {isLoading && (
        <div
          style={{
            margin: 10,
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <CircularProgress
            size={40}
            thickness={2.5}
            style={{ color: SKY_BLUE }}
          />
        </div>
      )}
    </Dialog>
  );
}
