import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import SearchIcon from "@material-ui/icons/Search";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Popup from "../Popup";
import DiscountCustomerLists from "./DiscountCustomerLists";
import BrowseCustomer from "./BrowseCustomer";

import BrowseProducts from "../OrderModals/BrowseProducts";
import Box from "@material-ui/core/Box";
import CustomDisocuntProducts from "./CustomDisocuntProducts";
// import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import Modal from "@material-ui/core/Modal";

import Grid from "@material-ui/core/Grid";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import InputAdornment from "@material-ui/core/InputAdornment";
import Store from "../../index";
import { connect } from "react-redux";

import GlobalConfig from "../../Config";
import { SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
const style = (theme) => ({
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },

  FormColumns: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  responsiveForm: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "0px",
    },
  },
  hideOnSmallScreen: {
    width: "32%",
    position: "fixed",
    paddingRight: "3rem",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      padding: "0px",
    },
  },
  parentBox: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  responsive: {
    display: "flex",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  paper: {
    backgroundColor: "white",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
});
class EditDiscount extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      type: "",
      discountAppliedOn: "",
      quantityOrAmount: "",
      eligibleCustomers: "",
    };
    if (props.location.state == undefined) {
      console.log("PROPS ARE UNDEFINED");
      console.log(this.props.location.pathname.split("/")[2]);
      let url =
        this.props.global_config.basePath +
        `/promotions/${this.props.location.pathname.split("/")[2]}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
      })
        .then((response2) => response2.json())
        .then((data2) => {
          this.setState({
            products: [],
            backdrop: false,
            id: data2[0]?.id,
            name: data2[0]?.name,
            type: data2[0]?.type,
            status: data2[0]?.status,
            checked: false,

            actionType: data2[0]?.actionType,
            discountAmount: data2[0]?.discountAmount,
            applicationMode: data2[0]?.applicationMode,
            customerBuys: data2[0]?.customerBuys,
            customerGets: data2[0]?.customerGets,
            discountCode: data2[0]?.discountCode,
            discountPercentage: data2[0]?.discountPercentage,
            endDate: "2021-10-07",
            minimumQuantityOrAmountToBuy:
              data2[0]?.minimumQuantityOrAmountToBuy,

            quantitycustomerGets: data2[0]?.quantitycustomerGets,
            startDate: "2021-10-07",
            quantityOrAmount: data2[0]?.quantityOrAmount,
            discountAppliedOn: data2[0]?.discountAppliedOn,
            eligibleCustomers: data2[0]?.eligibleCustomers,
            browseCustomerModal: false,
            browseProductsModal: false,
            productList: [],
            productListGets: [],
            customerList: [],
            selectedEndDate: "2021-10-07",
            //eligibleCustomers: "",
            selectedDate: new Date(),
            statusSnackbar: false,
            snackbar: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.state = {
        products: [],
        backdrop: false,
        id: this.props.location.state.id,
        name: this.props.location.state.name,
        type: this.props.location.state.type,
        status: this.props.location.state.status,
        checked: false,

        actionType: this.props.location.state.actionType,
        discountAmount: this.props.location.state.discountAmount,
        applicationMode: this.props.location.state.applicationMode,
        customerBuys: this.props.location.state.customerBuys,
        customerGets: this.props.location.state.customerGets,
        discountCode: this.props.location.state.discountCode,
        discountPercentage: this.props.location.state.discountPercentage,
        endDate: "2021-10-07",
        minimumQuantityOrAmountToBuy:
          this.props.location.state.minimumQuantityOrAmountToBuy,

        quantitycustomerGets: this.props.location.state.quantitycustomerGets,
        startDate: "2021-10-07",
        quantityOrAmount: this.props.location.state.quantityOrAmount,
        discountAppliedOn: this.props.location.state.discountAppliedOn,
        eligibleCustomers: this.props.location.state.eligibleCustomers,
        browseCustomerModal: false,
        browseProductsModal: false,
        productList: [],
        productListGets: [],
        customerList: [],
        selectedEndDate: "2021-10-07",
        //eligibleCustomers: "",
        selectedDate: new Date(),
        statusSnackbar: false,
        snackbar: false,
      };
    }
  }

  componentDidMount() {
    this.setState({
      type: "Percentage",
    });
    if (this.state.applicationMode == "Automatic") {
      this.setState({ discountCode: this.state.name });
    }

    console.log("working... did mount");

    let list = this.state.customerBuys?.map((ids) => {
      let a = {
        id: ids,
        productName: "usman" + ids,
      };
      return a;
    });
    this.setState({ productList: list });

    let listGets = this.state.customerGets?.map((ids) => {
      let a = {
        id: ids,
        productName: "usman" + ids,
      };
      return a;
    });
    this.setState({ productListGets: listGets });

    let customers = this.props.location.state?.eligibleCustomers?.map((ids) => {
      let a = {
        POID: ids,
        name: "zafar" + ids,
      };
      return a;
    });
    this.setState({ customerList: customers });
  }

  setProductFor = () => {
    let selectedProductList = [];
    this.props.location.state.customerBuys.map((ids) => {
      let a = {
        id: ids,
        productName: "usman" + ids,
      };
      selectedProductList.push(a);
      console.log("in component did mount ", a);
    });
    return selectedProductList;
  };

  addToCart = (product) => {
    console.log("order added");
    this.props.addToCartAction(product);
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStartDateChange = (event) => {
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newStartDate = currentDate.split("T");
    this.setState({ startDate: newStartDate[0] });
  };

  handleEndDateChange = (event) => {
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newEndDate = currentDate.split("T");
    this.setState({ endDate: newEndDate[0] });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    console.log("INSIDE HANDLE SUBMIT");
    const startDate = this.state.startDate;
    console.log(startDate);
    const endDate = this.state.endDate;
    console.log(typeof endDate);
    const data = {
      name: this.state.name,
      type: this.state.type,
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.discountCode,
      discountPercentage: this.state.discountPercentage,
      endDate: this.state.endDate,
      minimumQuantityOrAmountToBuy: this.state.minimumQuantityOrAmountToBuy,

      quantitycustomerGets: this.state.quantitycustomerGets,
      startDate: this.state.startDate,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
      eligibleCustomers: this.state.eligibleCustomers,
    };
    console.log(JSON.stringify(data));
    console.log("Before PUt");

    let url =
      this.state.global_config.basePath +
      `/promotions/${this.props.location.pathname.split("/")[2]}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        fetch(
          this.state.global_config.basePath + `/promotions/${this.state.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer my-token",
            },
          }
        )
          .then((response2) => response2.json())
          .then((data2) => {
            console.log("Before Dispatch");
            Store.dispatch({
              type: "EDIT_AUTOMATED_DISCOUNT",
              updated_automated_discount: data2,
            });
          })
          .catch((e) => {
            console.log(e);
          });

        setTimeout(() => {
          this.setState({ backdrop: false, snackbar: false });
          helpers.toastNotify("success", "Discount Updated Successfully!");
        }, 10000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleOpen = (name) => {
    if (name === "browseCustomerModal") {
      this.setState({ browseCustomerModal: true });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: true });
    }
  };

  handleClose = (name) => {
    if (name === "browseCustomerModal") {
      this.setState({ browseCustomerModal: false });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: false });
    }
    console.log("on close");
  };
  handleDelete = (id) => {
    this.setState({ backdrop: true });
    fetch(this.props.global_config.basePath + `/promotions/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      //console.log("order response...");
      //console.log(response);
      this.props.history.push({
        pathname: "/Discount",
      });
    });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  updateMyCounter = (data) => {
    console.log(data);
    this.setState({
      productList: [...data],
      customerBuys: Object.values(data.map((id) => id.productId)),
      customerGets: Object.values(data.map((id) => id.productId)),
    });
  };
  updateCustomerCounter = (data) => {
    console.log("customer data", data);
    this.setState({
      customerList: [...data],
      eligibleCustomers: Object.values(data.map((id) => id.customerID)),
    });
  };
  handleStatustoggle = (status) => {
    this.setState({ backdrop: true });
    const data = {
      status: status,
    };
    fetch(this.props.global_config.basePath + `/promotions/${this.state.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(data),
      // convert the React state to JSON and send it as the POST body
    }).then((response) => {
      fetch(
        this.props.global_config.basePath + `/promotions/${this.state.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        }
      )
        .then((response2) => response2.json())
        .then((data2) => {
          console.log(response);
          Store.dispatch({
            type: "TOGGLE_STATUS",
            discounts: data2,
          });
        });
    });
    this.setState({ backdrop: false, snackbar: false, status: status });
    helpers.toastNotify("success", "Discount Updated Successfully!");
  };
  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: Number(event.target.value) });
  };
  onCustomerDelete = (e) => {
    console.log(e.POID);
  };

  onDelete = (e) => {
    console.log(e);
  };
  render() {
    console.log("Edit Discount props ", this.props);

    const { classes } = this.props;
    const { type } = this.state;
    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} size={90} color="inherit" />
        </Backdrop>
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                variant="filled"
                color="success"
                onClose={this.closeSnack}
              >
                Promotion Status Updated Successfully
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        {this.state.statusSnackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                variant="filled"
                color="success"
                onClose={this.closeSnack}
              >
                Promotion Status updated
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <h5>
              <ArrowBackIosIcon
                fontSize="large"
                style={{ cursor: "pointer" }}
                onClick={(props) => this.props.history.push("/Discount")}
              />
              Edit Discount Code
            </h5>
            <div style={{ marginRight: "35px" }}>
              {this.state.status === "Active" ? (
                <Button
                  variant="contained"
                  onClick={() => this.handleStatustoggle("Archived")}
                  color="secondary"
                  size="small"
                >
                  Disabled
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => this.handleStatustoggle("Active")}
                >
                  Active
                </Button>
              )}
            </div>
          </div>
          <div className={classes.responsive}>
            <div className={classes.parentBox}>
              <div
                className={classes.responsiveForm}
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Discount Code
                  </div>
                  <span
                    style={{
                      backgroundColor: "#25a18e",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.setState({
                        discountCode:
                          "promo-" + Math.random().toString(36).slice(2),
                      });
                    }}
                  >
                    Generate Code
                  </span>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <TextField
                    name="discountCode"
                    value={this.state.discountCode}
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{ classes: { input: classes.inputHeight } }}
                  />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <p style={{ color: "#6d7175", fontWeight: "400" }}>
                    Customers will enter this discount code at checkout.
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Types
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={this.state.type}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={this.handleChange}
                          value="Percentage"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      // value={this
                      control={
                        <Radio
                          value="Fixed Amount"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Fixed Amount"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          value="Buy One Get One"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Buy X get Y"
                    />
                  </RadioGroup>
                </div>
              </div>
              {type === "Buy One Get One" && (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Buys
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="quantity"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minumum Quantity of items"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="amount"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount "
                      />
                    </RadioGroup>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {this.state.quantityOrAmount === "quantity" ? (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="Quantity"
                          type="number"
                          value={this.state.minimumQuantityOrAmountToBuy}
                          name="minimumQuantityOrAmountToBuy"
                          onChange={this.handleChange}
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="amount"
                          name="minimumQuantityOrAmountToBuy"
                          onChange={this.handleChange}
                          type="number"
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    )}
                    <div style={{ width: "63%" }}>
                      <FormControl
                        inputprops={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className={classes.FormColumns}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%", marginTop: "18px" }}>
                      <TextField
                        fullWidth={true}
                        id="filled-search"
                        label="Search Products"
                        // type={this}
                        variant="outlined"
                        onChange={this.handleChange}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse products"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={this.updateMyCounter}
                              onClickBtnClose={() =>
                                this.handleClose("browseProductsModal")
                              }
                            />
                          } //component to send
                          openPopup={this.state.browseProductsModal} //open
                          closePop={() =>
                            this.handleClose("browseProductsModal")
                          } //close
                          onClickBtnOpen={() =>
                            this.handleOpen("browseProductsModal")
                          } //on click open
                          onClickBtnClose={() =>
                            this.handleClose("browseProductsModal")
                          } //on click close
                        />
                      </Box>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <Box>
                      {/* {this.state.customerBuys.length > 0 ? ( */}
                      <CustomDisocuntProducts
                        products={this.state.productList}
                        // onDelete={this.onDelete}

                        onDelete={this.onDelete}
                      />
                    </Box>
                  </div>
                  <hr />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Gets
                    </div>
                  </div>
                  <p>
                    Customers must add the quantity of items specified below to
                    their cart.
                  </p>

                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <TextField
                        name="quantitycustomerGets"
                        onChange={this.handleChange}
                        id="filled-search"
                        label="Quantity"
                        value={this.state.quantitycustomerGets}
                        // type="number"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                      />
                    </div>
                    <div style={{ width: "63%" }}>
                      <FormControl
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                          <MenuItem value={20}>Specific Collections</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className={classes.FormColumns}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%", marginTop: "18px" }}>
                      <TextField
                        onFocus={this.handleOpen}
                        fullWidth={true}
                        onClose={() => this.handleClose}
                        // autoFocus={true}
                        label="Search Products"
                        type="search"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment>
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={this.updateMyCounter}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          } //component to send
                          openPopup={this.state.open} //open
                          closePop={() => this.handleClose} //close
                          onClickBtnOpen={() => this.handleOpen()} //on click open
                          onClickBtnClose={() => this.handleClose()} //on click close
                        />
                      </Box>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <Box>
                      <CustomDisocuntProducts
                        products={this.state.productList}
                        onDelete={this.onDelete}
                      />
                    </Box>
                  </div>

                  {/* */}

                  <hr />
                  <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                    <p>AT A DISCOUNT VALUE</p>
                  </div>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value="minqty"
                      control={<Radio color="primary" size="small" />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="minamount"
                      control={<Radio color="primary" size="small" />}
                      label="Free "
                    />
                  </RadioGroup>
                  <FormControlLabel
                    value="start"
                    control={<Checkbox color="primary" />}
                    label="Set a maximum number of uses per order"
                    // labelPlacement="start"
                  />
                </div>
              )}

              {type === "Percentage" || type === "Fixed Amount" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Value
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <p style={{ color: "#6d7175", fontWeight: "400" }}>
                      Discount value
                    </p>
                  </div>

                  {type === "Percentage" && (
                    <div>
                      <TextField
                        name="discountPercentage"
                        value={this.state.discountPercentage}
                        onChange={this.onPriceChange}
                        variant="outlined"
                        type="number"
                        label="0"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                  {type === "Fixed Amount" && (
                    <div>
                      <TextField
                        value={this.state.discountAmount}
                        variant="outlined"
                        name="discountAmount"
                        onChange={this.onPriceChange}
                        type="number"
                        label="0"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="start">Rs</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}

                  <hr />
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Applies to
                  </div>

                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="discountAppliedOn"
                      value={this.state.discountAppliedOn}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="allproducts"
                            size="small"
                            onChange={this.handleAllproducts}
                          />
                        }
                        label="All Products"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            onChange={this.handleChange}
                            value="specificproducts"
                          />
                        }
                        label="Specific products"
                      />
                      {this.state.discountAppliedOn === "specificproducts" ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              // name="specificcollection"
                              label="Specific Products"
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: {
                                  input: classes.inputHeight,
                                },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <div>
                              <Box>
                                <Popup
                                  title="Select products"
                                  btnVar="contained"
                                  btnText="Browse"
                                  contentTxt={<hr />}
                                  contentTxtBe={<hr />}
                                  content={
                                    <BrowseProducts
                                      updateParent={this.updateMyCounter}
                                      onClickBtnClose={() => this.handleClose()}
                                    />
                                  } //component to send
                                  openPopup={this.state.browseProductsModal} //open
                                  closePop={() =>
                                    this.handleClose("browseProductsModal")
                                  } //close
                                  onClickBtnOpen={() =>
                                    this.handleOpen("browseProductsModal")
                                  } //on click open
                                  onClickBtnClose={() =>
                                    this.handleClose("browseProductsModal")
                                  } //on click close
                                />
                              </Box>
                            </div>
                          </div>
                          <div style={{ width: "100%" }}>
                            <Box>
                              <CustomDisocuntProducts
                                products={this.state.productList}
                                onDelete={this.onDelete}
                              />
                            </Box>
                          </div>
                        </>
                      ) : null}
                    </RadioGroup>
                  </div>
                </div>
              ) : (
                ""
              )}

              {type === "Percentage" ||
              type === "Fixed Amount" ||
              type === "Freeshipping" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Minimum Requirements
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="amount"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount (Rs)"
                      />
                      {this.state.quantityOrAmount === "amount" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            label={
                              "RS " + this.state.minimumQuantityOrAmountToBuy
                            }
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: { input: classes.inputHeight },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="quantity"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum quantity of items"
                      />
                      {this.state.quantityOrAmount === "quantity" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: { input: classes.inputHeight },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                    </RadioGroup>
                  </div>
                </div>
              ) : null}

              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Customer eligibility
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup
                    size="small"
                    name="eligibleCustomers"
                    value={this.state.eligibleCustomers}
                    onChange={this.handleAllCustomers}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          value="everyone"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Everyone"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          size="small"
                          value="SpecificCustomer"
                          onChange={this.handleChange}
                        />
                      }
                      label="specific Customers"
                    />

                    {this.state.eligibleCustomers === "SpecificCustomer" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            name="SpecificCustomer"
                            label="Specific Customers"
                            //value={this.state.eligibleCustomers}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              classes: { input: classes.inputHeight },
                              endAdornment: (
                                <InputAdornment>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Box>
                            <Popup
                              title="Select  Customers"
                              btnVar="contained"
                              btnText="Browse"
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              content={
                                <BrowseCustomer
                                  updateParent={() =>
                                    this.updateCustomerCounter
                                  }
                                  onClickBtnClose={() =>
                                    this.handleClose("browseCustomerModal")
                                  }
                                />
                              } //component to send
                              openPopup={this.state.browseCustomerModal} //open
                              closePop={() =>
                                this.handleClose("browseCustomerModal")
                              } //close
                              onClickBtnOpen={() =>
                                this.handleOpen("browseCustomerModal")
                              } //on click open
                              onClickBtnClose={() =>
                                this.handleClose("browseCustomerModal")
                              } //on click close
                            />
                          </Box>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Box>
                            <DiscountCustomerLists
                              customers={this.state.customerList}
                              onDelete={this.onCustomerDelete}
                            />
                          </Box>
                        </div>
                      </>
                    ) : null}
                  </RadioGroup>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Active Dates
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Grid container justify="space-around">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Start Date"
                        format="yyyy/MM/dd"
                        value={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        mt={2}
                        label="End Date"
                        format="yyyy/MM/dd"
                        value={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </div>
              </div>
            </div>

            <div className={classes.hideOnSmallScreen}>
              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Summary
                </p>
                <p
                  style={{
                    margin: "15px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  {type === "Percentage" && "No information entered yet"}
                  {type === "FixedAmount" && "No information entered yet"}
                  {type === "Buy One Get One:" && "No information entered yet"}
                  {type === "Freeshipping" && (
                    <>
                      <List className={classes.root} subheader={<li />}>
                        {[0, 1, 2].map((sectionId) => (
                          <li
                            key={`section-${sectionId}`}
                            className={classes.listSection}
                            style={{ listStyleType: "circle" }}
                          >
                            <ul className={classes.ul}>
                              <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            </ul>
                          </li>
                        ))}
                      </List>
                    </>
                  )}
                </p>
                <hr></hr>
                <p
                  style={{
                    margin: "15px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  PERFORMANCE
                </p>
                <p style={{ color: "#6d7175", margin: "10px 0 15px 15px" }}>
                  Discount is not active yet.
                </p>
              </div>

              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  CAN’T COMBINE WITH OTHER AUTOMATIC DISCOUNTS
                </p>
                <p
                  style={{
                    margin: "10px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  Customers won’t be able to enter a code if an automatic
                  discount is already applied at checkout.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => this.props.history.push("/Discount")}
            >
              Discard
            </Button>

            <Button
              type="submit"
              variant="outlined"
              onClick={() => this.handleSubmit}
              style={{ backgroundColor: SKY_BLUE, color: "white" }}
            >
              Save Discount Code
            </Button>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(style)(EditDiscount));
