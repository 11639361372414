import React, { Component } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import TextField from "@material-ui/core/TextField";
import TextField from "@mui/material/TextField";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { DropzoneArea } from "material-ui-dropzone";
import InputAdornment from "@material-ui/core/InputAdornment";

import { FormControlLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import Box from "@material-ui/core/Box";
// import Popup from "../Popup";
// import ImageUrlModal from "./ImageUrlModal";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalConfig from "../../Config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { cartesianProduct } from "cartesian-product-multiple-arrays";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClearIcon from "@material-ui/icons/Clear";
import AddProduct from "../../MobileComponents/ProductMobileComponents/AddProduct";
import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Store from "../../../src/index";
import Loader from "../../MobileComponents/Loader";
import SnackBar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { SKY_BLUE, WHITE } from "../../colors/colors";
// import { uploadImage } from "./ImageController";
// import Paper from "@material-ui/core/Paper";
import SimpleReactValidator from "simple-react-validator";
import { PathRef } from "slate";
import helpers from "../../UtilitiesFunctions";
import { checkCategoryAvailibility } from "../../utilities/CategoryLogic";
import { Center } from "devextreme-react/map";
import Delete from "@material-ui/icons/Delete";
import { AddPhotoAlternate } from "@material-ui/icons";

// import { checkCategoryAvailibility } from "./CategoryLogic";
const statuses = [
  {
    POID: 1,
    value: "Active",
  },
  {
    POID: 2,
    value: "Inactive",
  },
];

const TitleStatuses = [
  {
    POID: 1,
    value: "Mr.",
  },
  {
    POID: 2,
    value: "Ms.",
  },
];

const GenderStatus = [
  {
    POID: 1,
    value: "Male",
  },
  {
    POID: 2,
    value: "Female",
  },
];

const MartialStatuses = [
  {
    POID: 1,
    value: "Single",
  },
  {
    POID: 2,
    value: "Married",
  },
];

const sampleVarient = {
  price: null,
  startDate: "2024-01-11",
  endDate: "2024-01-11",
  taxRate: null,
  barcodes: [
    {
      code: null,
      unitOfMeasureRefId: "1",
    },
  ],
  status: "Active",
  images: [],
  quantity: null,
  costPrice: null,
  productSpecCharacteristics: [],
  unitsOfMeasures: [
    {
      id: 1,
      isDefault: true,
      conversionFactor: 1,
      name: "Nos",
      relation: "includes",
      price: null,
    },
  ],
};
const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      // width: "55%",
    },
  },

  formItems: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
  },
  formInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  formList: {
    width: "85%",
    marginRight: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRightBox: {
    width: "40%",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textDanger: {
    color: "red",
  },

  arrowroute: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      marginBottom: "20px",
      marginLeft: "10px",
    },
  },

  pricing: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.only("lg")]: {
      display: "flex",

      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  pricingfixwd: {
    [theme.breakpoints.only("xs")]: {
      width: "300px",
    },
    [theme.breakpoints.only("lg")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("md")]: {
      width: "200px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "200px",
      marginLeft: "10px",
    },
  },

  variantresponsive: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantshow: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "55vw",
    overflowX: "scroll",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "100vw",
    },
  },

  variantgenerationd: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    maxWidth: "55vw",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100vw",
    },
  },
  addproductsave: {
    marginRight: "10px",
    [theme.breakpoints.only("xs")]: {
      marginRight: "30px",
      marginBottom: "10px",
      padding: "20px",
    },
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

const initialFormState = {
  fullnameError: "",
  emailError: "",
  phoneError: "",
  street1Error: "",
  street2Error: "",
};

const status = [
  { code: "Active", label: "Active" },
  { code: "Draft", label: "Draft" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checked: false,
      backdrop: false,
      inputList: [
        { name: "", prodSpecCharValues: [{ isDefault: true, value: "black" }] },
      ],
      snackBar: false,
      snackBarMessage: "",
      snackBarType: "",
      loader: false,
      description: "",
      name: "",
      price: null,
      url: null,
      city: "Karachi",
      country: "PK",
      countryName: "Pakistan",
      postCode: "",
      stateOrProvince: "SD",
      stateOrProvinceName: "Sindh",
      taxRate: 0,
      allAttributeVariant: [],
      startDate: "2021-01-14",
      endDate: "2021-01-14",
      Category_Id: "12",
      algorithm: "RuleEngine.groovy",
      status: "Active",
      emailaddress: "",
      postcode: "",
      phonenumber: "",
      streetone: "",
      streettwo: "",
      countryofbirth: "",
      familyname: "",
      fullname: "",
      gender: "Male",
      givenname: "",
      href: "PartyManagementService/individual/1",
      id: "1",
      location: "",
      maritalstatus: "Single",
      middlename: "Michael",
      nationality: "",
      placeofbirth: "",
      titlestatus: "Mr.",
      images: [],
      quantity: null,
      barcodes: [
        {
          code: null,
          unitOfMeasureRefId: "1",
        },
      ],
      users: [{ name: "", value: "" }], //""used for dynamic fields
      arr: [],
      productSpecCharacteristics: [],
      categoryName: "",
      variantPrice: "",
      categoryDescription: "",
      obj_parent: {},
      numberOfVarients: [],
      show: false,
      submit: false,
      snackbar: false,
      errorSnackbar: false,
      tradingname: "",
      categories: [],
      variants: [],
      categoryId: null,
      costPrice: null,
      fullnameError: null,
      emailError: null,
      phoneError: null,
      street1Error: null,
      street2Error: null,
      descriptionError: null,
      CategorySnackbar: false,
      mediaError: null,
      files: [],
      categoryModal: false,
      variantModal: false,
      imagePickerModal: false,
      attributes: { attributeName: "", attributeValues: [{ value: "" }] }, // Initial empty object with an array of value objects
      countries: [],
      allVariants: [],
      selectedOption: "individual",
      updateIndex: null,
      variantImages: [],
      selectedIndexForImages: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleSubmit_DF = this.handleSubmit_DF.bind(this);
    this.addVar = this.addVar.bind(this);
    this.validator = new SimpleReactValidator({
      validators: {
        positiveNumber: {
          // name the rule
          message: "The :attribute must be valid",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^[+]?([0-9]+(?:[\.][1-9]*)?|\.[1-9]+)$/i
              ) && params.indexOf(val) === -1
            );
          },
          required: true, // optional
        },
      },
    });
  }
  //create a validate function
  validate = () => {
    if (this.state.fullname === null && this.state.fullname === "") {
      this.setState({ fullnameError: "FullName is required" });
    }
    if (this.state.emailaddress === null) {
      this.setState({ emailError: "Email is required" });
    }
    if (this.state.phonenumber === null) {
      this.setState({ phoneError: "Phone is required" });
    }
    if (this.state.streetone === null) {
      this.setState({ street1Error: "Street1 is required" });
    }
    if (this.state.streettwo === null) {
      this.setState({ street2Error: "Street2 is required" });
    }

    if (
      this.state.fullnameError === null &&
      this.state.emailError === null &&
      this.state.phoneError === null &&
      this.state.street1Error === null &&
      this.state.street2Error === null
    ) {
      return false;
    } else {
      return true;
    }
  };
  printCategotyId = () => {
    console.log("ca" + this.state.categoryId);
  };
  removeImage = (index) => {};
  removeImage = (image, index) => {
    this.setState({
      images: this.state.images.filter((index) => image !== index),
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  handleOptionChange = (event) => {
    // setSelectedOption(event.target.value);
    this.setState({ selectedOption: event.target.value }, () => {
      console.log("here is all selected options", this.state.selectedOption);
    });
  };
  updateAllAttributeValues = (value, key, index) => {
    const deepCopyOfAllAttributeVariant = JSON.parse(
      JSON.stringify(this.state.allAttributeVariant)
    );
    if (key === "price") {
      deepCopyOfAllAttributeVariant[index].unitsOfMeasures[0][key] = value;
      deepCopyOfAllAttributeVariant[index][key] = value;
    } else if (key === "barcode") {
      deepCopyOfAllAttributeVariant[index].barcodes[0].code = value;
    } else {
      deepCopyOfAllAttributeVariant[index][key] = value;
    }
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
  };

  removeImageFromVariant = (image, index) => {
    const deepCopyOfAllAttributeVariant = this.state.allAttributeVariant;
    deepCopyOfAllAttributeVariant[this.state.selectedIndexForImages].images =
      deepCopyOfAllAttributeVariant[
        this.state.selectedIndexForImages
      ].images.filter((index) => image !== index);
    this.setState({
      allAttributeVariant: deepCopyOfAllAttributeVariant,
    });
    // setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  componentDidMount() {
    this.setState({ countries: Country.getAllCountries() });
    if (this.state.selectedOption) {
      const selectBox = document.getElementById("city");
      selectBox.options[selectBox.selectedIndex].innerHTML = "Karachi";
    }

    let url = this.props.global_config.basePath + "/categories";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          loading: false,
          categories: data,
        })
      )
      .catch(() => {
        this.setState({
          snackBar: false,
          snackBarMessage: "Error! unable to get Categories",
          snackBarType: "error",
        });
      });
  }

  addVar = (name, array) => {
    var productSpecCharValues = [];
    var obj_parent = {};

    for (var i = 0; i < array.length; i++) {
      var obj_child = {};
      obj_child.value = array[i];
      productSpecCharValues.push(obj_child);
    }

    obj_parent.name = name;
    obj_parent.productSpecCharValues = productSpecCharValues;

    this.setState(function (currentState) {
      return {
        productSpecCharacteristics: [
          ...currentState.productSpecCharacteristics,
          obj_parent,
        ],
      };
    });
  };
  setValues = (input) => (e) => {
    console.log("key is: ", input);
    console.log("value is: ", e.target.value);
    this.setState({ [input]: e.target.value });
  };
  setSpecificValues = (key, value) => {
    console.log("key is: ", key);
    console.log("value is: ", value);
    this.setState({ [key]: value });
  };

  handleChange_DF = (i, e) => {
    const { name, value } = e.target;
    var array = "";
    if (name === "value") {
      array = value.split(",");
      //console.log("if");
    } else {
      array = value;
      //console.log("else");
    }
    let users = [...this.state.users];
    users[i] = { ...users[i], [name]: array };
    this.setState({ users });
    //console.log(this.state.users[i]);
  };

  addClick_DF() {
    this.setState((prevState) => ({
      users: [...prevState.users, { name: "", value: "" }],
    }));
  }

  removeClick_DF(i) {
    let users = [...this.state.users];
    users.splice(i, 1);
    this.setState({ users });
  }

  handleSubmit_DF = (e) => {
    this.setState({ submit: true });
    console.log("submit_DF");

    for (var i = 0; i < this.state.users.length; i++) {
      this.addVar(this.state.users[i].name, this.state.users[i].value, i);
    }
    console.log("productSpecCharacteristics: ");
    console.log(this.state.productSpecCharacteristics);
    console.log(this.state.obj_parent);
    e.preventDefault();
  };
  value = (value) => {
    const val = value.split(":");
    return val[1];
  };
  superList = () => {
    this.setState({ show: true });
    let superlist = [];
    for (var j = 0; j < this.state.productSpecCharacteristics.length; j++) {
      let values = [];
      for (
        var k = 0;
        k <
        this.state.productSpecCharacteristics[j].productSpecCharValues.length;
        k++
      ) {
        values.push(
          this.state.productSpecCharacteristics[j].name +
            ":" +
            this.state.productSpecCharacteristics[j].productSpecCharValues[k]
              .value
        );
      }
      superlist.push(values);
    }
    console.log("this is varients", cartesianProduct(...superlist));
    let varients = cartesianProduct(...superlist);
    console.log("varients", varients);
    this.setState({ numberOfVarients: varients });
  };
  createUI_DF() {
    return this.state.users.map((el, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          // width: "70%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <TextField
          size="small"
          variant="outlined"
          placeholder="Attribute"
          name="name"
          value={el.name || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <TextField
          size="small"
          variant="outlined"
          placeholder="Value"
          name="value"
          value={el.value || ""}
          onChange={this.handleChange_DF.bind(this, i)}
        />
        <Button
          color="secondary"
          value="remove"
          size="medium"
          variant="contained"
          onClick={this.removeClick_DF.bind(this, i)}
        >
          Remove
        </Button>
      </div>
    ));
  }

  handleChangeBarcode = (event) => {
    this.setState({
      barcodes: [
        {
          code: event.target.value,
          unitOfMeasureRefId: "1",
        },
      ],
      //checked: !this.state.checked,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      //checked: !this.state.checked,
    });
  };
  handleChangeCheckBox = (event) => {
    console.log("in handle change check box");
    this.setState({
      checked: !this.state.checked,
    });
  };

  handleChangeVariant = (attrIndex, valueIndex, fieldName, value) => {
    const updatedAttributes = this.state.attributes;
    updatedAttributes.attributeValues[valueIndex][fieldName] = value;

    // Automatically add a new empty value object when entering a value
    if (
      valueIndex === updatedAttributes.attributeValues.length - 1 &&
      value !== ""
    ) {
      updatedAttributes.attributeValues.push({ value: "" });
    }

    this.setState({ attributes: updatedAttributes });
  };

  openCategoryModal = () => {
    this.setState({ categoryModal: true });
  };
  closeCategoryModal = () => {
    this.setState({ categoryModal: false });
  };

  openVariantModal = (updateIndex = null) => {
    this.setState({ variantModal: true, updateIndex: updateIndex });
  };
  closeVariantModal = () => {
    this.setState({ variantModal: false, updateIndex: null });
  };

  openImageModal = (selectedIndex = null) => {
    this.setState({ selectedIndexForImages: selectedIndex });
    this.setState({ imagePickerModal: true });
  };
  closeImageModal = () => {
    this.setState({ imagePickerModal: false, updateIndex: null });
  };

  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: Number(event.target.value) });
  };
  onHandleBack = (props) => {
    this.props.history.push("/Vendors");
  };

  handleDescriptionChange = (value) => {
    this.setState({ description: value });
  };

  generateCombinations = (variants, index = 0, currentCombination = {}) => {
    const combinations = [];

    if (index == variants.length) {
      // Push the ordered combination
      const sampleCombination = JSON.parse(JSON.stringify(sampleVarient));
      sampleCombination.productSpecCharacteristics =
        Object.values(currentCombination);
      combinations.push(JSON.parse(JSON.stringify(sampleCombination)));
    } else {
      const attribute = variants[index];

      attribute.attributeValues.forEach((value) => {
        const newCombination = { ...currentCombination }; // Create a new object for each iteration
        newCombination[attribute.attributeName] = {
          name: attribute.attributeName,
          productSpecCharValues: { value: value.value },
        };

        const subCombinations = this.generateCombinations(
          variants,
          index + 1,
          newCombination
        );

        combinations.push(...subCombinations);
      });
    }

    return combinations;
  };

  submitVariant = () => {
    console.log(
      "here is every",
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    );
    if (
      this.state.attributes.attributeName.trim() === "" ||
      this.state.attributes.attributeValues.every(
        (item) => item["value"].trim() === ""
      )
    ) {
      helpers.toastNotify("error", "All attribute values cannot be empty");
      return;
    }

    if (
      this.state.attributes.attributeValues.some(
        (item, index, arr) =>
          item["value"].trim() !== "" &&
          arr.findIndex((i) => i["value"] === item["value"]) !== index
      )
    ) {
      helpers.toastNotify(
        "error",
        "Attribute Can not contain duplicate values"
      );
      return;
    }

    if (this.state.updateIndex == null) {
      if (
        this.state.allVariants.filter(
          (v) => v.attributeName == this.state.attributes.attributeName
        ).length > 0
      ) {
        helpers.toastNotify(
          "error",
          'Attribute Name  "' +
            this.state.attributes.attributeName +
            '" already exist.'
        );
        return;
      }
      this.setState(
        (prevState) => ({
          allVariants: [...prevState.allVariants, prevState.attributes],
          attributes: { attributeName: "", attributeValues: [{ value: "" }] }, // Reset to initial empty state
        }),
        this.prepareCombinations
      );
    } else {
      var localVarient = this.state.allVariants;
      localVarient[this.state.updateIndex] = this.state.attributes;
      this.setState(
        {
          allVariants: localVarient,
          attributes: { attributeName: "", attributeValues: [{ value: "" }] },
          updateIndex: null,
        },
        this.prepareCombinations
      );
    }
    this.setState({ variantModal: false });
    console.log("here is all variant outside callback", this.state.allVariants);
  };

  prepareCombinations = () => {
    const clearAttribute = [];
    this.state.allVariants.map((variant) => {
      var deepCopy = JSON.parse(JSON.stringify(variant));
      deepCopy.attributeValues = deepCopy.attributeValues?.filter(
        (attributeVal) => attributeVal.value !== ""
      );
      clearAttribute.push(deepCopy);
    });
    var combvar = this.generateCombinations(clearAttribute);
    this.setState(
      {
        allAttributeVariant: combvar,
      },
      () => {
        console.log("here is all variants combinations", combvar);
      }
    );
  };

  submitCategory = () => {
    if (this.state.categoryName == "" || this.state.categoryDescription == "") {
      helpers.toastNotify("error", "Fields are empty!");
      return;
    }
    this.setState({ backdrop: true, categoryModal: false });

    const data = {
      name: this.state.categoryName,
      description: this.state.categoryDescription,
    };

    console.log("data", data);

    fetch(this.props.global_config.basePath + "/categories", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(data),
    })
      .then((response) => {
        this.setState({ backdrop: false, CategorySnackbar: false });
        helpers.toastNotify("success", "Category Successfully Added!");

        fetch(this.props.global_config.basePath + "/categories", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              categories: data,
              linearSpinner: false,
            });
            Store.dispatch({ type: "GET_CATEGORIES", categories: data });
          })
          .catch((e) => {
            console.log(e);
            helpers.toastNotify("error", "Something went wrong!");
          });
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Failed To Create Category. ");
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.selectedOption === "organization") {
      // if (this.validator.allValid()) {
      //   this.setState({ ...this.state, initialFormState });

      this.addVendorOrganization();
      // } else {
      //   this.validator.showMessages();
      //   helpers.toastNotify("error", "Mandatory Field Missing");

      //   this.forceUpdate();
      // }
      console.log("here is basebath", this.props.global_config.basePath);
    } else {
      //   console.log(
      //   this.state.titlestatus,
      //   this.state.fullname,
      //   this.state.familyname,
      //   this.state.givenname,
      //   this.state.middlename,
      //   this.state.maritalstatus,
      //   this.state.status,
      //   this.state.emailaddress,
      //   this.state.postcode,
      //   this.state.phonenumber,
      //   this.state.streetone,
      //   this.state.streettwo,
      //   this.state.nationality,
      //   this.state.placeofbirth,
      //   this.state.countryofbirth,
      //   this.state.location,
      //   this.state.countryName,
      //   this.state.stateOrProvince,
      //   this.state.city
      // );
      if (this.validator.allValid()) {
        this.setState({ ...this.state, initialFormState });

        this.addVendorIndividual();
      } else {
        this.validator.showMessages();
        helpers.toastNotify("error", "Mandatory Field Missing");

        this.forceUpdate();
      }
    }
  };

  addVendorOrganization = () => {
    this.setState({ backdrop: true });
    const data = {
      status: "active",
      type: "Individual",
      organizations: [
        {
          type: "exampleType",
          tradingName: this.state.tradingname,
          status: this.state.status,
          existsDuring: {
            startDate: "2024-01-01",
            endDate: "2024-12-31",
          },
          contactMediums: [
            {
              type: "exampleType",
              prefered: true,
              validFor: {
                endDate: "2024-12-31",
                startDate: "2024-01-01",
              },
              mediumCharacteristics: [
                {
                  city: this.state.city,
                  country: this.state.country,
                  emailAddress: this.state.emailaddress,
                  number: this.state.phonenumber,
                  postCode: this.state.postcode,
                  stateOrProvince: this.state.stateOrProvince,
                  street1: this.state.streetone,
                  street2: this.state.streettwo,
                },
              ],
            },
          ],
        },
      ],
    };
    console.log("product to be created: ", JSON.stringify(data));
    let url = this.props.global_config.basePath + "/VendorManagement/vendor";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        helpers.toastNotify("success", "Vendor Created successfully");
        this.setState({ backdrop: false });
        this.redirectToVendors();
      })
      .catch((err) => {
        this.setState({ backdrop: false });
        helpers.toastNotify("error", "Vendor Creation Failed.");
      });
  };

  addVendorIndividual = () => {
    this.setState({ backdrop: true });
    const data = {
      status: "active",
      type: "Individual",
      individuals: [
        {
          gender: this.state.gender,
          placeOfBirth: this.state.placeofbirth,
          countryOfBirth: this.state.countryofbirth,
          nationality: this.state.nationality,
          birthDate: "1980-01-01",
          maritalStatus: this.state.maritalstatus,
          title: this.state.titlestatus,
          givenName: this.state.givenname,
          familyName: this.state.familyname,
          middleName: this.state.middlename,
          location: this.state.location,
          fullName: this.state.fullname,
          status: "Active",
          contactMediums: [
            {
              type: "exampleType",
              prefered: true,
              validFor: {
                endDate: "2024-12-31",
                startDate: "2024-01-01",
              },
              mediumCharacteristics: [
                {
                  city: this.state.city,
                  country: this.state.country,
                  emailAddress: this.state.emailaddress,
                  number: this.state.number,
                  postCode: this.state.postcode,
                  stateOrProvince: this.state.stateOrProvince,
                  street1: this.state.street1,
                  street2: this.state.street2,
                },
              ],
            },
          ],
        },
      ],
    };

    let url = this.props.global_config.basePath + "/VendorManagement/vendor";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        helpers.toastNotify("success", "Vendor Created successfully");
        this.setState({ backdrop: false });
        this.redirectToVendors();
      })
      .catch((err) => {
        console.log("here is err of addvendor individual", err);
        this.setState({ backdrop: false });
      });
  };

  resetStateOfOrganization = () => {
    this.setState({
      tradingname: "",
      city: "",
      country: "",
      emailaddress: "",
      phonenumber: "",
      postcode: "",
      streetone: "",
      streettwo: "",
    });
  };

  redirectToVendors = () => {
    const { history } = this.props;
    setTimeout(() => {
      history.push("/Vendors");
    }, 2000);
  };
  updateParent = (data) => {
    const obj = {
      imageURL: data.image,
    };
    this.setState({ images: [...this.state.images, obj] });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    console.log("list", list);
    list[index][name] = value;
    list[0].prodSpecCharValues.push({ value: value });
    console.log("list", list);
    this.setState({ inputList: list });
    console.log("list", list);
  };
  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = this.state.inputList;
    list.splice(1, 1);
    this.setState({ inputList: list });
  };

  handleAddClick = () => {
    const obj = [
      {
        variantName: "aa",
        variantValue: [{ value: "black" }, { value: "red" }],
      },
    ];
    console.log("clicked");
    if (this.state.inputList.length < 3) {
      console.log(this.inputList);
      this.setState({ inputList: [...this.state.inputList, obj] });
    } else {
      console.log("can not add");
    }
  };

  handleClick = () => {
    this.setState(true);
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState(false);
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false, errorSnackbar: false });
  };

  handleImages = (images) => {
    const { files } = this.state;
    console.log("uploaded images are: ", images);
    const addedImages = images.filter(
      (image) => !files.find((file) => image === file)
    );
    console.log("Added iamges are: ", addedImages);

    this.postImages(addedImages);
    this.setState({ files: images });
  };

  handleImagesUploading = async (images) => {
    return new Promise(async (resolve, reject) => {
      const { files } = this.state;
      console.log("uploaded images are: ", images);
      const addedImages = images.filter(
        (image) => !files.find((file) => image === file)
      );
      console.log("Added iamges are: ", addedImages);
      this.postImagesToS3(addedImages)
        .then((uploads) => {
          resolve(uploads);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleAddImage = (image) => {
    console.log("added image is: ", image);
  };
  handleDeleteImage = (deletedImage) => {
    console.log("deleted image is: ", deletedImage);
    console.log("gobal config is: ", GlobalConfig.temp);
    this.deleteImageFromArray(deletedImage);
  };
  deleteImageFromArray = (imageToDelete) => {
    const { images } = this.state;
    images.forEach((image, index) => {
      let myArray = image.imageURL.split("/");
      if (imageToDelete.name === myArray[myArray.length - 1]) {
        console.log("Removing index: ", index, " image: ", image);
        images.splice(index, 1);
      }
    });
    console.log("now images in state are ", images);
  };

  handleChangeCat = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  postImagesToS3 = (images) => {
    return new Promise(async (resolve, reject) => {
      let path = "";
      let uploadedFiles = [];
      await images.forEach(async (image) => {
        console.log("Image", image);
        let formData = new FormData();
        formData.append("file", image);
        let urll = `https://s3.hulmsolutions.com/upload`;
        await this.setState({ loader: true, backdrop: true });
        fetch(urll, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log("here is data of imgaes", data);
            console.log("here is data of dataa of images", data.data);

            await data.files.map(async (i) => {
              await uploadedFiles.push({
                imageURL: i.location,
              });
            });
            await this.setState({ loader: false, backdrop: false });
            resolve(uploadedFiles);
          })
          .catch(async (error) => {
            console.error(error);
            await this.setState({ loader: false, backdrop: false });
            reject(error);
          });
      });
    });
  };

  postImages = async (images) => {
    let path = "";
    await images.forEach(async (image) => {
      console.log("Image", image);
      let formData = new FormData();
      formData.append("file", image);
      let urll = `https://s3.hulmsolutions.com/upload`;
      await this.setState({ loader: true, backdrop: true });
      fetch(urll, {
        method: "POST",

        body: formData,
      })
        .then((response) => response.json())
        .then(async (data) => {
          let imagess = [];

          await data.files.map(async (i) => {
            await imagess.push({
              imageURL: i.location,
            });
          });
          this.setState({ images: imagess });
          await this.setState({ loader: false, backdrop: false });
        })
        .catch(async (error) => {
          console.error(error);
          await this.setState({ loader: false, backdrop: false });
        });
    });
  };

  setImageInState = (image) => {
    const obj = {
      imageURL: image.filePath,
    };
    this.setState({ images: [...this.state.images, obj] });
  };

  render() {
    const { classes, history } = this.props;
    const {
      status,
      name,
      description,
      snackBar,
      snackBarMessage,
      snackBarType,
    } = this.state;
    const { country, countries, stateOrProvince, city } = this.state;
    const values = { status, name, description };
    const state = State.getStatesOfCountry(country);
    const myCity = City.getCitiesOfState(country, stateOrProvince);
    console.log("status is: ", status);

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} color="inherit" />
        </Backdrop>

        <h5 className={classes.arrowroute}>
          <ArrowBackIosIcon
            style={{ cursor: "pointer" }}
            onClick={this.onHandleBack}
          />
          Add Vendor
        </h5>

        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.errorSnackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="error"
                color="error"
                variant="filled"
                onClose={this.closeSnack}
              >
                Error! Request Failed
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}

        {/* Check Box for individual or organization */}

        <div style={{ marginTop: "20px" }}>
          <label className="poppins-regular">
            <input
              type="radio"
              name="vendortype"
              value="individual"
              checked={this.state.selectedOption === "individual"}
              onChange={this.handleOptionChange}
              style={{ marginRight: "10px" }}
            />
            Individual
          </label>
          <label className="poppins-regular">
            <input
              type="radio"
              name="vendortype"
              value="organization"
              checked={this.state.selectedOption === "organization"}
              onChange={this.handleOptionChange}
              style={{ marginRight: "10px", marginLeft: "10px" }}
            />
            Organization
          </label>
        </div>
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={2000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                severity="success"
                color="success"
                variant="filled"
                onClose={this.closeSnack}
              >
                Product Created
                <div>
                  <Link to="/Products" style={{ color: "white" }}>
                    clik here to see products
                  </Link>
                </div>
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}

        {this.state.selectedOption === "individual" && (
          <>
            <form>
              <div className={classes.formContainer}>
                <div className={classes.formList}>
                  <div className={classes.formItems}>
                    <div>
                      {/* <p
                        style={{
                          margin: "10px 0 10px 10px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Title
                      </p> */}

                      <h5 className="poppins-regular" style={{fontSize:"17px"}}>Title</h5>

                    </div>

                    <div style={{ marginTop: "5px" }}>
                      {/* <div className={classes.formItems}> */}

                      <Grid item xs={12}>
                        {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                        <Select
                          labelId="Input label"
                          style={{ height: "40px" }}
                          id="Select"
                          value={this.state.titlestatus}
                          defaultValue={status}
                          onChange={(e) => {
                            this.setState({ titlestatus: e.target.value });
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          {TitleStatuses.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* </div> */}
                    </div>
                    <TextField
                      required={true}
                      name="fullname"
                      style={{ marginTop: "10px" }}
                      label="Full Name"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />

                    {this.validator.message(
                      "fullname",
                      this.state.fullname,
                      "required",
                      {
                        className: classes.textDanger,
                      }
                    )}
                    <TextField
                      required={true}
                      name="familyname"
                      style={{ marginTop: "10px" }}
                      label="Family Name"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />

                    {this.validator.message(
                      "FamilyName",
                      this.state.familyname,
                      "required",
                      {
                        className: classes.textDanger,
                      }
                    )}
                    <TextField
                      required={true}
                      name="givenname"
                      style={{ marginTop: "10px" }}
                      label="Given Name"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />

                    {this.validator.message(
                      "Given name",
                      this.state.givenname,
                      "required",
                      {
                        className: classes.textDanger,
                      }
                    )}

                    <TextField
                      required={true}
                      name="middlename"
                      style={{ marginTop: "10px" }}
                      label="Middle Name"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />

                    {this.validator.message(
                      "Given middlename",
                      this.state.middlename,
                      "required",
                      {
                        className: classes.textDanger,
                      }
                    )}

                    <div style={{ marginTop: "20px" }}>
                      {/* <p
                        style={{
                          margin: "10px 0 10px 10px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Gender
                      </p> */}

 <h5 className="poppins-regular" style={{fontSize:"17px"}}>Gender</h5>

                    </div>

                    <div style={{ marginTop: "5px" }}>
                      {/* <div className={classes.formItems}> */}

                      <Grid item xs={12}>
                        {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                        <Select
                          labelId="Input label"
                          style={{ height: "40px" }}
                          id="Select"
                          value={this.state.gender}
                          defaultValue={this.state.gender}
                          onChange={(e) => {
                            this.setState({ gender: e.target.value });
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          {GenderStatus.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* </div> */}
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      {/* <p
                        style={{
                          margin: "10px 0 10px 10px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Martial Status
                      </p> */}


 <h5 className="poppins-regular" style={{fontSize:"17px"}}>Martial Status</h5>

                    </div>

                    <div style={{ marginTop: "5px" }}>
                      {/* <div className={classes.formItems}> */}

                      <Grid item xs={12}>
                        {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                        <Select
                          labelId="Input label"
                          style={{ height: "40px" }}
                          id="Select"
                          value={this.state.maritalstatus}
                          defaultValue={this.state.maritalstatus}
                          onChange={(e) => {
                            this.setState({ maritalstatus: e.target.value });
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          {MartialStatuses.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* </div> */}
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      {/* <p
                        style={{
                          margin: "10px 0 10px 10px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Status
                      </p> */}

<h5 className="poppins-regular" style={{fontSize:"17px"}}>Status</h5>
                      
                    </div>

                    <div style={{ marginTop: "5px" }}>
                      {/* <div className={classes.formItems}> */}

                      <Grid item xs={12}>
                        {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                        <Select
                          labelId="Input label"
                          style={{ height: "40px" }}
                          id="Select"
                          value={status}
                          defaultValue={status}
                          onChange={(e) => {
                            this.setState({ status: e.target.value });
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          {statuses.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* </div> */}
                    </div>
                  </div>
                  <div></div>
                  <div className={classes.formItems}>
                    {/* <p style={{ fontWeight: "bold", fontSize: "16px" }}>Pricing</p> */}
                    <div
                      className={classes.pricing}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      <div className={""}>
                        <TextField
                          required={true}
                          name="emailaddress"
                          style={{ marginTop: "10px" }}
                          label="Email Address"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />

                        {this.validator.message(
                          "Email Address",
                          this.state.emailaddress,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}

                        <TextField
                          required={true}
                          name="postcode"
                          style={{ marginTop: "10px" }}
                          label="Post Code"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                        <TextField
                          required={true}
                          name="phonenumber"
                          style={{ marginTop: "10px" }}
                          label="Phone Number"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                        {this.validator.message(
                          "phone number",
                          this.state.phonenumber,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}

                        <TextField
                          required={true}
                          style={{ marginTop: "10px" }}
                          label="Street 1"
                          size="small"
                          name="streetone"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            inputProps: { min: 1 },
                          }}
                        />

                        {this.validator.message(
                          "Street 1 ",
                          this.state.streetone,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}

                        <TextField
                          required={true}
                          label="Street 2"
                          style={{ marginTop: "10px" }}
                          size="small"
                          name="streettwo"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            inputProps: { min: 1 },
                          }}
                        />
                        {this.validator.message(
                          "Street 2 ",
                          this.state.streettwo,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>
                    </div>
                    <div>
                      <div style={{ marginTop: "20px" }}>
                        <div>
                          <TextField
                            // required={true}
                            label="Nationality"
                            name="nationality"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.nationality}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "Nationality",
                          this.state.nationality,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <div>
                          <TextField
                            label="Place of Birth"
                            name="placeofbirth"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.placeofbirth}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "Place of Birth",
                          this.state.nationality,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <div>
                          <TextField
                            label="Country of Birth"
                            name="countryofbirth"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.countryofbirth}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "Country of birth",
                          this.state.countryofbirth,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <div>
                          <TextField
                            // required={true}
                            label="Location"
                            name="location"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.location}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "location",
                          this.state.location,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <select
                          style={{
                            marginBottom: "20px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                          }}
                          defaultValue=""
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          label="Country"
                          size="small"
                          onChange={this.selectCountry}
                        >
                          {countries.map((option) => (
                            <option value={option.isoCode}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            marginBottom: "20px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                          }}
                          defaultValue=""
                          labelId="stateOrProvince"
                          id="stateOrProvince"
                          value={stateOrProvince}
                          label="State"
                          onChange={this.selectRegion}
                        >
                          {state.map((option) => (
                            <option value={option.isoCode}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            marginBottom: "10px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                            display: "none",
                          }}
                          value={city}
                          labelId="city"
                          id="city"
                          label="City"
                          onChange={this.selectCity}
                        >
                          {myCity.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>

                        <div>
                          <TextField
                            // required={true}
                            label="City"
                            name="city"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.city}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "City",
                          this.state.city,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.addproductsave} style={{}}>
                <Button
                  onClick={this.handleSubmit}
                  style={{ backgroundColor: SKY_BLUE, color: WHITE }}
                  type="submit"
                  variant="contained"
                  color="default"
                  size="medium"
                >
                  Save
                </Button>
              </div>
            </form>
          </>
        )}

        {this.state.selectedOption === "organization" && (
          <>
            <form>
              <div className={classes.formContainer}>
                <div className={classes.formList}>
                  <div className={classes.formItems}>
                    <TextField
                      required={true}
                      name="tradingname"
                      style={{ marginTop: "10px" }}
                      label="Trading Name"
                      size="small"
                      onChange={this.handleChange}
                      variant="outlined"
                      fullWidth={true}
                      InputProps={{ classes: { input: classes.inputHeight } }}
                    />

                    {this.validator.message(
                      "trading Name",
                      this.state.tradingname,
                      "required",
                      {
                        className: classes.textDanger,
                      }
                    )}
                    <div style={{ marginTop: "20px" }}>
                      {/* <p
                        style={{
                          margin: "10px 0 10px 10px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Status
                      </p> */}

                    <h5 className="poppins-regular" style={{fontSize:"17px"}}>Status</h5>

                    </div>

                    <div style={{ marginTop: "5px" }}>
                      {/* <div className={classes.formItems}> */}

                      <Grid item xs={12}>
                        {/* <InputLabel id="Input label">ProductStatus</InputLabel> */}
                        <Select
                          labelId="Input label"
                          style={{ height: "40px" }}
                          id="Select"
                          value={status}
                          defaultValue={status}
                          onChange={(e) => {
                            this.setState({ status: e.target.value });
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          {statuses.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.value}>
                                {item.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      {/* </div> */}
                    </div>
                  </div>
                  <div></div>
                  <div className={classes.formItems}>
                    {/* <p style={{ fontWeight: "bold", fontSize: "16px" }}>Pricing</p> */}
                    <div
                      className={classes.pricing}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      <div className={""}>
                        <TextField
                          required={true}
                          name="emailaddress"
                          style={{ marginTop: "10px" }}
                          label="Email Address"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                        <TextField
                          required={true}
                          name="postcode"
                          style={{ marginTop: "10px" }}
                          label="Post Code"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                        <TextField
                          required={true}
                          name="phonenumber"
                          style={{ marginTop: "10px" }}
                          label="Phone Number"
                          size="small"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                        <TextField
                          required={true}
                          style={{ marginTop: "10px" }}
                          label="Street 1"
                          size="small"
                          name="streetone"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            inputProps: { min: 1 },
                          }}
                        />
                        <TextField
                          required={true}
                          label="Street 2"
                          style={{ marginTop: "10px" }}
                          size="small"
                          name="streettwo"
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth={true}
                          InputProps={{
                            classes: { input: classes.inputHeight },
                            inputProps: { min: 1 },
                          }}
                        />
                        {this.validator.message(
                          "streettwo",
                          this.state.streettwo,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>
                    </div>
                    <div>
                      <div style={{ marginTop: "20px" }}>
                        <select
                          style={{
                            marginBottom: "20px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                          }}
                          defaultValue=""
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          label="Country"
                          size="small"
                          onChange={this.selectCountry}
                        >
                          {countries.map((option) => (
                            <option value={option.isoCode}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            marginBottom: "20px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                          }}
                          defaultValue=""
                          labelId="stateOrProvince"
                          id="stateOrProvince"
                          value={stateOrProvince}
                          label="State"
                          onChange={this.selectRegion}
                        >
                          {state.map((option) => (
                            <option value={option.isoCode}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            marginBottom: "10px",
                            height: "50px",
                            width: "100%",
                            borderColor: "lightgrey",
                            borderRadius: "5px",
                            display: "none",
                          }}
                          value={city}
                          labelId="city"
                          id="city"
                          label="City"
                          onChange={this.selectCity}
                        >
                          {myCity.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>

                        <div>
                          <TextField
                            // required={true}
                            label="City"
                            name="city"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.city}
                            onChange={this.handleChange}
                          />
                        </div>
                        {this.validator.message(
                          "City",
                          this.state.city,
                          "required",
                          {
                            className: classes.textDanger,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.addproductsave} style={{}}>
                <Button
                  onClick={this.handleSubmit}
                  style={{ backgroundColor: SKY_BLUE, color: WHITE }}
                  type="submit"
                  variant="contained"
                  color="default"
                  size="medium"
                >
                  Save
                </Button>
              </div>
            </form>
          </>
        )}

        <div>
          {snackBar ? (
            <SnackBar message={snackBarMessage} type={snackBarType} />
          ) : null}
        </div>
      </>
    );
    // }
  }
}
const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(AddVendor));
