import React, { Component, useId } from "react";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Popup from "../Popup";
import SearchIcon from "@material-ui/icons/Search";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomProducts from "../ProductsModule/CustomProducts";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import BrowseProducts from "../OrderModals/BrowseProducts";
import Box from "@material-ui/core/Box";
import CustomDisocuntProducts from "./CustomDisocuntProducts";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import CreateDiscount from "../../MobileComponents/Discount/CreateDiscount";
import AppliesTo from "../../MobileComponents/Discount/AppliesTo";
import Discount from "../../MobileComponents/Discount/Discount";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import InputAdornment from "@material-ui/core/InputAdornment";

import DiscountCustomerLists from "./DiscountCustomerLists";
import BrowseCustomer from "./BrowseCustomer";
import GlobalConfig from "../../Config";
import Store from "../../index";
import { SKY_BLUE } from "../../colors/colors";
import { connect } from "react-redux";
import CustomersTable from "./CustomersTable";
import helpers from "../../UtilitiesFunctions";

const style = (theme) => ({
  inputHeight: {
    height: 3,
    marginBottom: "10px",
  },

  responsiveForm: {
    backgroundColor: "white",
    borderRadius: "0.8rem",
    padding: "25px",
    marginBottom: "20px",
    boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    outline: ".1rem solid transparent",
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
      marginBottom: "20px",
      boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
      outline: ".1rem solid transparent",
    },
  },
  hideOnSmallScreen: {
    width: "32%",
    position: "fixed",
    paddingRight: "3rem",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      padding: "0px",
    },
  },
  parentBox: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  responsive: {
    display: "flex",
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  paper: {
    backgroundColor: "white",
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // width: "700px",
    // minHeight: "300px",
    // padding: (2, 4, 3),
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  titleroute: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "10px",
      marginLeft: "10px",
    },
  },
  buttonmargin: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      padding: "10px",
    },
  },
});
class DiscountCodesForm extends Component {
  constructor(props) {
    super(props);
    this.updateMyCounter = this.updateMyCounter.bind(this);

    this.state = {
      backdrop: false,
      actionType: "discount",
      discountAmount: null,
      applicationMode: "Manual",
      customerBuys: [],
      customerGets: ["0"],
      discountCode: "",
      discountPercentage: null,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      minimumQuantityOrAmountToBuy: "",
      quantitycustomerGets: 0,
      status: "Active",
      type: "",
      quantityOrAmount: "quantity",
      discountAppliedOn: "productIds",
      eligibleCustomers: [],
      snackbar: false,
      name: "",

      percentageOrFree: "",

      open: false,
      productList: [],
      CustomerGetList: [],
      CustomerBuysList: [],
      customerList: [],
      customerEligibility: "",
      customerModelOpen: false,
    };
  }

  dates = (dates) => {
    var year = "" + dates.getFullYear();
    var month = "" + (dates.getMonth() + 1);
    var day = "" + dates.getDate();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    console.log(month);
    return year + "-" + month + "-" + day;
  };

  setdate = (date) => {
    console.log(date);
    date.setMonth(date.getMonth() - 1);
    console.log(date);
    return this.dates(date);
  };
  setenddate = (date) => {
    console.log(date);
    return this.dates(date);
  };

  handleEndDateChange = (event) => {
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newEndDate = currentDate.split("T");
    this.setState({ endDate: newEndDate[0] });
  };
  handleStartDateChange = (event) => {
    console.log("--------CHANGING START DATE-----------");
    console.log(event);
    let date = new Date(event.toString());
    let currentDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getDate();
    let newStartDate = currentDate.split("T");
    this.setState({ startDate: newStartDate[0] });
  };
  addToCart = (product) => {
    console.log("order added");
    this.props.addToCartAction(product);
  };
  handleCheckBox = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAllproducts = () => {
    this.setState({ customerBuys: ["0"] });
  };
  handleAllCustomers = () => {
    this.setState({ eligibleCustomers: ["0"] });
  };

  handleSubmit = (e) => {
    this.setState({ backdrop: true });
    e.preventDefault();
    const data = {
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.name,
      discountPercentage: this.state.discountPercentage,
      endDate: this.state.endDate,
      minimumQuantityOrAmountToBuy: this.state.minimumQuantityOrAmountToBuy,
      name: this.state.name,
      quantitycustomerGets: this.state.quantitycustomerGets,
      startDate: this.state.startDate,
      status: this.state.status,
      type: this.state.type,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
      eligibleCustomers: this.state.eligibleCustomers,
      applicationMode: this.state.applicationMode,
      customerEligibility: this.state.customerEligibility,
    };

    console.log(JSON.stringify(data));
    fetch(this.props.global_config.basePath + "/promotions/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.POID) {
          fetch(
            this.props.global_config.basePath + "/promotions/" + resp.POID,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer my-token",
              },
            }
          )
            .then((response2) => response2.json())
            .then((data2) => {
              Store.dispatch({
                type: "POST_DISCOUNTS",
                discounts: data2,
              });
              this.setState({ backdrop: false, snackbar: false });

              helpers.toastNotify("success", "Discount Successfully Created!");
            });
        }
        console.log(resp);

        this.props.history.push("/Discount");
      });
  };
  handleOpen = () => {
    this.setState({ open: true });
    this.getProducts();
  };

  handleClose = () => {
    this.setState({ open: false });
    console.log("on close");
  };
  handleOpenCustomerModel = () => {
    this.setState({ customerModelOpen: true });
  };
  handleCloseCustomerModel = () => {
    this.setState({ customerModelOpen: false });
  };
  onPriceChange = (event) => {
    console.log("in handle change");
    this.setState({ [event.target.name]: event.target.value });
  };
  getProducts() {}
  handleDiscount = (e) => {
    this.setState({ backdrop: true });
    e.preventDefault();

    // console.log("Qty customer get: " + this.state.quantitycustomerGets);
    const data = {
      actionType: this.state.actionType,
      discountAmount: this.state.discountAmount,
      customerBuys: this.state.customerBuys,
      customerGets: this.state.customerGets,
      discountCode: this.state.name,
      discountPercentage: this.state.discountPercentage,
      endDate: this.state.endDate,
      minimumQuantityOrAmountToBuy: this.state.minimumQuantityOrAmountToBuy,
      name: this.state.name,
      quantitycustomerGets: this.state.quantitycustomerGets,
      startDate: this.state.startDate,
      status: this.state.status,
      type: this.state.type,
      quantityOrAmount: this.state.quantityOrAmount,
      discountAppliedOn: this.state.discountAppliedOn,
      eligibleCustomers: this.state.eligibleCustomers,
      applicationMode: this.state.applicationMode,
    };
    console.log(data);
    fetch(this.props.global_config.basePath + "/promotions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(data),
    }).then((response, props) => {
      this.setState({ backdrop: false, snackbar: false });
      helpers.toastNotify("success", "Discount Successfully Created!");
    });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  onDelete = (e) => {
    console.log(e);
    var name = null;
    if (e.productId === null) {
      name = e.productName;
      this.setState({
        productList: this.state.productList.filter(
          (item) => item.productName !== name
        ),
        customerBuys: this.state.customerBuys.filter(
          (item) => item !== e.productId
        ),
      });
    } else {
      name = e.productId;
      this.setState({
        productList: this.state.productList.filter(
          (item) => item.productId !== name
        ),
        customerBuys: this.state.customerBuys.filter(
          (item) => item !== e.productId
        ),
      });
    }
  };

  onCustomerDelete = (e) => {
    console.log(e.POID);
    var name = null;
    if (e.POID === null) {
      name = e.name;
      this.setState({
        customerList: this.state.customerList.filter(
          (item) => item.name !== name
        ),
        eligibleCustomers: this.state.eligibleCustomers.filter(
          (item) => item.POID !== e.POID
        ),
      });
    } else {
      console.log("in else on delete customer");
      name = e.POID;
      this.setState({
        customerList: this.state.customerList.filter(
          (item) => item.POID !== name
        ),
        eligibleCustomers: this.state.eligibleCustomers.filter(
          (item) => item !== e.POID
        ),
      });
    }
  };

  updateCustomerCounter = (data) => {
    console.log("in update customer my counter", data);
    //console.log(data);
    this.setState({
      customerList: [...data],
      eligibleCustomers: Object.values(data.map((id) => id.POID)),
    });

    console.log("Eligible customers", this.state.eligibleCustomers);
    console.log("customer List", this.state.customerList);
    this.handleCloseCustomerModel();
  };

  updateMyCounter(data) {
    console.log("in update my counter", data);
    this.setState({
      productList: [...data],
      customerBuys: Object.values(data.map((id) => id.productId)),
      customerGets: Object.values(data.map((id) => id.productId)),
    });
    this.handleClose();
    console.log("customers Buys", this.state.customerBuys);
  }

  render() {
    // console.log("discount from props", this.props);

    const { classes } = this.props;
    const { type } = this.state;
    console.log(this.state.customerList);

    return (
      <>
        <Backdrop
          style={{
            zIndex: 1,
            color: "#fff",
          }}
          open={this.state.backdrop}
        >
          <CircularProgress thickness={2} size={90} color="inherit" />
        </Backdrop>
        {this.state.snackbar ? (
          <>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={6000}
              onClose={this.closeSnack}
            >
              <MuiAlert
                color="success"
                severity="success"
                variant="filled"
                onClose={this.closeSnack}
              >
                Promotions Created Successfully
              </MuiAlert>
            </Snackbar>
          </>
        ) : null}
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h5 className={classes.titleroute}>
            <ArrowBackIosIcon
              fontSize="large"
              style={{ cursor: "pointer" }}
              onClick={(props) => this.props.history.push("/Discount")}
            />
            Create Discount Code
          </h5>

          <div className={classes.responsive}>
            <div className={classes.parentBox}>
              <div className={classes.responsiveForm}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Discount Code
                  </div>
                  <span
                    style={{
                      backgroundColor: "#25a18e",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      this.setState({
                        name: "promo-" + Math.random().toString(36).slice(2),
                      });
                    }}
                  >
                    Generate Code
                  </span>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <TextField
                    name="name"
                    label="SUMMERSALE"
                    // placeholder="e.g. SUMMERSALE"
                    size="small"
                    value={this.state.name}
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{ classes: { input: classes.inputHeight } }}
                  />
                </div>

                <div style={{ paddingTop: "10px" }}>
                  <p style={{ color: "#6d7175", fontWeight: "400" }}>
                    Customers will enter this discount code at checkout.
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Types
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup aria-label="type" name="type" value={type}>
                    <FormControlLabel
                      control={
                        <Radio
                          onChange={this.handleChange}
                          value="Percentage"
                          color="primary"
                          size="small"
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      // value={this
                      control={
                        <Radio
                          value="Fixed Amount"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Fixed Amount"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          value="Buy One Get One"
                          color="primary"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Buy X get Y"
                    />
                  </RadioGroup>
                </div>
              </div>
              {type === "Buy One Get One" && (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Buys
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="quantity"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minumum Quantity of items"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="amount"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount "
                      />
                    </RadioGroup>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {this.state.quantityOrAmount === "quantity" ? (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="Quantity"
                          type="number"
                          size="small"
                          name="minimumQuantityOrAmountToBuy"
                          onChange={this.handleChange}
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "30%" }}>
                        <TextField
                          label="amount"
                          name="minimumQuantityOrAmountToBuy"
                          size="small"
                          onChange={this.handleChange}
                          type="number"
                          variant="outlined"
                          inputprops={{
                            classes: { input: classes.inputHeight },
                          }}
                        />
                      </div>
                    )}
                    <div style={{ width: "63%" }}>
                      <FormControl
                        inputprops={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "75%", marginTop: "18px" }}></div>
                    <div style={{ marginTop: "20px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={() => this.updateMyCounter}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          } //component to send
                          openPopup={this.state.open} //open
                          closePop={() => this.handleClose} //close
                          onClickBtnOpen={() => this.handleOpen()} //on click open
                          onClickBtnClose={() => this.handleClose()} //on click close
                        />
                      </Box>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Box>
                      {/* {this.state.customerBuys.length > 0 ? ( */}
                      <CustomDisocuntProducts
                        products={this.state.productList}
                        // onDelete={this.onDelete}

                        onDelete={this.onDelete}
                      />
                    </Box>
                  </div>
                  <hr />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Customer Gets
                    </div>
                  </div>
                  <p>
                    Customers must add the quantity of items specified below to
                    their cart.
                  </p>

                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "30%", marginRight: "20px" }}>
                      <TextField
                        name="quantitycustomerGets"
                        onChange={this.handleChange}
                        id="filled-search"
                        size="small"
                        label="Quantity"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormControl
                        InputProps={{
                          classes: { input: classes.inputHeight },
                        }}
                        variant="outlined"
                        // className={classes.formControl}
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Any Items from
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // value={age}
                          // onChange={handleChange}
                          label="Any Items from "
                        >
                          <MenuItem value={10}>Specific Products</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginTop: "18px",
                        marginRight: "20px",
                      }}
                    ></div>
                    <div style={{ marginTop: "20px", marginRight: "10px" }}>
                      <Box>
                        <Popup
                          title="Select products"
                          btnVar="contained"
                          btnText="Browse"
                         
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <BrowseProducts
                              updateParent={this.updateMyCounter}
                              onClickBtnClose={() => this.handleClose()}
                            />
                          } //component to send
                          openPopup={this.state.open} //open
                          closePop={() => this.handleClose} //close
                          onClickBtnOpen={() => this.handleOpen()} //on click open
                          onClickBtnClose={() => this.handleClose()} //on click close
                        />
                      </Box>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Box>
                        {/* {this.state.customerBuys.length > 0 ? ( */}
                        <CustomDisocuntProducts
                          products={this.state.productList}
                          // onDelete={this.onDelete}

                          onDelete={this.onDelete}
                         
                        />
                      </Box>
                    </div>
                  </div>
                  <hr />
                  <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                    <p>AT A DISCOUNT VALUE</p>
                  </div>
                  <RadioGroup
                    aria-label="type"
                    name="percentageOrFree"
                    value={this.state.percentageOrFree}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value="minqty"
                      control={<Radio color="primary" size="small" />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="100"
                      control={<Radio color="primary" size="small" />}
                      label="Free"
                    />
                  </RadioGroup>
                  <FormControlLabel
                    value="start"
                    control={<Checkbox color="primary" />}
                    label="Set a maximum number of uses per order"
                    // labelPlacement="start"
                  />
                </div>
              )}

              {type === "Percentage" || type === "Fixed Amount" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Value
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <p style={{ color: "#6d7175", fontWeight: "400" }}>
                      Discount value
                    </p>
                  </div>

                  {type === "Percentage" && (
                    <div>
                      <TextField
                        value={this.state.discountPercentage}
                        variant="outlined"
                        type="number"
                        size="small"
                        // label="0"
                        name="discountPercentage"
                        onChange={this.onPriceChange}
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                  {type === "Fixed Amount" && (
                    <div>
                      <TextField
                        value={this.state.discountAmount}
                        variant="outlined"
                        name="discountAmount"
                        onChange={this.onPriceChange}
                        type="number"
                        size="small"
                        //label="0"
                        InputProps={{
                          classes: { input: classes.inputHeight },
                          endAdornment: (
                            <InputAdornment position="start">Rs</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}

                  <hr />
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Applies to
                  </div>

                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="discountAppliedOn"
                      value={this.state.discountAppliedOn}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="allproducts"
                            size="small"
                            onChange={this.handleAllproducts}
                          />
                        }
                        label="All Products"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            size="small"
                            onChange={this.handleChange}
                            value="specificproducts"
                          />
                        }
                        label="Specific products"
                      />
                      {this.state.discountAppliedOn === "specificproducts" ? (
                        <>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              // name="specificcollection"
                              label="Specific Products"
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: {
                                  input: classes.inputHeight,
                                },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <div>
                              <Box>
                                <Popup
                                  title="Select products"
                                  btnVar="contained"
                                  btnText="Browse"
                                  contentTxt={<hr />}
                                  contentTxtBe={<hr />}
                                  content={
                                    <BrowseProducts
                                      updateParent={this.updateMyCounter}
                                      onClickBtnClose={() => this.handleClose()}
                                    />
                                  } //component to send
                                  openPopup={this.state.open} //open
                                  closePop={() => this.handleClose} //close
                                  onClickBtnOpen={() => this.handleOpen()} //on click open
                                  onClickBtnClose={() => this.handleClose()} //on click close
                                />
                              </Box>
                              {/* </Button> */}
                            </div>
                          </div>
                          <div style={{ width: "100%" }}>
                            <Box>
                              {/* {this.state.customerBuys.length > 0 ? ( */}
                              <CustomDisocuntProducts
                                products={this.state.productList}
                                onDelete={this.onDelete}
                              />
                            </Box>
                          </div>
                        </>
                      ) : null}
                    </RadioGroup>
                  </div>
                </div>
              ) : (
                ""
              )}
              {type === "Freeshipping" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Countries
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={type}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="allcountries"
                        control={<Radio color="primary" size="small" />}
                        label="All Countries"
                      />
                      <FormControlLabel
                        value="selectedcountries"
                        control={<Radio color="primary" size="small" />}
                        label="Selected Countries"
                      />
                    </RadioGroup>
                    <hr />
                    {/* <FormGroup> */}
                    <p>SHIPPING RATES</p>
                    <FormControlLabel
                      value="start"
                      control={<Checkbox color="primary" />}
                      label="Exclude shipping rates over a certain amount"
                      // labelPlacement="start"
                    />
                    {/* </FormGroup> */}
                  </div>
                </div>
              ) : null}
              {type === "Percentage" ||
              type === "Fixed Amount" ||
              type === "Freeshipping" ? (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.8rem",
                    padding: "25px",
                    marginBottom: "20px",
                    boxShadow:
                      "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                    outline: ".1rem solid transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                    >
                      Minimum Requirements
                    </div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <RadioGroup
                      name="quantityOrAmount"
                      value={this.state.quantityOrAmount}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="amount"
                            color="primary"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum purchase amount (Rs)"
                      />
                      {this.state.quantityOrAmount === "amount" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            label="RS 0.00"
                            size="small"
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: {
                                input: classes.inputHeight,
                              },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            value="quantity"
                            onChange={this.handleChange}
                            size="small"
                          />
                        }
                        label="Minimum quantity of items"
                      />
                      {this.state.quantityOrAmount === "quantity" && (
                        <div>
                          <TextField
                            name="minimumQuantityOrAmountToBuy"
                            size="small"
                            value={this.state.minimumQuantityOrAmountToBuy}
                            onChange={this.handleChange}
                            variant="outlined"
                            InputProps={{
                              classes: {
                                input: classes.inputHeight,
                              },
                            }}
                          />
                          <p
                            style={{
                              margin: "10px 0 10px 7px",
                              color: "rgba(109, 113, 117, 1)",
                            }}
                          >
                            Applies to All Products
                          </p>
                        </div>
                      )}
                    </RadioGroup>
                  </div>
                </div>
              ) : null}
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Customer eligibility
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <RadioGroup
                    aria-label="type"
                    size="small"
                    name="customerEligibility"
                    value={this.state.customerEligibility}
                    onChange={this.handleAllCustomers}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          value="everyone"
                          onChange={this.handleChange}
                          size="small"
                        />
                      }
                      label="Everyone"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          color="primary"
                          size="small"
                          value="SpecificCustomer"
                          onChange={this.handleChange}
                        />
                      }
                      label="specific Customers"
                    />

                    {this.state.customerEligibility === "SpecificCustomer" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* <TextField
                              name="SpecificCustomer"
                              label="Specific Customers"
                              // value="minpurchaseamount"
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth={true}
                              InputProps={{
                                classes: { input: classes.inputHeight },
                                endAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            /> */}
                          <Box>
                            <Popup
                              title="Selcet  Customers"
                              btnVar="contained"
                              btnText="Browse"
                              // btnText={
                              //   this.state.discountAppliedOn ===
                              //   "specificcollection"
                              //     ? "Specific Collection"
                              //     : "Specific Products"
                              // }
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              content={
                                // <BrowseCustomer
                                //   updateParent={this.updateCustomerCounter}
                                //   onClickBtnClose={() =>
                                //     this.handleCloseCustomerModel()
                                //   }
                                // />
                                <CustomersTable
                                  updateParent={this.updateCustomerCounter}
                                  onClickBtnClose={() =>
                                    this.handleCloseCustomerModel()
                                  }
                                />
                              } //component to send
                              openPopup={this.state.customerModelOpen} //open
                              closePop={() => this.handleCloseCustomerModel()} //close
                              onClickBtnOpen={() =>
                                this.handleOpenCustomerModel()
                              } //on click open
                              onClickBtnClose={() =>
                                this.handleCloseCustomerModel()
                              } //on click close
                            />
                          </Box>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Box>
                            <DiscountCustomerLists
                              customers={this.state.customerList}
                              onDelete={this.onCustomerDelete}
                            />
                          </Box>
                        </div>
                      </>
                    ) : null}
                  </RadioGroup>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                ></div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "25px",
                  marginBottom: "20px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Active Dates
                  </div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Grid container justify="space-around">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label="Set Start Date"
                        format="yyyy/MM/dd"
                        value={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <KeyboardDatePicker
                        label="Set End Date"
                        format="yyyy/MM/dd"
                        value={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </div>
              </div>
            </div>

            <div className={classes.hideOnSmallScreen}>
              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Summary
                </p>
                <p
                  style={{
                    margin: "15px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  {type === "Percentage" && "No information entered yet"}
                  {type === "FixedAmount" && "No information entered yet"}
                  {type === "Buy One Get One:" && "No information entered yet"}
                  {type === "Freeshipping" && (
                    <>
                      <List className={classes.root} subheader={<li />}>
                        {[0, 1, 2].map((sectionId) => (
                          <li
                            key={`section-${sectionId}`}
                            className={classes.listSection}
                            style={{ listStyleType: "circle" }}
                          >
                            <ul className={classes.ul}>
                              <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            </ul>
                          </li>
                        ))}
                      </List>
                    </>
                  )}
                </p>
                <hr></hr>
                <p
                  style={{
                    margin: "15px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  PERFORMANCE
                </p>
                <p style={{ color: "#6d7175", margin: "10px 0 15px 15px" }}>
                  Discount is not active yet.
                </p>
              </div>

              <div
                style={{
                  padding: "20px 10px",
                  backgroundColor: "#fafbfb",
                  borderRadius: "0.8rem",
                  marginBottom: "10px",
                  boxShadow:
                    "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
                  outline: ".1rem solid transparent",
                }}
              >
                <p
                  style={{
                    margin: "10px 0 0 15px",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  CAN’T COMBINE WITH OTHER AUTOMATIC DISCOUNTS
                </p>
                <p
                  style={{
                    margin: "10px 0 15px 15px",
                    color: "#6d7175",
                  }}
                >
                  Customers won’t be able to enter a code if an automatic
                  discount is already applied at checkout.
                </p>
              </div>
            </div>
          </div>

          <div
            className={classes.buttonmargin}
            style={
              {
                // padding: "30px",
              }
            }
          >
            <Button
              variant="outlined"
              onClick={() => this.props.history.push("/Discount")}
            >
              Discard
            </Button>

            <Button
              type="submit"
              variant="outlined"
              onClick={() => this.handleSubmit}
              style={{ backgroundColor: SKY_BLUE, color: "white" }}
            >
              Save Discount Code
            </Button>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps)(withStyles(style)(DiscountCodesForm));
