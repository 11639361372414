import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Popup from "../../components/Popup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
//Modals
import BrowseProducts from "../../components/OrderModals/BrowseProducts";
import AddCustomItem from "../../components/OrderModals/AddCustomItem";
import Tax from "../../components/OrderModals/Tax";
import AddDiscount from "../../components/OrderModals/AddDiscount";
import CustomProducts from "../../MobileComponents/OrderMobileComponents/CustomProducts";
import CustomShipping from "../../components/OrderModals/AddShipping";
import EditContactInfoModal from "../../components/OrderModals/EditContactInfoModal";
import ShippingAddressModal from "../../components/OrderModals/ShippingAddressModal";
import helpers from "../../UtilitiesFunctions";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Chip } from "@material-ui/core";
import { connect } from "react-redux";
import Store from "../../index";
import AddCustomerModal from "../../components/Customers/AddCustomerModal";
// import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import * as localForage from "localforage";
import Loader from "../../MobileComponents/Loader";
import CustomSnackbar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import {
  getDraftOrders,
  getCloseOrders,
  getOpenOrders,
  getUnpaidOrders,
  getUnfulfilledOrders,
} from "../../utilities/OrdersFilter";
import { ThermalPrinterView } from "../../components/print/thermalPrinterView";
// import { Autocomplete } from "@material-ui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FormControlLabel } from "@mui/material";
const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),

    textAlign: "",
    color: theme.palette.text.primary,
  },
  paperSmall: {
    height: "150px",
    overflowY: "scroll",
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    //bgcolor: theme.palette.backgroundColor.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCustomItemModal: false,
      addCustomerModal: false,
      addDiscountModal: false,
      addShippingModal: false,
      addTaxModal: false,
      browseProductsModal: false,
      editInfoModal: false,
      ShippingAddressModal: false,
      snackbar: false,
      products: [],
      lineItems: [],
      isUnpaidChecked: true,
      isPaidChecked: false,
      isUnfullfilledChecked: true,
      isFullfilledChecked: false,
      customers: [], //info needed here
      currentCustomer: "",
      paid: false, //paid status on btn mark as paid
      billStatus: "pending", //bill status on btn mark as pending
      loader: false,
      loaderMessage: "Loading Orders",
      open: true,
      costPrice: 0,
      errorResponse: "",
      customSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
      taxTotal: 0,
      totalAmount: 0,
      email: "",
      phoneNumber: "",
      name: "",
      orderObject: undefined,
      printReceipt: false,
      discount: undefined,
      payableAmount:0,
      discountAmount:0,
    };
  }
  debounce = (func, delay) => {
    console.log("inDebounced Function");
    let inDebounce;
    return function () {
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func(), delay);
    };
  };
  handleError = async (response) => {
    // console.log("response is: ", response);
    if (response.status === 403) {
      const errorResponse = await response.json();
      this.setState({ errorResponse: errorResponse });
      console.log("response is: ", errorResponse);
      const { message, errors } = errorResponse;
      this.setState({
        customSnackbar: false,
        snackbarMessage: message,
        snackbarType: "error",
      });

      helpers.toastNotify("error", message);
    }
  };
  renderSnackbar = (message, type) => {
    this.setState({
      customSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };

  addProduct = (resp) => {
    // console.log(resp);
    this.setState(function (prevState) {
      return {
        products: [
          ...prevState.products,
          {
            productId: resp.POID,
            productName: resp.name, //productName
            price: resp.price,
            tax: resp.taxRate,
            unitOfMeasure: resp.unitsOfMeasures[0].POID,
            action: "add",
            fulfilled: false,
            quantityAvailable: resp.quantity, //available qty
            costPrice: resp.costPrice,
          },
        ],
      };
    });
  };

  componentDidMount = () => {
    let customer;
    if (this.props.g_orders.length === 0) {
      this.enableLoader("Loading Orders..");

      let url = this.props.global_config.basePath + "/orders";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          Store.dispatch({ type: "GET_ORDERS", orders: data });
          Store.dispatch({
            type: "UNFULFILLED_ORDERS",
            unfulfilled_orders: getUnfulfilledOrders(data),
          });
          Store.dispatch({
            type: "UNPAID_ORDERS",
            unpaid_orders: getUnpaidOrders(data),
          });
          Store.dispatch({
            type: "DRAFT_ORDERS",
            draft_orders: getDraftOrders(data),
          });
          Store.dispatch({
            type: "CLOSE_ORDERS",
            close_orders: getCloseOrders(data),
          });
          Store.dispatch({
            type: "OPEN_ORDERS",
            open_orders: getOpenOrders(data),
          });
          this.disableLoader();
        })
        .catch((err) => {
          this.disableLoader();

          console.log(err);
        });
    }

    if (this.props.g_active_customers.length === 0) {
      let url = this.props.global_config.basePath + "/customers?status=Active";

      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            customers: data,
            previousCustomers: data,
            loading: false,
          });

          Store.dispatch({
            type: "GET_ACTIVE_CUSTOMERS",
            active_customers: data,
          });
        })
        .catch((err) => {});
    } else {
      this.setState({ customers: this.props.g_active_customers });
    }

    try {
      customer = window.history.state.state;
    } catch {}
    if (customer !== undefined) {
      this.handleOnSelect(customer, "customer");
    }
  };
  handleOrderTypesInStore = (orders) => {
    let finalOrder;

    if (!orders) return;

    if (Array.isArray(orders)) {
      finalOrder = orders[0];
    } else {
      finalOrder = orders;
    }
    const { fulfilled, paid, partiallyFulfilled } = finalOrder;
    if (fulfilled === false && paid === false) {
      Store.dispatch({
        type: "POST_MARK_AS_PENDING_ORDERS",
        unfulfilled_orders: finalOrder,
      });
    } else if (paid) {
      Store.dispatch({
        type: "POST_MARK_AS_PAID_ORDERS",
        orders: finalOrder,
      });
    }
  };

  updateProductQuantity = (productIds, productQuantity) => {
    Store.dispatch({
      type: "EDIT_INVENTORY_ITEM_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
    Store.dispatch({
      type: "EDIT_ACTIVE_PRODUCTS_QUANTITY_ON_ORDER",
      id: productIds,
      quantity: productQuantity,
    });
  };

  fetchSpecificOrder = async (resp) => {
    let productIds = [];
    let productQuantity = [];
    const orderResponse = await resp.json();
    console.log("in fetchSpecificOrder: response is -> ", orderResponse);
    let url =
      this.props.global_config.basePath + "/orders/" + orderResponse.orderId;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
    })
      .then(async (response2) => {
        const fetchedOrder = await response2.json();
        console.log("fetched response is: ", await fetchedOrder);
        this.handleOrderTypesInStore(fetchedOrder);
        for (let i in fetchedOrder[0].lineItems) {
          productIds.push(fetchedOrder[0].lineItems[parseInt(i)].productId);
          productQuantity.push(fetchedOrder[0].lineItems[parseInt(i)].quantity);
        }

        this.updateProductQuantity(productIds, productQuantity);
        if (this.state.currentCustomer !== "") {
          Store.dispatch({
            type: "EDIT_CUSTOMER_ON_ORDER",
            orders: fetchedOrder[0],
          });
        }
      })
      .then(() => {
        this.props.history.push({
          pathname: "/ViewOrders",
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState(function (currentState) {
      return { loader: false };
    });
  };
  handleClickOpen = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: true });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: true });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: true });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: true });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: true });
    } else if (name === "editInfoModal") {
      this.setState({ editInfoModal: true });
    } else if (name === "ShippingAddressModal") {
      this.setState({ ShippingAddressModal: true });
    } else if (name === "AddCustomerModal") {
      this.setState({ addCustomerModal: true });
    }
  };

  enableLoader = (message) => {
    this.setState({ loader: true, loaderMessage: message });
  };
  disableLoader = () => {
    this.setState({ loader: false });
  };
  totalLineItems = () => {
    let total = 0.0;

    this.state.lineItems.map(
      (lineItem) => (total = total + lineItem.price * lineItem.quantity)
    );

    if (total <= 0) return "Rs 0.00";
    else return total;
  };
  calculateTax = () => {
    let totalTax = 0.0;
    const { lineItems } = this.state;
    lineItems.map(
      (lineItem) =>
        (totalTax = totalTax + lineItem.price * (lineItem.tax / 100))
    );
    if (totalTax <= 0) return 0.0;
    return totalTax;
  };

  // Handle Unpaid checkbox change
  handleUnpaidCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { isUnpaidChecked: isChecked, isPaidChecked: false }
      // this.validateCheckboxes
    );
  };

  // Handle Paid checkbox change
  handlePaidCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { isPaidChecked: isChecked, isUnpaidChecked: false }
      // this.validateCheckboxes
    );
  };

  // Handle Unfulfilled checkbox change
  handleUnfullfilledCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { isUnfullfilledChecked: isChecked, isFullfilledChecked: false }
      // this.validateCheckboxes
    );
  };

  // Handle Fulfilled checkbox change
  handleFullfilledCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.setState(
      { isFullfilledChecked: isChecked, isUnfullfilledChecked: false }
      // this.validateCheckboxes
    );
  };

  // Validation logic to check valid combinations

  calculateTotal = () => {
    var totalAmount = 0.0;
    var totalTax = 0.0;
    const { lineItems } = this.state;
    console.log(lineItems);
    lineItems.forEach((lineItem) => {
      console.log("in tax calculateTotal function");
      totalTax = totalTax + lineItem.price * (lineItem.tax / 100);
      console.log("total tax is: ", totalTax);
    });
    lineItems.map(
      (lineItem) =>
        (totalAmount += totalTax + lineItem.price * lineItem.quantity)
    );
    if (totalAmount <= 0) return 0;

    const values = { totalAmount, totalTax };
    console.log("values are: " + JSON.stringify(values));
    return values;
  };

  closeLoader = () => {
    this.setState({ loader: false });
  };

  getInventory = (incomingProduct) => {
    const products = incomingProduct.map((item) => {
      const inventoryItem = this.props.g_inventoryItems.find(
        (inv) => inv.POID === item.id
      );

      const productDiscountTax = this.props.g_active_products.find(
        (inv) => inv.POID === item.id
      );

      console.log("here is g_products", productDiscountTax, inventoryItem);

      if (inventoryItem) {
        // Update the item with inventory stock
        return {
          ...item,
          stock: inventoryItem.quantity,
          discountRate: productDiscountTax?.discountRate, // Assuming quantity is the field you want to use for stock
        };
      }

      // If no inventory item found, return the item without changes
      return item;
    });
    // this.setState({lineItems:products});
    console.log("products line items selected:", products);
    console.log("products line items selected11:", this.props.g_inventoryItems);

    return products;
  };

  handleClose = (name) => {
    if (name === "addCustomItemModal") {
      this.setState({ addCustomItemModal: false });
    } else if (name === "addDiscountModal") {
      this.setState({ addDiscountModal: false });
    } else if (name === "browseProductsModal") {
      this.setState({ browseProductsModal: false });
    } else if (name === "addTaxModal") {
      this.setState({ addTaxModal: false });
    } else if (name === "addShippingModal") {
      this.setState({ addShippingModal: false });
    } else if (name === "editInfoModal") {
      this.setState({ editInfoModal: false });
    } else if (name === "ShippingAddressModal") {
      this.setState({ ShippingAddressModal: false });
    } else if (name === "AddCustomerModal") {
      this.setState({ addCustomerModal: false });
    }
  };

  addItem = (item, varName) => {
    if (varName === "product") {
      this.setState(function (currentState) {
        return {
          lineItems: [
            ...currentState.lineItems,
            {
              productId: item.productId,
              productName: item.productName, //productName
              price: item.price,
              tax: item.tax,
              unitOfMeasure: item.unitOfMeasure,
              action: "add",
              fulfilled: false,
              quantity: 1,
              costPrice: item.costPrice,
            },
          ],
        }; //adding items in
      });
    } else if (varName === "customer") {
      this.setState({ currentCustomer: item });
    }
  };

  updateMyCounter = (data, dest, products) => {
    if (dest === "customItem") {
      if (this.state.lineItems.length === 0) {
        this.setState({ lineItems: [data] });
        this.handleClose("addCustomItemModal");
      } else {
        this.setState({ lineItems: [...this.state.lineItems, data] });
        this.handleClose("addCustomItemModal");
      }
    } else if (dest === "dbItem") {
      if (this.state.lineItems.length !== 0) {
        this.state.lineItems.map((existingItem) => {
          data = data.filter(
            (newItem) => newItem.productId != existingItem.productId
          );
        });
      }
      this.setState({
        lineItems: [...this.state.lineItems, ...data],
      });

      this.handleClose("browseProductsModal");
    }
  };

  updateQty = (qty, name) => {
    const array = this.state.lineItems;
    for (var i = 0; i < this.state.lineItems.length; i++) {
      if (array[i].productName === name) {
        array[i] = { ...array[i], quantity: qty };
      }
    }
    this.setState(
      {
        lineItems: array,
      },
      () => {
        this.addDiscount(this.state.discount);
      }
    );
  };

  updatePrice = (price, name) => {
    const array = this.state.lineItems;
    for (var i = 0; i < this.state.lineItems.length; i++) {
      if (array[i].productName === name) {
        array[i] = { ...array[i], price: price };
      }
    }
    this.setState(
      {
        lineItems: array,
      },
      () => {
        this.addDiscount(this.state.discount);
      }
    );
  };

  // addDiscount = (props) => {
  //   console.log("here is discount props", props);
  //   this.setState(
  //     {
  //       discount: props,
  //     },
  //     () => {
  //       if (
  //         (this?.state?.discount?.discount_value
  //           ? helpers
  //               .calculateSubTotalModified(
  //                 this.getInventory(this.state.lineItems)
  //               )
  //               .toFixed(2) - this?.state?.discount?.discount_value
  //           : 0) >
  //         helpers
  //           .calculateSubTotalModified(this.getInventory(this.state.lineItems))
  //           .toFixed(2)
  //       ) {
  //         this.setState({ discount: undefined });
  //         helpers.toastNotify(
  //           "error",
  //           "Discount amount cannot be greater than the total amount"
  //         );
  //         return;
  //       } else {
  //         this.handleClose("addDiscountModal");
  //       }
  //     }
  //   );
  // };


  // addDiscount = (props) => {
  //   console.log("Here is discount props", props);
  
  //   const subtotal = parseFloat(
  //     helpers.calculateSubTotalModified(this.getInventory(this.state.lineItems)).toFixed(2)
  //   );
  
  //   let discountValue = parseFloat(props?.discount_value) || 0;
  //   const discountType = props?.discount_type; // "percentage" or fixed amount
  
  //   // Validate percentage discount
  //   if (discountType === "percentage") {
  //     if (discountValue > 100) {
  //       // this.setState({discount:0}); 
  //       helpers.toastNotify("error", "Percentage discount cannot exceed 100%.");
  //       return;
  //     }
  //     discountValue = (subtotal * discountValue) / 100; // Convert percentage to absolute value
  //   }
  
  //   // Prevent discount from exceeding subtotal or making total <= 0
  //   // if (discountValue > subtotal) {
  //   //   // this.setState({discount:0}); 
  //   //   helpers.toastNotify("error", "Discount cannot be equal to or greater than the total amount.");
  //   //   return;
  //   // }
  
  //   // Ensure total is always positive
  //   const newTotal = subtotal - discountValue;
  //   if (newTotal <= 0) {
  //     // this.setState({discount:0}); 
  //     helpers.toastNotify("error", "Discount makes total zero or negative, which is not allowed.");
  //     return;
  //   }
  
  //   // Update state safely using functional setState
  //   this.setState({ discount: props }, () => {
  //     this.handleClose("addDiscountModal");
  //   });
  // };
  


  // addDiscount = (props) => {
  //   console.log("Here is discount props", props);
  
  //   const subtotal = parseFloat(
  //     helpers.calculateSubTotalModified(this.getInventory(this.state.lineItems)).toFixed(2)
  //   );
  
  //   let discountValue = parseFloat(props?.discount_value) || 0;
  //   const discountType = props?.discount_type; // "percentage" or fixed amount
  
  //   // Validate percentage discount
  //   if (discountType === "percentage") {
  //     if (discountValue > 100) {
  //       helpers.toastNotify("error", "Percentage discount cannot exceed 100%.");
  //       return;
  //     }
  //     discountValue = (subtotal * discountValue) / 100; // Convert percentage to absolute value
  //   }
  
  //   // Calculate tax (assuming a predefined tax rate, e.g., 11%)
  //   const taxAmount = parseFloat(
  //     helpers.calculcateTaxByElevenPercent(this.state.lineItems).toFixed(2)
  //   );
  
  //   // Ensure payable amount equals tax and discount combined
  //   let payableAmount = taxAmount + discountValue;
  //   if (payableAmount > subtotal) {
  //     payableAmount = subtotal; // Ensure total does not exceed subtotal
  //   }
  
  //   // Update state safely using functional setState
  //   this.setState({ discount: props, payableAmount }, () => {
  //     this.handleClose("addDiscountModal");
  //   });
  // };

  // addDiscount = (props) => {
  //   console.log("Here is discount props", props);
  
  //   const subtotal = parseFloat(
  //     helpers.calculateSubTotalModified(this.getInventory(this.state.lineItems)).toFixed(2)
  //   );
  
  //   let discountValue = parseFloat(props?.discount_value) || 0;
  //   const discountType = props?.discount_type; // "percentage" or fixed amount
  
  //   // Validate percentage discount
  //   if (discountType === "percentage") {
  //     if (discountValue > 100) {
  //       helpers.toastNotify("error", "Percentage discount cannot exceed 100%.");
  //       return;
  //     }
  //     discountValue = (subtotal * discountValue) / 100; // Convert percentage to absolute value
  //   }
  
  //   // Calculate tax (assuming a predefined tax rate, e.g., 11%)
  //   const taxAmount = parseFloat(
  //     helpers.calculcateTaxByElevenPercent(this.state.lineItems).toFixed(2)
  //   );
  
  //   // Ensure payable amount equals tax and discount combined
  //   let payableAmount = subtotal - discountValue + taxAmount;
  //   if (payableAmount < 0) {
  //     payableAmount = 0; // Prevent negative payable amount
  //   }
  
  //   // Update state safely using functional setState
  //   this.setState({ discount: props, payableAmount }, () => {
  //     this.handleClose("addDiscountModal");
  //   });
  // };



  addDiscount = (props) => {
    console.log("Here is discount props", props);



    if (props?.discount_value < 0) {
        helpers.toastNotify("error", "Please enter a valid discount value.");
        return;
    }


    const subtotal = parseFloat(
        helpers.calculateSubTotalModified(this.getInventory(this.state.lineItems)).toFixed(2)
    );


    let discountValue = parseFloat(props?.discount_value) || 0;
    const discountType = props?.discount_type; // "percentage" or fixed amount

    let taxAmount = parseFloat(
      helpers.calculcateTaxByElevenPercent(this.state.lineItems).toFixed(2)
  );
    // Validate percentage discount
    if (discountType === "percentage") {
        if (discountValue > 100) {
            helpers.toastNotify("error", "Percentage discount cannot exceed 100%.");
            return;
        }
        discountValue = ((subtotal * discountValue) / 100)+taxAmount; // Convert percentage to absolute value


console.log("here is discount value inside percentage",(discountValue+taxAmount).toFixed(2));
    }

    // Calculate tax before applying discount
    



console.log("here is discount value without percentage", discountValue,(subtotal + taxAmount).toFixed(2))



    // Ensure discount does not exceed subtotal + tax
    // if (discountValue > subtotal + taxAmount) {
    //     helpers.toastNotify("error", `Discount cannot exceed the total amount ($${(subtotal + taxAmount).toFixed(2)}).`);
    //     return;
    // }

    // **NEW LOGIC: If 100% discount, set tax to 0**
    if (discountValue === subtotal) {
        taxAmount = 0;
    }

    // Calculate payable amount
    let payableAmount = subtotal - discountValue + taxAmount;

    if (payableAmount < 0) {
        payableAmount = 0; // Ensure payable amount does not go negative
    }

    if(discountValue> (Number(subtotal) + Number(taxAmount)).toFixed(2)){
      helpers.toastNotify("error", "Discount Value cannot be greater than payable amount");
      return;
    }
    console.log("Here is payable amount from discount", payableAmount,discountValue+taxAmount);

    // Update state safely using functional setState
    this.setState({ discount: props, payableAmount:payableAmount,discountAmount:discountValue.toFixed(2) }, () => {
        // helpers.toastNotify("success", "Discount applied successfully!");
        this.handleClose("addDiscountModal");
    });
    //   this.setState({ discount: props}, () => {
    //     helpers.toastNotify("success", "Discount applied successfully!");
    //     this.handleClose("addDiscountModal");
    // });
};







  
  onDelete = (e) => {
    var name = null;
    if (e.productId === null) {
      name = e.productName;
      this.setState(
        {
          lineItems: this.state.lineItems.filter(
            (item) => item.productName !== name
          ),
        },
        () => {
          console.log("this?.state?.lineItems-1", this.state.lineItems);
          if (this.state.lineItems.length === 0) {
            this.setState({ discount: 0 });
          } else {
            this.addDiscount(this.state.discount);
          }
        }
      );
    } else {
      name = e.productId;
      this.setState(
        {
          lineItems: this.state.lineItems.filter(
            (item) => item.productId !== name
          ),
        },
        () => {
          console.log("this?.state?.lineItems-2", this.state.lineItems);
          if (this.state.lineItems.length === 0) {
            this.setState({ discount: 0 });
          } else {
            this.addDiscount(this.state.discount);
          }

          // this.setState({discount:0});
        }
      );
    }
  };

  closeLoading = () => {
    this.setState({ loader: false });
  };

  onPrintFinish = () => {
    this.setState({ printReceipt: false });
    this.setState({
      lineItems: [],
      currentCustomer: "",
    });
  };

  postOrders(ss) {
    let orderObject = {};
    let orderKey = "";
    localForage
      .iterate(function (value, key, iterationNumber) {
        console.log(key);
        console.log([key, value]);
        Object.assign(orderObject, value);
        console.log(orderObject);
        orderKey = key;
        console.log("orderKey", orderKey);
        console.log("orderObject", orderObject);
        return value, key, iterationNumber;
        // return orderObject
      })
      .then(function (orderObject) {
        console.log(orderObject);

        console.log("Iteration has completed");
      })
      .catch(function (err) {
        console.log(err);
      });
    orderObject?.lineItems?.forEach((o) => {
      console.log(o);
      o.id = undefined;
      delete o.id;
    });
    console.log("Order object before request order create API: ");
    console.log("ooobject", orderObject);
    let url = this.props.global_config.basePath + "/orders";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },
      // convert the React state to JSON and send it as the POST body
      body: JSON.stringify(orderObject),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          this.setState({ orderObject: response, printReceipt: true });
          localForage.removeItem(orderKey, (err, value) => {
            console.log(err, value);
          });
        }
      })
      .then(() => {
        localForage.clear();
      })
      .catch(function (err) {
        localForage.clear();
        helpers.toastNotify("error", "Could Not Create Order.");
      });
  }

  SendServerService(orderObject) {
    const payload = {
      pageSize: this?.props?.printer_Setting?.printer_settings
        ? this?.props?.printer_Setting?.printer_settings?.salesprinterPaperWidth
        : "80mm",

      // data: data,

      OrderscreatedAt: helpers.convertTime(orderObject?.createdAt),
      OrderObject: orderObject,
      type: "SalesReceipt",
      printrecieptdata: this?.props?.company_info?.receipt_metadata,
      // payableAmount: addCommasToNumber(
      //   orderObject?.total -
      //     (orderObject?.discountAmount ? orderObject?.discountAmount : 0)
      // ),
      // discountAmount:
      //   orderObject?.discountAmount > 0
      //     ? addCommasToNumber(orderObject?.discountAmount)
      //     : "",
      // TotalAmount: addCommasToNumber(orderObject?.total),
    };

    fetch(
      `${
        this?.props?.printer_Setting?.printer_settings
          ? this?.props?.printer_Setting?.printer_settings?.printerUrlAddress
          : "http://127.0.0.1:3000/printserver"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
        },
        body: JSON.stringify(payload),
      }
    )
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(async ({ response, status }) => {
        console.log("Jawad response is here", response);
        console.log("RESPONSEEEE");
        console.log(response);

        if (status !== 200) {
          // setLoader(false);
          // helpers.toastNotify("error", response.errors[0]);
          return;
        }

        if (status === 200) {
          // setLoader(false);
          //helpers.toastNotify("success", "Send To Printer Successfully!");
          // Optional: If needed, handle the response further
        }
      })
      .catch(function (err) {
        // error = 1;
        console.log(err);
        // setLoader(false);
        helpers.toastNotify("error", "Please Connect Printer");
      });
  }

  offlineOrder = (status) => {
    let productIds = [];
    let productQuantity = [];
    let offlineCreateOrder = null;
    let subTotal = this.state.lineItems.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
    let taxAmount = this.state.lineItems.reduce((total, item) => {
      return total + (item.price * item.quantity * item.tax) / 100;
    }, 0);
    let orderID = (
      Math.max.apply(
        Math,
        this.props.g_orders.map((o) => {
          return o.orderId;
        })
      ) + 1
    ).toString();

    if (!Number.isInteger(orderID)) {
      orderID = Math.floor(Math.random() * 100000);
    } else {
      orderID = orderID.replace(/,/g, "");
    }

    offlineCreateOrder = {
      customerId: this.state.currentCustomer.id
        ? this.state.currentCustomer.id
        : null,
      lineItems: this.state.lineItems,
      paid: status,
      active: true,
      close: false,
      createdAt: this.getOfflineDate(),
      draft: false,
      fulfilled: false,
      fulfillmentDate: "null",
      orderId: orderID.replace(/,/g, ""),
      partiallyFulfilled: false,
      paymentDate: this.getOfflinePaymentDate(),
      paymentId: "",
      returned: false,
      returnedAmount: 0,
      status: "Active",
      subTotal: subTotal,
      taxAmount: taxAmount,
      taxRate: this.state.lineItems[0].tax,
      total: subTotal + taxAmount,
    };
    delete offlineCreateOrder.billUnitId;
    delete offlineCreateOrder.billStatus;
    delete offlineCreateOrder.currencyCode;

    this.handleOrderTypesInStore(offlineCreateOrder);
    for (let i in offlineCreateOrder.lineItems) {
      productIds.push(offlineCreateOrder.lineItems[parseInt(i)].productId);
      productQuantity.push(offlineCreateOrder.lineItems[parseInt(i)].quantity);
    }
    this.updateProductQuantity(productIds, productQuantity);
    if (this.state.currentCustomer !== "") {
      Store.dispatch({
        type: "EDIT_CUSTOMER_ON_ORDER",
        orders: offlineCreateOrder,
      });
    }
  };

  markAsConfirm = () => {
    if (
      !this.state.isUnfullfilledChecked &&
      !this.state.isFullfilledChecked &&
      !this.state.isPaidChecked &&
      !this.state.isUnpaidChecked
    ) {
      helpers.toastNotify("error", "Please select order status");
      return;
    }

    if (!this.state.isUnfullfilledChecked && !this.state.isFullfilledChecked) {
      helpers.toastNotify(
        "error",
        "Please select fulfilled or unfulfilled status"
      );
      return;
    }
    if (!this.state.isUnpaidChecked && !this.state.isPaidChecked) {
      helpers.toastNotify("error", "Please select paid or unpaid status");
      return;
    }
    console.log("here is line item on checkout", this.state.lineItems);
    const invalidEntries = this.state.lineItems.filter(
      (lineItem) =>
        lineItem.price === null ||
        lineItem.price === "" ||
        lineItem.price === 0 ||
        isNaN(lineItem.price) ||
        lineItem.quantity === null ||
        lineItem.quantity === "" ||
        lineItem.quantity === 0 ||
        isNaN(lineItem.quantity)
    );

    if (invalidEntries.length > 0) {
      helpers.toastNotify("error", "Inputs fields can not be empty or Zero");
      return;
    }

    const updateLineItemsWithDiscount = (lineItems, reduxProducts) => {
      return lineItems.map((item) => {
        // Find the corresponding product from the Redux store by productId
        // const product = reduxProducts.find(p => p.productId === item.productId);
        const productDiscountTax = reduxProducts.find(
          (inv) => inv.POID === item.id
        );

        // If a product is found, add the discountRate to the line item
        if (productDiscountTax) {
          return {
            ...item,
            // discountRate: productDiscountTax.discountRate,
            fulfilled:
              this.state.isFullfilledChecked || this.state.isUnfullfilledChecked
                ? this.state.isFullfilledChecked ||
                  !this.state.isUnfullfilledChecked
                : false,
          };
        }

        // Return the line item as is if no matching product found
        return item;
      });
    };

    // Update the lineItems with discountRate from Redux store
    const updatedLineItems = updateLineItemsWithDiscount(
      this.state.lineItems,
      this.props.g_products
    );

    console.log("here is updated line", updatedLineItems, this.state.lineItems);

    let createOrder = {
      paid:
        this.state.isPaidChecked || this.state.isUnpaidChecked
          ? this.state.isPaidChecked || !this.state.isUnpaidChecked
          : false,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      fulfilled:
        this.state.isFullfilledChecked || this.state.isUnfullfilledChecked
          ? this.state.isFullfilledChecked || !this.state.isUnfullfilledChecked
          : false,
      // lineItems: this.state.lineItems,
      lineItems: updatedLineItems,
      // total: parseInt(helpers
      // .calculateSubTotalModified(this.getInventory(this.state.lineItems))
      // .toFixed(2)),
      total:
        parseFloat(
          helpers
            .calculateSubTotal(this.getInventory(this.state.lineItems))
            .toFixed(2)
        ) +
        parseFloat(
          helpers.calculcateTaxByElevenPercent(this.state.lineItems).toFixed(2)
        ),
        payableAmount:this.state.payableAmount,
      // payableAmount:
      //   this.state.lineItems.length > 0
      //     ? this?.state?.discount?.discount_type === "percentage"
      //       ? parseFloat(
      //           helpers
      //             .calculateSubTotalModified(
      //               this.getInventory(this.state.lineItems)
      //             )
      //             .toFixed(2)
      //         ) -
      //         parseFloat(
      //           (helpers
      //             .calculateSubTotalModified(
      //               this.getInventory(this.state.lineItems)
      //             )
      //             .toFixed(2) *
      //             this?.state?.discount?.discount_value) /
      //             100
      //         ) +
      //         parseFloat(
      //           helpers
      //             .calculcateTaxByElevenPercent(this.state.lineItems)
      //             .toFixed(2)
      //         )
      //       : this?.state?.discount?.discount_value
      //       ? parseFloat(
      //           helpers
      //             .calculateSubTotalModified(
      //               this.getInventory(this.state.lineItems)
      //             )
      //             .toFixed(2) - this?.state?.discount?.discount_value
      //         ) +
      //         parseFloat(
      //           helpers
      //             .calculcateTaxByElevenPercent(this.state.lineItems)
      //             .toFixed(2)
      //         )
      //       : parseFloat(
      //           helpers
      //             .calculateSubTotalModified(
      //               this.getInventory(this.state.lineItems)
      //             )
      //             .toFixed(2)
      //         ) +
      //         parseFloat(
      //           helpers
      //             .calculcateTaxByElevenPercent(this.state.lineItems)
      //             .toFixed(2)
      //         )
      //     : 0,
      // discountAmount:
      //   this.state.lineItems.length > 0
      //     ? this?.state?.discount?.discount_type === "percentage"
      //       ? helpers
      //           .calculateSubTotalModified(
      //             this.getInventory(this.state.lineItems)
      //           )
      //           .toFixed(2) -
      //         (helpers
      //           .calculateSubTotalModified(
      //             this.getInventory(this.state.lineItems)
      //           )
      //           .toFixed(2) *
      //           this?.state?.discount?.discount_value) /
      //           100
      //       : this?.state?.discount?.discount_value
      //       ? helpers
      //           .calculateSubTotalModified(
      //             this.getInventory(this.state.lineItems)
      //           )
      //           .toFixed(2) - this?.state?.discount?.discount_value
      //       : helpers
      //           .calculateSubTotalModified(
      //             this.getInventory(this.state.lineItems)
      //           )
      //           .toFixed(2)
      //     : 0,
      discountAmount:this.state.discountAmount,
      discountReason:
        this.state.discount?.discount_reason === ""
          ? null
          : this.state.discount?.discount_reason,
    };

    console.log("create order go::::", createOrder);

    this.enableLoader("Please Wait...");

    let urll = this.props.global_config.basePath + "/orders";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(({ response, status }) => {
        this.disableLoader();
        console.log("here is response from exceed quantity", response);
        if (status !== 200) {
          helpers.toastNotify("error", response.message);
          return;
        }
        let productIds = [];
        let productQuantity = [];

        this.state.lineItems.map((item) => {
          if (item.productId) {
            productIds.push(item.productId);
            productQuantity.push(item.quantity);
          }
        });
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });
        if (this.state?.isFullfilledChecked) {
          this.updateProductQuantity(productIds, productQuantity);
        }

        // this.setState({ orderObject: response, printReceipt: true });
        this.setState({ orderObject: response });
        this.setState({
          lineItems: [],
          currentCustomer: "",
          isUnpaidChecked: false,
          isPaidChecked: false,
          isUnfullfilledChecked: false,
          isFullfilledChecked: false,
        });
        console.log(response);
        //this.SendServerService(response);
        helpers.toastNotify("success", "Order Successfully Created!");
        this.setState({ discount: "" });
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  markAsfullfiled = () => {
    console.log("here is line item on checkout", this.state.lineItems);
    const invalidEntries = this.state.lineItems.filter(
      (lineItem) =>
        lineItem.price === null ||
        lineItem.price === "" ||
        lineItem.price === 0 ||
        isNaN(lineItem.price) ||
        lineItem.quantity === null ||
        lineItem.quantity === "" ||
        lineItem.quantity === 0 ||
        isNaN(lineItem.quantity)
    );

    if (invalidEntries.length > 0) {
      helpers.toastNotify("error", "Inputs fields can not be empty or Zero");
      return;
    }

    let createOrder = {
      paid: true,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      fulfilled: true,
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
      discountAmount: helpers.getDiscountAmount(this?.state?.discount),
      discountReason:
        this.state.discount?.discount_reason === ""
          ? null
          : this.state.discount?.discount_reason,
    };

    console.log("create order go::::", createOrder);

    this.enableLoader("Please Wait...");

    let urll = this.props.global_config.basePath + "/orders";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(({ response, status }) => {
        this.disableLoader();
        console.log("here is response from exceed quantity", response);
        if (status !== 200) {
          helpers.toastNotify("error", response.message);
          return;
        }
        let productIds = [];
        let productQuantity = [];

        this.state.lineItems.map((item) => {
          if (item.productId) {
            productIds.push(item.productId);
            productQuantity.push(item.quantity);
          }
        });
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });
        this.updateProductQuantity(productIds, productQuantity);

        // this.setState({ orderObject: response, printReceipt: true });
        this.setState({ orderObject: response });
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        console.log(response);
        this.SendServerService(response);
        helpers.toastNotify("success", "Order Successfully Created!");
        this.setState({ discount: "" });
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };
  markAsunfullFilled = () => {
    console.log("here is line item on checkout", this.state.lineItems);
    const invalidEntries = this.state.lineItems.filter(
      (lineItem) =>
        lineItem.price === null ||
        lineItem.price === "" ||
        lineItem.price === 0 ||
        isNaN(lineItem.price) ||
        lineItem.quantity === null ||
        lineItem.quantity === "" ||
        lineItem.quantity === 0 ||
        isNaN(lineItem.quantity)
    );

    if (invalidEntries.length > 0) {
      helpers.toastNotify("error", "Inputs fields can not be empty or Zero");
      return;
    }

    let createOrder = {
      paid: true,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
      discountAmount: helpers.getDiscountAmount(this?.state?.discount),
      discountReason:
        this.state.discount?.discount_reason === ""
          ? null
          : this.state.discount?.discount_reason,
    };

    console.log("create order go::::", createOrder);

    this.enableLoader("Please Wait...");

    let urll = this.props.global_config.basePath + "/orders";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(({ response, status }) => {
        this.disableLoader();
        console.log("here is response from exceed quantity", response);
        if (status !== 200) {
          helpers.toastNotify("error", response.message);
          return;
        }
        let productIds = [];
        let productQuantity = [];

        this.state.lineItems.map((item) => {
          if (item.productId) {
            productIds.push(item.productId);
            productQuantity.push(item.quantity);
          }
        });
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });
        this.updateProductQuantity(productIds, productQuantity);

        // this.setState({ orderObject: response, printReceipt: true });
        this.setState({ orderObject: response });
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        console.log(response);
        this.SendServerService(response);
        helpers.toastNotify("success", "Order Successfully Created!");
        this.setState({ discount: "" });
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  markAsPaidUnpaid = () => {
    console.log("here is line item on checkout", this.state.lineItems);
    const invalidEntries = this.state.lineItems.filter(
      (lineItem) =>
        lineItem.price === null ||
        lineItem.price === "" ||
        lineItem.price === 0 ||
        isNaN(lineItem.price) ||
        lineItem.quantity === null ||
        lineItem.quantity === "" ||
        lineItem.quantity === 0 ||
        isNaN(lineItem.quantity)
    );

    if (invalidEntries.length > 0) {
      helpers.toastNotify("error", "Inputs fields can not be empty or Zero");
      return;
    }

    let createOrder = {
      paid: false,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
      discountAmount: helpers.getDiscountAmount(this?.state?.discount),
      discountReason:
        this.state.discount?.discount_reason === ""
          ? null
          : this.state.discount?.discount_reason,
    };

    console.log("create order go::::", createOrder);

    this.enableLoader("Please Wait...");

    let urll = this.props.global_config.basePath + "/orders";
    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then(async (response) => ({
        response: await response.json(),
        status: response.status,
      }))
      .then(({ response, status }) => {
        this.disableLoader();
        console.log("here is response from exceed quantity", response);
        if (status !== 200) {
          helpers.toastNotify("error", response.message);
          return;
        }
        let productIds = [];
        let productQuantity = [];

        this.state.lineItems.map((item) => {
          if (item.productId) {
            productIds.push(item.productId);
            productQuantity.push(item.quantity);
          }
        });
        Store.dispatch({
          type: "APPEND_ORDERS",
          orders: response,
        });
        this.updateProductQuantity(productIds, productQuantity);

        // this.setState({ orderObject: response, printReceipt: true });
        this.setState({ orderObject: response });
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        console.log(response);
        this.SendServerService(response);
        helpers.toastNotify("success", "Order Successfully Created!");
        this.setState({ discount: "" });
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  markAsPending = () => {
    this.enableLoader("Please Wait...");

    let createOrder = {
      paid: false,
      billUnitId: 2,
      currencyCode: "PKR",
      billStatus: "pending",
      customerId: this.state?.currentCustomer?.id,
      lineItems: this.state.lineItems,
    };

    let urll = this.props.global_config.basePath + "/orders";

    fetch(urll, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer saad",
      },
      body: JSON.stringify(createOrder),
    })
      .then((response) => response.json())
      .then((response) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });

        this.setState({ orderObject: response, printReceipt: true });
        helpers.toastNotify("success", "Order Successfully Created!");
      })
      .catch((e) => {
        this.disableLoader();
        this.setState({
          lineItems: [],
          currentCustomer: "",
        });
        helpers.toastNotify("error", "Something Went Wrong.");
      });
  };

  addZero = (x, n) => {
    while (x.toString().length < n) {
      x = "0" + x;
    }
    return x;
  };
  getOfflineDate = () => {
    const d = new Date();
    let day = this.addZero(d.getDate(), 2);
    let month = this.addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    let h = this.addZero(d.getHours(), 2);
    let m = this.addZero(d.getMinutes(), 2);
    let s = this.addZero(d.getSeconds(), 2);
    let ms = this.addZero(d.getMilliseconds(), 3);
    let time =
      year + "." + month + "." + day + "." + h + "." + m + "." + s + "." + ms;
    return time;
  };
  getOfflinePaymentDate = () => {
    const d = new Date();
    let day = this.addZero(d.getDate(), 2);
    let month = this.addZero(d.getMonth() + 1, 2);
    let year = d.getFullYear();
    return year + "-" + month + "-" + day;
  };

  createOrderWithInvoice = () => {
    console.log(this.state);

    this.enableLoader("Please Wait...");
    if (this.state.currentCustomer.length < 1) {
      helpers.toastNotify("error", "Customer Not Selected!");
      this.disableLoader();
      this.setState({
        lineItems: [],
      });
    } else {
      let total = 0;
      let subtotal = 0;
      let emailTemplate = `
          <body style="font-family: sans-serif;background-color:white;background: url('https://i.postimg.cc/d1Qmcgmm/f50524ee5f161f437400aaf215c9e12f.jpg') cover; ">
            <table style="width: 100%; text-align: center;padding-bottom: 10px;background-color:white;background: url('https://i.postimg.cc/d1Qmcgmm/f50524ee5f161f437400aaf215c9e12f.jpg') cover">
              <tr>
                <td>
                  <img src="https://i.postimg.cc/xdZHBq8Y/logo.png" width="10%" />
                </td>
              </tr>
              <tr>
                <td style="font-weight: bold;padding-bottom: 10px;">HULM</td>
              </tr>
              <tr>
                <td style="padding-bottom: 10px;">ORDER # 3997</td>
              </tr>
              <tr>
                <td style="font-weight: bold;padding-bottom: 10px;">THANK YOU</td>
              </tr>
            </table>
        
            <table style="width: 100%;padding:15px 0px;background-color:  #50a5ac;color: white;">
              <tr>
                <th style="width: 20%">IMAGE</th>
                <th style="width: 60%">PRODUCT NAME</th>
                <th style="width: 20%">PRODUCT QUANTITY</th>
              </tr>
            </table>
            <table style="width: 100%; text-align: center">`;

      this.state.lineItems.map((item) => {
        subtotal += item.price * item.quantity;
        total = subtotal;
        emailTemplate +=
          `<tr>
                <td style="width: 20%">
                  <img src="https://i.postimg.cc/xdZHBq8Y/logo.png" style="width: 100px" />
                </td>
                <td style="width: 60%">` +
          item.productName +
          `</td>
                <td style="width: 20%">` +
          item.quantity +
          `</td>
              </tr>`;
      });

      emailTemplate +=
        `</table>
        
            <table
              style="
                width: 100%;
                background-color: #50a5ac;
                color: white;
                padding: 20px 30px;
              "
            >
              <td style="width: 75%">
                <tr>
                  <td></td>
                  <td>Subtotal:</td>
                  <td style="font-weight: bold">` +
        subtotal +
        `</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td></td>
                  <td>Shipping:</td>
                  <td>Calculated at next step</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td></td>
                  <td style="font-weight: bold">Taxes:</td>
                  <td>62.1231231212</td>
                </tr>
              </td>
            </table>
            <table style="width: 100%; background-color: #2e6d72; color: white">
              <td>
                <tr style="width: 26%; float: right">
                  <td style="width: 50%; font-weight: bold">TOTAL:</td>
                  <td style="width: 23%; font-weight: bold">` +
        total +
        `</td>
                </tr>
              </td>
            </table>
          </body>
        `;

      let url = "https://email.hulmsolutions.com/api/email/sendhtml";

      let emailObj = {
        url: "string",
        name: "string",
        receiverEmail: this.state.currentCustomer.email,
        email: this.state.currentCustomer.email,
        companyName: "string",
        message: emailTemplate,
        emailhtmlBody: emailTemplate,
        emailSubject: "Order Invoice - HULM POS",
        path: "path",
        adminDetails: { adminUserName: "string", adminPassword: "string" },
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: JSON.stringify(emailObj),
      })
        .then((response) => response.json())
        .then((response) => {
          this.disableLoader();
          helpers.toastNotify(
            "success",
            `Invoice Sent To ${this.state.currentCustomer.name}`
          );
          this.setState({
            lineItems: [],
            currentCustomer: "",
          });
        })
        .catch((error) => {
          this.disableLoader();
          this.setState({
            lineItems: [],
            currentCustomer: "",
          });
          helpers.toastNotify("error", `Something went wrong!`);
        });
    }
  };

  createOrderAndSendInvoice = () => {
    var createOrder = null;
    if (!this.state.currentCustomer.id) {
      alert("Please Select a customer to send the invoice");
      return;
    }
    if (
      this.state.lineItems.length > 0 &&
      helpers.checkQuantity(this.state.lineItems, this.state.products)
    ) {
      this.setState(function (currentState) {
        return { loader: true };
      });

      createOrder = {
        customerId: this.state.currentCustomer.id
          ? this.state.currentCustomer.id
          : null,
        lineItems: this.state.lineItems,
        paid: false,
      };

      let stringifyString;
      if (createOrder != null) {
        stringifyString = JSON.stringify(createOrder);
      }
      console.log(stringifyString);
      let url = this.props.global_config.basePath + "/orders/sendInvoice";

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
        body: stringifyString, //JSON.stringify(createOrder),
      })
        .then((response) => {
          if (response.status === 403) this.handleError(response);
          else if (response.status === 200) this.fetchSpecificOrder(response);
        })
        .catch(() => {
          helpers.toastNotify("error", "Something Went Wrong.");

          this.setState({ loader: false });
        });
    } else {
      this.setState({ snackbar: false });
      helpers.toastNotify("error", "Error Occured!");
    }
  };
  getCustomerData = (customerID) => {
    console.log("Cusotmer ID: ", customerID);
    if (customerID !== null && customerID !== "null") {
      const customers = this.props.g_active_customers.concat(
        this.props.g_archived_customers
      );

      const customerData = customers.find(
        (customer) => customer.id === customerID
      );
      this.setState({ customerData: customerData });
    }
  };
  updateShippingAddress = (shippingAddress) => {
    const updateAddres = {
      address: [
        {
          addressContactId: this.state.currentCustomer.address[0].addressId,
          addressId: this.state.currentCustomer.address[0].addressId,
          city: shippingAddress.city,
          country: shippingAddress.country,
          postCode: shippingAddress.postal_code,
          stateOrProvince: shippingAddress.stateOrProvince,
          street: shippingAddress.address,
          preferred: true,
        },
      ],
    };

    let url =
      this.props.global_config.basePath +
      `/customers/${this.state.currentCustomer.POID}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(updateAddres),
    })
      .then((resp) => {
        console.log("Resp" + resp);
        let url =
          this.props.global_config.basePath +
          `/customers/${this.state.currentCustomer.POID}`;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        })
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_ACTIVE_CUSTOMER",
              updated_customer: data2,
            });
          });
        this.getCustomerData(this.state.currentCustomer.POID);
      })
      .catch((err) => {
        this.setState({ backdrop: false, errorSnackbar: false });
        helpers.toastNotify("error", "Could Not Update Shipping Address.");
      });
    this.handleClose("ShippingAddressModal");
  };
  updateContactInfo = (data) => {
    console.log("data from contact modal...", data);
    this.handleClose("editInfoModal");
    const updateData = {
      email: data.email,
      phoneNumber: data.phone_number,
      phoneNumberContactId: this.state.currentCustomer.phoneNumberContactId,
      phoneNumberId: this.state.currentCustomer.phoneNumberId,
      emailContactId: this.state.currentCustomer.emailContactId,
      emailId: this.state.currentCustomer.emailId,
      name: data.name,
      querry: "name",
    };

    console.log(updateData);

    let url =
      this.props.global_config.basePath +
      `/customers/${this.state.currentCustomer.POID}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
      },

      body: JSON.stringify(updateData),
    })
      .then((resp) => {
        let url =
          this.props.global_config.basePath +
          `/customers/${this.state.currentCustomer.POID}`;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer my-token",
          },
        })
          .then((response2) => response2.json())
          .then((data2) => {
            Store.dispatch({
              type: "EDIT_CUSTOMERS",
              updated_customer: data2,
            });
            this.setState({ currentCustomer: data2 });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleOnSearchCustomers = (string, results) => {
    console.log(string, results);
  };

  handleOnSearchProducts = (string, results) => {
    console.log(string, results);
  };

  handleOnSearchCustomers = (string, results) => {
    console.log(string, results);
  };

  handleOnSelect = (item, name) => {
    if (name === "product") {
      this.addItem(item, name);
    } else if (name === "customer") {
      this.addItem(item, name);
    }
  };

  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  onHandleBack = (props) => {
    this.props.history.push("/");
  };
  setSnackbarToFalse = () => {
    setTimeout(() => {
      this.setState({ customSnackbar: false });
    }, 3000);
  };

  handleDiscountsChange = (e, selectedvalue) => {
    console.log(
      "here is discount change when discount change",
      e.target,
      selectedvalue
    );
  };

  render() {
    const { classes } = this.props;
    console.log("here is all lineitems", this.state.lineItems);
    const discounts = this.props.g_discount_state;
    console.log(
      "checkbox condition check",
      this.state.isUnpaidChecked,
      this.state.isPaidChecked,
      this.state.isUnfullfilledChecked,
      this.state.isFullfilledChecked
    );
    const {
      customSnackbar,
      snackbarMessage,
      snackbarType,
      loader,
      loaderMessage,
    } = this.state;
    console.log("here is order created check", this.state.lineItems);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    if (customSnackbar) {
      //agar snackbar true hai to usay false karde wrna wo har render bar display hoga
      console.log("in snackbar condiiton");
      this.setSnackbarToFalse();
    }

    return (
      <>
      {this.state.loader ? (
        <Loader value={loaderMessage} />
      ) : (
        <div className={classes.root}>
          {this.state.snackbar ? <></> : null}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.rounded}>
                <Box
                  className={classes.box}
                  p={{ xs: 1, sm: 2 }}
                  width="100%"
                  style={{
                    backgroundColor: SKY_BLUE,
                  }}
                >
                  <Grid
                    container
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item style={{ marginLeft: { xs: "2%", sm: "1%" } }}>
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{ 
                          color: "white",
                          fontSize: { xs: "1.2rem", sm: "1.5rem" }
                        }}
                      >
                        Orders
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginRight: { xs: "2%", sm: "1%" } }}></Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
    
            <Grid item xs={12} md={12} lg={8}>
              <Paper className={classes.paper}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box display="flex" p={1} width="100%" flexWrap="wrap" alignItems="center">
                      <Box p={1} flexGrow={1}>
                        <Typography variant="h6">Create Order</Typography>
                      </Box>
                      {/* <Box p={1}>
                        <Popup
                          title="Add Custom Item"
                          btnText="Add Custom Item"
                          btnColor={SKY_BLUE}
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <AddCustomItem
                              onClickBtnClose={() => this.handleClose("A")}
                              updateParent={this.updateMyCounter}
                            />
                          }
                          openPopup={this.state.addCustomItemModal}
                          onClickBtnOpen={() =>
                            this.handleClickOpen("addCustomItemModal")
                          }
                          onClickBtnClose={() =>
                            this.handleClose("addCustomItemModal")
                          }
                        />
                      </Box> */}
                    </Box>
                  </div>
    
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                    }}
                  >
                    <Box display="flex" p={1} width="100%">
                      <Box p={1}>
                        <BrowseProducts
                          updateParent={this.updateMyCounter}
                          onClickBtnClose={() =>
                            this.handleClose("browseProductsModal")
                          }
                        />
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: "block", width: "100%" }}>
                    <Box>
                      {this.state.lineItems.length > 0 ? (
                        <CustomProducts
                          products={this.getInventory(this.state.lineItems)}
                          onDelete={this.onDelete}
                          isDelete={true}
                          getQty={this.updateQty}
                          updatePrice={this.updatePrice}
                          isPriceUpdateable={true}
                          isRefund={false}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </div>
    
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      overflowX: "auto", // Allow horizontal scrolling on small screens
                    }}
                  >
                    <table style={{ margin: 10, width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>Order Discount</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            {this?.state?.discount
                              ? this?.state?.discount?.discount_type === "percentage"
                                ? this?.state?.discount?.discount_value + "%"
                                : "Rs " + this?.state?.discount?.discount_value
                              : 0}
                          </td>
                          <td>
                            <Popup
                              title="Add Discount"
                              btnText="Add Order Discount"
                              btnTextColor={SKY_BLUE}
                              btnDisable={this.state.lineItems.length > 0 ? false : true}
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              content={
                                <AddDiscount
                                  onClickBtnClose={() =>
                                    this.handleClose("addDiscountModal")
                                  }
                                  setDiscount={this.addDiscount}
                                />
                              }
                              openPopup={this.state.addDiscountModal}
                              closePop={() => this.handleClose("addDiscountModal")}
                              onClickBtnOpen={() =>
                                this.handleClickOpen("addDiscountModal")
                              }
                              onClickBtnClose={() =>
                                this.handleClose("addDiscountModal")
                              }
                            />
                          </td>
                        </tr>
    
                        <tr>
                          <td>Products Discount Applied</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0
                              ? helpers
                                  .calculateTotalDiscount(
                                    this.getInventory(this.state.lineItems)
                                  )
                                  .toFixed(2)
                              : 0}
                          </td>
                        </tr>
    
                        <tr>
                          <td>Tax Applied</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0
                              ? helpers
                                  .calculcateTaxByElevenPercent(this.state.lineItems)
                                  .toFixed(2)
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0 ? (
                              <>
                                {parseFloat(
                                  helpers
                                    .calculateSubTotal(
                                      this.getInventory(this.state.lineItems)
                                    )
                                    .toFixed(2)
                                )}
                              </>
                            ) : (
                              <>0</>
                            )}
                          </td>
                        </tr>
    
                        <tr>
                          <td>Total Amount with (Tax)</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0 ? (
                              <>
                                {parseFloat(
                                  helpers
                                    .calculateSubTotal(
                                      this.getInventory(this.state.lineItems)
                                    )
                                    .toFixed(2)
                                ) +
                                  parseFloat(
                                    helpers
                                      .calculcateTaxByElevenPercent(
                                        this.state.lineItems
                                      )
                                      .toFixed(2)
                                  )}
                              </>
                            ) : (
                              <>0</>
                            )}
                          </td>
                        </tr>
    
                        <tr>
                          <td>Total Amount with (Discount)</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0
                              ? (() => {
                                  const subtotal = parseFloat(
                                    helpers
                                      .calculateSubTotalModified(
                                        this.getInventory(this.state.lineItems)
                                      )
                                      .toFixed(2)
                                  );
                                  
                                  let discountAmount = 0;
                                  
                                  if (this?.state?.discount) {
                                    if (
                                      this?.state?.discount?.discount_type ===
                                      "percentage"
                                    ) {
                                      discountAmount =
                                        (subtotal *
                                          this?.state?.discount?.discount_value) /
                                        100;
                                    } else if (this?.state?.discount?.discount_value) {
                                      discountAmount =
                                        this?.state?.discount?.discount_value;
                                    }
                                  }
                                  
                                  // Ensure discount does not exceed subtotal
                                  if (discountAmount > subtotal) {
                                    return subtotal.toFixed(2);
                                  }
                                  
                                  const totalAfterDiscount = Math.max(
                                    0,
                                    subtotal - discountAmount
                                  );
                                  
                                  return totalAfterDiscount.toFixed(2);
                                })()
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Payable Amount with (Tax + Discount)</td>
                          <td>:</td>
                          <td style={{ paddingLeft: "5px" }}>
                            Rs{" "}
                            {this.state.lineItems.length > 0
                              ? (() => {
                                  const subtotal = parseFloat(
                                    helpers
                                      .calculateSubTotalModified(
                                        this.getInventory(this.state.lineItems)
                                      )
                                      .toFixed(2)
                                  );
                                  
                                  const tax = parseFloat(
                                    helpers
                                      .calculcateTaxByElevenPercent(
                                        this.state.lineItems
                                      )
                                      .toFixed(2)
                                  );
                                  
                                  let discountAmount = 0;
                                  
                                  if (this?.state?.discount) {
                                    if (
                                      this?.state?.discount?.discount_type ===
                                      "percentage"
                                    ) {
                                      discountAmount =
                                        ((subtotal *
                                          this?.state?.discount?.discount_value) /
                                          100) +
                                        tax;
                                    } else if (this?.state?.discount?.discount_value) {
                                      discountAmount =
                                        this?.state?.discount?.discount_value;
                                    }
                                  }
                                  
                                  let totalPayable =
                                    (Number(subtotal) + Number(tax)).toFixed(2) -
                                    discountAmount;
                                  if (totalPayable < 0) {
                                    return (subtotal + tax).toFixed(2);
                                  }
    
                                  console.log(
                                    "here is subtotal: " +
                                      (Number(subtotal) + Number(tax)),
                                    totalPayable,
                                    discountAmount,
                                    tax,
                                    "loo"
                                  );
    
                                  return totalPayable.toFixed(2);
                                })()
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
    
                  {this.state.lineItems.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "white",
                        }}
                      >
                        <Box display="flex" flexDirection={"row"} p={1}>
                          <Box p={1}></Box>
                        </Box>
                      </div>
    
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "white",
                          justifyItems: "flex-end",
                          flexWrap: "wrap", // Allow wrapping on small screens
                        }}
                      >
                        <Box 
                          display="flex" 
                          p={1} 
                          width="100%" 
                          flexWrap="wrap" 
                          sx={{ 
                            flexDirection: { xs: "column", sm: "row" } 
                          }}
                        >
                          <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.isUnpaidChecked}
                                    onChange={this.handleUnpaidCheckboxChange}
                                    color="primary"
                                  />
                                }
                                label="Unpaid"
                              />
    
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.isPaidChecked}
                                    onChange={this.handlePaidCheckboxChange}
                                    color="primary"
                                  />
                                }
                                label="Paid"
                              />
    
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.isUnfullfilledChecked}
                                    onChange={this.handleUnfullfilledCheckboxChange}
                                    color="primary"
                                  />
                                }
                                label="Unfullfilled"
                              />
    
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.isFullfilledChecked}
                                    onChange={this.handleFullfilledCheckboxChange}
                                    color="primary"
                                  />
                                }
                                label="Fullfilled"
                              />
                            </div>
                          </Box>
    
                          <Box sx={{ mt: { xs: 1, sm: 0 } }}>
                            <Button
                              fullWidth
                              style={{
                                textTransform: "none",
                                marginLeft: { xs: 0, sm: "10px" },
                                marginBottom: { xs: "8px", sm: 0 }
                              }}
                              variant="contained"
                              onClick={this.markAsConfirm}
                            >
                              Confirm Order
                            </Button>
                          </Box>
                        </Box>
                      </div>
                      {customSnackbar ? (
                        <CustomSnackbar
                          message={snackbarMessage}
                          type={snackbarType}
                        />
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              {this.state.currentCustomer === "" ? (
                <>
                  <Paper className={classes.paper}>
                    <Box>
                      <Box p={1} flexGrow={1} bgcolor="white">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="subtitle1">
                            Find or create a customer
                          </Typography>
                          <PersonAddIcon
                            style={{
                              cursor: "pointer",
                              color: SKY_BLUE,
                            }}
                            onClick={() => this.handleClickOpen("AddCustomerModal")}
                          />
                        </Box>
                        <div style={{ marginLeft: { xs: "10px", sm: "25px" } }}>
                          <Box>
                            <Popup
                              title="Add Customer"
                              contentTxt={<hr />}
                              contentTxtBe={<hr />}
                              btnVar="text"
                              content={
                                <AddCustomerModal
                                  onClickBtnClose={() =>
                                    this.handleClose("AddCustomerModal")
                                  }
                                  setCurrentCustomer={this.handleOnSelect}
                                />
                              }
                              openPopup={this.state.addCustomerModal}
                              closePop={() => this.handleClose("AddCustomerModal")}
                              onClickBtnOpen={() =>
                                this.handleClickOpen("AddCustomerModal")
                              }
                              onClickBtnClose={() =>
                                this.handleClose("AddCustomerModal")
                              }
                            />
                          </Box>
                        </div>
                        <ReactSearchAutocomplete
                          items={this.props.g_active_customers}
                          fuseOptions={{
                            keys: ["name", "email"],
                          }}
                          resultStringKeyName="name"
                          onSearch={this.handleOnSearchCustomers}
                          onSelect={(item) => {
                            this.handleOnSelect(item, "customer");
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
                </>
              ) : (
                <>
                  <Paper style={{ marginTop: "20px" }} className={classes.paper}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={1}
                    >
                      <Typography variant="subtitle1">Customer</Typography>
                      <CancelIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => this.setState({ currentCustomer: "" })}
                      />
                    </Box>
                    <Box p={1}>
                      <Typography variant="body1">{this.state.currentCustomer?.name}</Typography>
                    </Box>
                    <Divider />
                    <Box p={1}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">CONTACT INFORMATION</Typography>
                        <Popup
                          title="Edit contact information"
                          contentTxt={<hr />}
                          contentTxtBe={<hr />}
                          content={
                            <EditContactInfoModal
                              updateContactInfo={this.updateContactInfo}
                              onClickBtnClose={() =>
                                this.handleClose("editInfoModal")
                              }
                              state={{
                                note: this.state.editInfoModal,
                                customer: this.state.currentCustomer,
                              }}
                            />
                          }
                          openPopup={this.state.editInfoModal}
                          onClickBtnClose={() => this.handleClose("editInfoModal")}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {this.state.currentCustomer?.email === undefined
                            ? ""
                            : this.state.currentCustomer?.email}
                        </Typography>
                        <Typography variant="body2">
                          {this.state.currentCustomer?.phoneNumber}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </>
              )}
            </Grid>
          </Grid>
          {this?.state?.printReceipt && this?.state?.orderObject && (
            <ThermalPrinterView
              orderObject={this?.state?.orderObject}
              customerObject={this.state?.currentCustomer}
              receiptMetadata={this.props?.company_info?.receipt_metadata}
              onFinish={this.onPrintFinish}
            />
          )}
        </div>
      )}
    </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_orders: state.ordersState.orders,
    g_unfulfilled_orders: state.ordersState.unfulfilled_orders,
    g_unpaid_orders: state.ordersState.unpaid_orders,
    g_draft_orders: state.ordersState.draft_orders,
    g_open_orders: state.ordersState.open_orders,
    g_close_orders: state.ordersState.close_orders,
    g_active_products: state.productsState.active_products,
    g_active_customers: state.customersState.active_customers,
    global_config: state.globalState,
    g_discount_state: state.discountState,
    company_info: state.companyInfoState,
    g_inventoryItems: state.inventoryState.inventoryItems,
    printer_Setting: state.printerSettingState,
    g_products: state.productsState.products,
  };
};
export default connect(mapStateToProps, null)(withStyles(style)(Orders));
