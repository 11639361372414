import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useState } from "react";

export default function CustomProduct(props) {
  const [qty, setQty] = useState(props.product.quantity);
  const [returnItem, setReturnItem] = useState(0);
  const [price, setPrice] = useState(props.product.price);
  const [stock, setStock] = useState(props.product.stock);
  const [initialStock] = useState(props.product.stock);

  // Media queries for responsiveness
  const isXs = useMediaQuery("(max-width:600px)"); // Extra small screens

  const priceUpdated = (e) => {
    if (props?.isPriceUpdateable) {
      setPrice(e.target.value);
      props?.updatePrice(parseInt(e.target.value), props?.product?.productName);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const newQuantity = inputValue === "" ? 0 : parseInt(inputValue) || 0;

    if (props.isRefund === false) {
      if (props.isEdit) {
        setQty(newQuantity);
        const id = props.product.id ?? props.product.productId;
        props.getQty(
          {
            id: id,
            quantity: newQuantity,
            price: props.product.price,
            tax: props.product.tax,
          },
          id
        );
      } else {
        setQty(newQuantity);
        props.getQty(newQuantity, props.product.productName);
      }
    } else if (props.isRefund) {
      if (newQuantity > props.product.quantity) {
        alert("Return quantity must be less than or equal to quantity purchased!");
      } else {
        setReturnItem(newQuantity);
        props.getQty(
          {
            id: props.product.id,
            quantity: newQuantity,
            returned: true,
            price: props.product.price,
            tax: props.product.tax,
          },
          props.product.id
        );
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: isXs ? "column" : "row",
          textAlign: "left",
          backgroundColor: "white",
          margin: isXs ? "5px" : "10px",
          padding: isXs ? "10px" : "0",
          position: "relative", // For better control over icon positioning
        }}
      >
        {/* Shopping Cart Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: isXs ? "100%" : "60px", // Full width on xs, fixed on larger screens
            marginBottom: isXs ? "10px" : "0",
          }}
        >
          <ShoppingCartIcon
            style={{
              fontSize: isXs ? "30px" : "40px",
              margin: isXs ? "0 auto" : "0 10px 0 0", // Centered on xs, aligned left otherwise
            }}
          />
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ marginBottom: "10px" }}>
            <p>
              {props.product.returned
                ? `${props.product.name ?? props.product.productName} - Returned Item`
                : props.product.name ?? props.product.productName}
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: isXs ? "10px" : "15px",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative", // To position the CloseIcon
            }}
          >
            <TextField
              size="small"
              type="number"
              value={price}
              min={0}
              disabled={!props?.isPriceUpdateable}
              label="Price Per Unit"
              variant="outlined"
              onChange={priceUpdated}
              sx={{ width: isXs ? "100%" : "120px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              size="small"
              type="number"
              value={props.product?.discountRate?.toFixed(2)}
              label="Discount"
              disabled={true}
              sx={{ width: isXs ? "100%" : "100px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              size="small"
              type="number"
              value={props.product?.tax?.toFixed(2)}
              label="Tax"
              disabled={true}
              sx={{ width: isXs ? "100%" : "100px" }}
              inputProps={{ min: 0 }}
            />
            <TextField
              size="small"
              type="number"
              min={0}
              max={parseInt(props.product.stock, 10)}
              label="Quantity"
              value={props.isRefund ? returnItem : qty}
              disabled={props.disableQty}
              variant="outlined"
              onChange={handleChange}
              sx={{ width: isXs ? "100%" : "120px" }}
              inputProps={{ min: 0, max: props.product.stock }}
              InputProps={{
                endAdornment: props.isRefund ? (
                  <InputAdornment position="end">
                    {"/" + props.product.quantity}
                  </InputAdornment>
                ) : null,
              }}
            />
            <TextField
              size="small"
              type="number"
              value={(
                (props.product.price * props.product.quantity * props.product.discountRate) /
                100
              ).toFixed(2)}
              label="Discounted Value"
              disabled={true}
              sx={{ width: isXs ? "100%" : "200px" }}
              inputProps={{ min: 0 }}
            />
            <Box
              component="span"
              sx={{ fontSize: isXs ? "14px" : "16px", whiteSpace: "nowrap" }}
            >
              Rs{" "}
              {(
                props.product.price * props.product.quantity -
                (props.product.price * props.product.quantity * props.product.discountRate) / 100
              ).toFixed(2)}
            </Box>
            <TextField
              size="small"
              type="number"
              label="Current Stock"
              value={stock}
              disabled={true}
              variant="outlined"
              sx={{ width: isXs ? "100%" : "120px" }}
              inputProps={{ min: 0 }}
            />
            {/* Close/Delete Icon */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                minWidth: "30px", // Ensure it has space
                marginLeft: isXs ? "auto" : "0", // Push to right on xs
              }}
            >
              {props.isDelete ? (
                <CloseIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: isXs ? "20px" : "24px", // Smaller on xs
                  }}
                  onClick={() => props.onDelete(props.product)}
                />
              ) : (
                <CloseIcon
                  color="disabled"
                  style={{ fontSize: isXs ? "20px" : "24px" }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider style={{ background: "gray" }} />
    </>
  );
}