import * as React from 'react';
import { DataGrid, useGridSlotComponentProps } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${
        theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
      }`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  };
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-cell': {
        color:
          theme.palette.type === 'light'
            ? 'rgba(0,0,0,.85)'
            : 'rgba(255,255,255,0.65)',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
    },
  }),
  { defaultTheme },
);

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={state.pagination.page}
      count={state.pagination.pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value)}
    />
  );
}

export default function Test() {
//   const { data } = useDemoData({
//     dataSet: 'Commodity',
//     rowLength: 10,
//     maxColumns: 10,
//   });

  const classes = useStyles();
  const rows= [
   { id: 1, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"},
   { id: 2, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"},
    { id: 3, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"}, 
    { id: 4, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"}, 
    { id: 5, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"}, 
    { id: 6, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"}, 
    { id: 7, col1: 'D-1532', col2: 'Soybeans',col3:"Ada McCormick", col4: "iholow@jadfi.io",col5:"82,314",col6:"10.73 %",col7:"Rejected",col8:"94.21"},
   // { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
   // { id: 3, col1: 'Material-UI', col2: 'is Amazing' },

 ];
 
 const columns = [
   { field: 'col1', headerName: 'Desk', width: 150 },
   { field: 'col2', headerName: 'Commodity', width: 150 },
   { field: 'col3', headerName: 'Trader Name', width: 150 },
   { field: 'col4', headerName: 'Trader Email', width: 150 },
   { field: 'col5', headerName: 'Quantity', width: 150 },
   { field: 'col6', headerName: 'Filled Quantity', width: 150 },
   { field: 'col7', headerName: 'Status', width: 150 },
   { field: 'col8', headerName: 'Unit Price', width: 150 },

 ];
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        className={classes.root}
        rows={rows}
        columns={columns}
        checkboxSelection
        pageSize={5}
        rowsPerPageOptions={[5]}
        components={{
          Pagination: CustomPagination,
        }}
      //   {...data}
      />
    </div>
  );
}
