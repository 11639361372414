import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Backdrop from "@material-ui/core/Backdrop";
import { DataGrid } from "@material-ui/data-grid";
import Loader from "../../MobileComponents/Loader";
import UserMobile from "../../MobileComponents/Users/UserMobile";
import CustomSnackbar from "../../MobileComponents/ProductMobileComponents/Snackbar";
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
//import CancelIcon from '@material-ui/icons/Cancel';
import GlobalConfig from "../../Config";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import EmailIcon from "@material-ui/icons/Email";
import LabelIcon from "@material-ui/icons/Label";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";
import Select from "@mui/material/Select";
import Store from "../../index";
import { connect } from "react-redux";
import { SKY_BLUE } from "../../colors/colors";
import helpers from "../../UtilitiesFunctions";
import Test from "./Test";

const style = (theme) => ({
  [theme.breakpoints.down("sm")]: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 0px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(66, 63, 62)",
        border: "1px solid black",
        borderRadius: "13px",
        outline: "1px solid slategrey",
      },
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
  },
  papertm: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    textAlign: "",
    color: theme.palette.text.primary,
    backgroundColor: "lightgray",
  },
  box: {
    display: "inline-flex",
  },
  boxRow: {
    display: "inline-flex",
    flexDirection: "row",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: true,
      loaderMessage: "Loading users..",
      query: "",
      backdrop: false,
      previousCustomers: [],
      snackbar: false,
      open: false,
      deleteOpen: false,
      roles: [],
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      selectedRole: "",
      selectedUserId: "",
      userRole: "",
      token: "",
      modalTitle: "",
      editOpen: false,
      customSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    // Simple GET request using fetch
    console.log("token: ", this.props);
    console.log("token: ", this.props.token);
    this.setState({ token: this.props.token });
    this.getUser();
    // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users/roles", {
    fetch(this.props.global_config.basePath + "/users/roles", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
      body: JSON.stringify(),
    })
      .then((response) => {
        console.log(response);
        response.status == 200 && response.json();
      })
      .then((data) =>
        this.setState({
          roles: data,
          loading: false,
        })
      )
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  }
  getRoles = () => {
    fetch(this.props.global_config.basePath + "/users/roles", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
      body: JSON.stringify(),
    })
      .then((response) => {
        console.log(response);
        response.status == 200 && response.json();
      })
      .then((data) =>
        this.setState({
          roles: data,
          loading: false,
        })
      )
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  };
  getUser = () => {
    fetch(this.props.global_config.basePath + "/users", {
      // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          users: data,
          previousCustomers: data,
          loading: this.props.g_users.length !== 0 ? false : false,
        });
        Store.dispatch({ type: "GET_USERS", users: data });
        Store.dispatch({ type: "GET_TOKEN", token: this.props.token });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  dynamicSearch = (list, searchTerm) => {
    // console.log("search term is-> ", searchTerm);
    // console.log("list of global users", list);
    let value = searchTerm.toLowerCase();
    //  console.log("value is ", value);
    let filteredList = list.filter(
      (item) =>
        item.userName.includes(searchTerm) ||
        item.firstName.includes(searchTerm) ||
        item.lastName.includes(searchTerm) ||
        (item.email ? item.email.includes(searchTerm) : null)
    );
    // console.log("Filtered List: " + JSON.stringify(filteredList));
    return filteredList;
  };
  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  searchUsers = (e) => {
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        users: this.props.g_users,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_users,
        e.target.value
      );
      this.setState({
        backdrop: false,
        users: filteredList,
      });
    }
  };

  dash = (value) => {
    if (value === undefined) value = "-";
    return value;
  };
  handleSearch = (key, value) => {
    if (this.state.query.length <= 0) {
      this.setState({ snackbar: false });
      helpers.toastNotify("error", "Empty Field Detected!");
    } else {
      this.setState({ backdrop: true });
      fetch(
        this.props.global_config.basePath +
          `/customers/search?key=${key}&value=${value}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer token",
          },
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData, "after search query");
          this.setState({
            backdrop: false,
            customers: responseData,
          });
        });
    }
  };

  handleDeleteOpen = (id) => {
    // console.log("POID", id);
    this.setState({ deleteOpen: true });
  };

  handleEditOpen = (data) => {
    const filteredUser = this.filterUser(data?.row?.POID);

    // console.log("filteredUser.role[0].name",filteredUser.role[0].name);
    // this.setState({
    //   selectedRole: filteredUser?.role?.length > 0 ? filteredUser.role[0].name : "agent",
    // });
    this.setEditUserFields(filteredUser);
    this.setState({ modalTitle: "Edit User", editOpen: true });
  };
  handleClickOpen = () => {
    this.setState({ open: true, modalTitle: "Add New User" });
  };
  handleEditClose = () => {
    this.setState({ editOpen: false });
  };
  handleDeleteClose = () => {
    this.setState({ deleteOpen: false });
  };
  handleClose = () => {
    console.log("user name: ", this.state.userName);
    console.log("password is: ", this.state.password);
    console.log("email: ", this.state.email);
    console.log("role: ", this.state.selectedRole);
    this.clearFieldValues();
    this.setState({ open: false, editOpen: false });
  };

  clearFieldValues = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      selectedRole: "",
      // selectedRole: user.role!=undefined ? (user.role.lenght>0 ? user.role[0] : 'none' ) : 'none',
      userName: "",
    });
  };
  closeSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };
  cancelSearch = () => {
    this.setState({ query: "", users: this.props.g_users });
  };
  filterUser = (userId) => {
    var filteredUser = [];
    if (this.props.g_users.length > 0) {
      filteredUser = this.props.g_users.filter((user) => {
        return user.POID === userId;
      });
    }
    return filteredUser[0];
  };
  setEditUserFields = (user) => {
    this.setState({
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      email: user.email,
      selectedRole: user.role.length ? user.role[0].name : "agent",
      // selectedRole: user.role!=undefined ? (user.role.lenght>0 ? user.role[0] : 'none' ) : 'none',
      userName: user.userName,
    });
  };
  submitUser = (e) => {
    e.preventDefault();
    this.setState({ loading: true, loaderMessage: "creating user.." });
    let createUser = {
      role: [
        {
          // POID:"c785b8b2-5a77-48f8-bc94-14d756468e9f",
          name: this.state.selectedRole.split(",")[0],
        },
      ],
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userName: this.state.userName,
      email: this.state.email,
      password: this.state.password,
    };

    let stringifyString;
    if (createUser != null) {
      stringifyString = JSON.stringify(createUser);
    }
    console.log("created object of user: ", stringifyString);
    fetch(this.props.global_config.basePath + "/users", {
      // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.token,
      },
      body: stringifyString,
    })
      .then((response) => {
        if (response.status == 409) {
          this.setState({
            loading: false,
          });
          helpers.toastNotify("error", "User already exists!");
        } else if (response.status == 200 || response.status == 204) {
          // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users", {

          this.getRoles();
          this.getUser();
          // this.renderSnackbar("user created successfully", "success");
          helpers.toastNotify("success", "user created successfully");
          return { loading: false };
        }
      })
      .catch(() => {
        //error handling here
        helpers.toastNotify("error", "something went wrong!");
        // this.renderSnackbar("Error! Something went wrong!", "error");
      });
    this.handleClose();
  };

  editUser = (e) => {
    console.log("Editing user ....");
    e.preventDefault();
    this.setState({ loading: true, loaderMessage: "updating user.." });
    let editUser = {
      role: [
        {
          // POID: this.state.selectedRole.split(",")[1],
          name: this.state.selectedRole.split(",")[0],
        },
      ],
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      // password: this.state.password,
    };

    let stringifyString;
    if (editUser != null) {
      stringifyString = JSON.stringify(editUser);
    }
    console.log("created object of user: ", stringifyString);
    fetch(
      this.props.global_config.basePath + "/users/" + this.state.selectedUserId,
      {
        // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users/"+this.state.selectedUserId, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.token,
        },
        body: stringifyString,
      }
    )
      .then((response) => {
        fetch(this.props.global_config.basePath + "/users", {
          // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users/", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.props.token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            Store.dispatch({ type: "GET_USERS", users: data });
            this.setState({ users: data });
          })
          .catch((e) => {
            helpers.toastNotify("error", "Something Went Wrong!");
          });
        this.setState(function (currentState) {
          return { loading: false };
        });
        // this.renderSnackbar("User Updated Successfully!");
        helpers.toastNotify("success", "User Updated Successfully!");
        // helpers.toastNotify( "error","Error! Something went wrong")
      })
      .catch(() => {
        // this.renderSnackbar("Error in user updation. Something went wrong!");
        // helpers.toastNotify( "success","User Updated Successfully!")
        helpers.toastNotify("error", "Could Not Update User.");
      });
    this.handleClose();
  };

  deleteUser = () => {
    this.setState({ loading: true, loaderMessage: "deleting user.." });
    fetch(
      this.props.global_config.basePath + "/users/" + this.state.selectedUserId,
      {
        // fetch("http://192.168.100.88:8080/POS/Sputnik/e2a2e917-168e-4d1e-8ff5-81b2628b68e4/users/" + this.state.selectedUserId, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.token,
        },

        body: JSON.stringify(),
      }
    )
      .then((response) => {
        Store.dispatch({
          type: "DELETE_USERS",
          POID: this.state.selectedUserId,
        });
        this.setState(function (currentState) {
          return {
            loading: false,
            deleteOpen: false,
            users: this.props.g_users,
          };
        });
      })
      .catch(() => {
        // this.renderSnackbar("Error in user deletion. Something went wrong!", "error");
        // helpers.toastNotify( "success","User Updated Successfully!")
        helpers.toastNotify("error", "Could Not Delete User.");
      });
  };

  renderSnackbar = (message, type) => {
    this.setState({
      customSnackbar: true,
      snackbarMessage: message,
      snackbarType: type,
    });
  };
  setSnackbarToFalse = () => {
    setTimeout(() => {
      this.setState({ customSnackbar: false });
    }, 3000);
  };
  render() {
    const { classes } = this.props;
    // this.setSnackbarToFalse();
    const {
      roles,
      loading,
      loaderMessage,
      query,
      backdrop,
      customSnackbar,
      snackbarMessage,
      snackbarType,
    } = this.state;
    let myRoles = [];
    // let myRoles = [{name: "Admin"}, {name: "agent"}]
    roles
      ? roles?.map((role) => {
          myRoles.push(role.name.concat(",").concat(role.POID));
        })
      : myRoles.push("Admin".concat(",").concat("00"));
    console.log(roles ? "Roles" : "Not Roles");
    console.log(myRoles);
    myRoles = [
      "Admin".concat(",").concat("00"),
      "manager".concat(",").concat("01"),
      "agent".concat(",").concat("02"),
    ];

    const columns = [
      {
        field: "ID",
        header: "ID",
        width: 110,

        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Id</h5>
          </span>
        )



      },
      {
        field: "name",
        headerName: "User Name",
        width: 200,
        editable: true,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>User Name</h5>
          </span>
        )
      },
      {
        field: "firstName",
        headerName: "First Name",
        width: 150,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>First Name</h5>
          </span>
        )
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 150,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Last Name</h5>
          </span>
        )
        
      },
      {
        field: "email",
        headerName: "Email",
        width: 250,
        editable: true,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Email</h5>
          </span>
        )
        
      },
      {
        field: "status",
        headerName: "Status",
        width: 250,
        editable: true,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>status</h5>
          </span>
        )
        
      },
      {
        field: "roles",
        headerName: "Roles",
        width: 200,
        editable: true,
        renderHeader: (params) => (
          <span style={{marginTop:"10px"}}>
          <h5 className="poppins-medium" style={{fontSize:"14px"}}>Roles</h5>
          </span>
        )
        
      },

      {
        field: " ",
        width: 150,
        renderCell: (params) => (
          <div>
            <Button
              // variant="contained"
              color="secondary"
              size="small"
              onClick={this.handleDeleteOpen}
            >
              <DeleteIcon />
            </Button>
            <Button
              // variant="contained"
              style={{ color: SKY_BLUE }}
              size="small"
              onClick={() => {
                this.handleEditOpen(params);
              }}
            >
              <ModeEditOutlineIcon />
            </Button>
          </div>
        ),
      },
    ];
    const rows = [];
    const obj = this.state.users;
    if (obj?.length > 0) {
      let x = 1;
      for (var i = 0; i < obj.length; i++) {
        if (obj[i].status == true) {
          rows[i] = {
            id: i,
            ID: x,
            POID: obj[i].POID,
            name: obj[i].userName,
            status: obj[i].status,
            roles:
              obj[i].role != undefined
                ? obj[i].role.length > 0
                  ? obj[i].role[obj[i].role?.length - 1].name
                  : obj[i].role[0].name
                : "none",
            email: this.dash(obj[i].email),
            firstName: obj[i].firstName,
            lastName: obj[i].lastName,
          };
          x++;
        }
      }
    }

    // console.log("Users", users);
    function CustomToolbar() {
      return (
        <div style={{ width: "400px" }}>
          <GridToolbarContainer
            style={{
              backgroundColor: "#EFEFEF",
              border: "1px solid black",
              margin: "20px",
              width: "100px",
              height: "50px",
            }}
          >
            {/* <GridToolbarExport /> */}
          </GridToolbarContainer>
        </div>
      );
    }
    return (
      <>
        {loading ? (
          <Loader value={loaderMessage} />
        ) : (
          <>
            <Backdrop
              style={{
                zIndex: 1,
                color: "#fff",
              }}
              open={backdrop}
            >
              <CircularProgress thickness={2} size={90} color="inherit" />
            </Backdrop>
            {this.state.snackbar ? (
              <>
                <Snackbar
                  open={this.state.snackbar}
                  autoHideDuration={2000}
                  onClose={this.closeSnack}
                >
                  <MuiAlert
                    severity="error"
                    variant="filled"
                    color="error"
                    onClose={this.closeSnack}
                  >
                    Please enter valid name!
                  </MuiAlert>
                </Snackbar>
              </>
            ) : null}
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "space-between",
                // marginLeft: "20px",
              }}
            >
              <div>
                <h5 className="poppins-medium">Users</h5>
              </div>
              <div>
                {/* <Button
                  color="primary"
                  variant="contained"
                  // component={Link}
                  // to="/customer"
                  style={{ backgroundColor: SKY_BLUE, color: "white" }}
                  onClick={this.handleClickOpen}
                >
                  <PersonAddIcon />
                </Button> */}


                <div style={{marginRight:"10px"}}>
                    <div>
                      {/* <Link to="/customer"> */}
                        <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={this.handleClickOpen}
                        style={{
                        backgroundColor: SKY_BLUE,
                        color: "white",
                        borderRadius: "50%",
                        width: "35px",  // Ensure width and height are equal
                        height: "35px",
                        minWidth: "unset", // Override Material-UI's default minWidth
                        }}
                          >
                         <AddIcon />
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>

              </div>
            </div>
            {/* add new user modal */}
            <Dialog
              fullWidth={true}
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              disableEnforceFocus
              onClose={this.handleClose}
            >
              <DialogTitle>{this.state.modalTitle}</DialogTitle>
              <form onSubmit={this.submitUser}>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        style={{ marginBottom: "20px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        label="First Name"
                        // placeholder="Enter First Name"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      ></TextField>

                      <TextField
                        style={{ marginBottom: "20px" }}
                        type="text"
                        size="small"
                        variant="outlined"
                        label="Last Name"
                        // placeholder="Enter Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      ></TextField>

                      <TextField
                        style={{ marginBottom: "20px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        label="User Name"
                        // placeholder="Enter User Name"
                        name="userName"
                        value={this.state.userName}
                        onChange={this.handleChange}
                        required
                      ></TextField>

                      <TextField
                        type="email"
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        label="Email"
                        size="small"
                        // placeholder="someone@gmail.com"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        required
                      ></TextField>

                      <TextField
                        type="password"
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        label="Password"
                        size="small"
                        // placeholder="Password"
                        //value={this.state.password}
                        onChange={this.handleChange}
                        required
                        name="password"
                        //  value={this.state.password}
                      ></TextField>

                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.formControl}
                      >
                        <InputLabel>Role</InputLabel>
                        <Select
                          onChange={this.handleChange}
                          name="selectedRole"
                          defaultValue=""
                          // label="Role"
                        >
                          {myRoles && myRoles.length
                            ? myRoles?.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {role.split(",")[0].toUpperCase()}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="secondary">
                    <CancelIcon /> Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    <PersonAddIcon /> Save
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            {/* edit use modal */}
            <Dialog
              fullWidth={true}
              open={this.state.editOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
            >
              <DialogTitle>{this.state.modalTitle}</DialogTitle>
              <form onSubmit={this.editUser}>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        style={{ marginBottom: "20px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        label="First Name"
                        // placeholder="Enter First Name"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      ></TextField>

                      <TextField
                        style={{ marginBottom: "20px" }}
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Last Name"
                        // placeholder="Enter Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      ></TextField>

                      <TextField
                        type="email"
                        size="small"
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                        label="Email"
                        // placeholder="someone@gmail.com"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        required
                      ></TextField>
                      {!this.state.editOpen && (
                        <TextField
                          type="password"
                          size="small"
                          style={{ marginBottom: "20px" }}
                          variant="outlined"
                          label="Password"
                          // placeholder="password"
                          //value={this.state.password}
                          onChange={this.handleChange}
                          required
                          name="password"
                          //  value={this.state.password}
                        ></TextField>
                      )}

                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Role</InputLabel>
                        <Select
                          onChange={this.handleChange}
                          value={
                            this.state.selectedRole != undefined
                              ? this.state.selectedRole
                              : "agent"
                          }
                          name="selectedRole"
                          label="Role"
                          required
                        >
                          {myRoles?.length > 0
                            ? myRoles?.map((role) => (
                                <MenuItem key={role} value={role.split(",")[0]}>
                                  {role.split(",")[0].toUpperCase()}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="secondary">
                    <CancelIcon /> Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    <PersonAddIcon /> Save
                  </Button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              fullWidth={true}
              open={this.state.deleteOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
            >
              <DialogTitle style={{ textAlign: "center" }}>
                {"Are you sure you want to delete this customer ? "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      onClick={this.deleteUser}
                      style={{
                        backgroundColor: "rgb(39,136,6)",
                        color: "white",
                      }}
                    >
                      <CheckBoxIcon /> YES
                    </Button>
                    <Button
                      onClick={this.handleDeleteClose}
                      color="secondary"
                      variant="contained"
                    >
                      <CancelIcon /> No
                    </Button>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDeleteClose} color="secondary">
                  <CancelIcon /> Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "none",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                  <TextField
                    fullWidth={true}
                    value={query}
                    name="query"
                    onChange={this.searchUsers}
                    id="outlined-basic"
                    // placeholder="search user by name"
                    label="search user by name or email"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ marginRight: "10px" }}>
                          {/* <IconButton> */}
                          <SearchTwoToneIcon />

                          {/* </IconButton> */}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment>
                          {this.state.query === "" ? null : (
                            <div
                              style={{ marginRight: "10px" }}
                              variant="outlined"
                              color="primary"
                              onClick={() => this.cancelSearch()}
                            >
                              <CancelIcon style={{ color: "red" }} />
                            </div>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // marginTop: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                ></div>
              </div>


{/* <div>

<Test/>

</div> */}









              <div style={{ height: 700, width: '100%' }}>
                    <div style={{ height: "100%", width: '100%' }}>

                <DataGrid
                  loading={loading}
                  rowHeight={85}
                  // headerHeight={75}
                  // hideFooter
                  rows={rows}
                  columns={columns}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  // sortModel={[
                  //   {
                  //     field: "name",
                  //     sort: "asc",
                  //   },
                  // ]}
                  checkboxSelection={false}
                  disableSelectionOnClick
                  onRowClick={(data) => {
                    console.log(data);

                    this.setState({ selectedUserId: data.row.POID });
                  }}
                />
                </div>
                </div>
              
            </div>
          </>
        )}
        {customSnackbar ? (
          <CustomSnackbar message={snackbarMessage} type={snackbarType} />
        ) : null}
      </>
    );
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    g_users: state.usersState.users,
    g_token: state.usersState.token,
    global_config: state.globalState,
  };
};
// export default withStyles(style)(Users)
export default connect(mapStateToProps)(withStyles(style)(Users));
