import React, { Component } from "react";
import GlobalConfig from "../../Config";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid } from "@mui/x-data-grid";
import Box from "@material-ui/core/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import Store from "../../index";
import { SKY_BLUE, MARS, WHITE } from "../../colors/colors";
import { Typography } from "@mui/material";
import { grey } from "@material-ui/core/colors";
import { QrCode } from "@mui/icons-material";
import helpers from "../../UtilitiesFunctions";
import { getFilteredProducts } from "../../utilities/ProductsFilter";
const useStyles = (theme) => ({
  dataGrid: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    //color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    width: "640px",
  },
});

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      previousProducts: [],
      selectedRows: [],
      loader: true,
      variant: 0,
      btnToggle: true,
      addBtn: false,
      // addBtn: true,
      query: "",
      modelRowsIds: [],
      newSelectedRows: [],
      rows: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  populateRows = (products) => {
    const rows = [];
    console.log("products", products);
    for (var i = 0; i < products.length; i++) {
      console.log("obj[i])", products[i]);
      if (products[i]?.varients?.length > 0) {
        console.log("PUSHING VARIENT");
        const filteredList = products[i]?.varients?.filter((item) => {
          if (item?.status === "Active") return item;
        });
        filteredList?.map((varient, index) => {
          var specificationName = " (";
          varient?.productSpecCharacteristics?.map((productspec, index) => {
            //specificationName += " -";
            productspec?.productSpecCharValues?.map(
              (productSpecCharValues, index) => {
                console.log(
                  "here is a product specificationvalues",
                  productSpecCharValues
                );
                specificationName +=
                  productSpecCharValues?.value +
                  (index + 1 == productspec?.productSpecCharValues?.length &&
                  productspec?.productSpecCharValues?.length > 1
                    ? ", "
                    : "");
                console.log("here is varient product", specificationName);
              }
            );
            specificationName +=
              index + 1 != varient?.productSpecCharacteristics?.length &&
              varient?.productSpecCharacteristics?.length > 1
                ? " - "
                : "";
          });
          specificationName += ")";
          rows.push({
            id: varient.POID,
            productId: varient.POID,
            productName: varient.name + specificationName,
            price: varient.price,
            quantity: 1,
            fulfilled: false,
            tax: varient.taxRate,
            unitOfMeasures: varient.unitsOfMeasures,
            action: "add",
            costPrice: varient.costPrice,
          });
        });
      } else {
        rows.push({
          id: products[i].POID,
          productId: products[i].POID,
          productName: products[i].name,
          price: products[i].price,
          quantity: 1,
          fulfilled: false,
          tax: products[i].taxRate,
          unitOfMeasures: products[i].unitsOfMeasures,
          action: "add",
          costPrice: products[i].costPrice,
        });
      }
    }

    return rows;
  };

  componentDidMount() {
    if (this.props.g_active_products?.length === 0) {
      let url = this.props.global_config.basePath + "/products?status=Active";
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      })
        .then(async (result) => {
          let data = await result?.json();

          if (data.length) {
            this.setState({
              products: data?.filter((product) => product.quantity > 0),
              rows: this.populateRows(
                data?.filter((product) => product.quantity > 0)
              ),
              previousProducts: data?.filter((product) => product.quantity > 0),
              loader: false,
            });

            Store.dispatch({
              type: "GET_ACTIVE_PRODUCTS",
              active_products: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            products: [],
            previousProducts: [],
            loader: false,
          });
        });
    } else {
      this.setState({
        products: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        rows: this.populateRows(
          this.props.g_active_products?.filter(
            (product) => product.quantity > 0
          )
        ),
        previousProducts: this.props.g_active_products?.filter(
          (product) => product.quantity > 0
        ),
        loader: false,
      });
    }



// this.getAllProducts();

  }











  getAllProducts = (start = 0, size = 200) => {
    this.setState({ syncingLoader: true });
    console.log("curretn product state123:");
    let url =
      this.props.global_config.basePath +
      `/products?status=All&start=${start}&size=${size}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          // Fetch the current state of products from the Redux store

          // setTimeout(() => {
          const currentProducts = Store.getState().productsState.products;
          console.log("curretn product state01:", currentProducts);
          // }, 1000);
          // Append the newly fetched data to the current products
          // const updatedProducts = [...currentProducts, ...data];

          // Dispatch the updated product list
          // Store.dispatch({ type: "GET_PRODUCTS", products: updatedProducts });
          const activeProducts = getFilteredProducts(data, "active");

          this.setState({ products: activeProducts });

          Store.dispatch({
            type: "GET_INVENTORY_ITEMS",
            inventoryItems: this.prepareInventoryData(activeProducts),
          });
          Store.dispatch({
            type: "GET_ACTIVE_PRODUCTS",
            active_products: activeProducts,
          });
          Store.dispatch({
            type: "GET_ARCHIVED_PRODUCTS",
            archived_products: getFilteredProducts(data, "archived"),
          });
          Store.dispatch({
            type: "GET_DRAFT_PRODUCTS",
            draft_products: getFilteredProducts(data, "draft"),
          });

          // Recursively call getAllProducts with the next start index

          this.getAllProducts(start + size, size);
        } else {
          // If no more products, stop fetching and set loading to false
          this.setState({
            previousProducts: this.state.products, // Assuming you want to store all products
            loading: false,
            syncingLoader: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          mode: "offline",
        });
      });
  };








  scanBarCodeAndAddToCart = (barCode) => {
    const foundProducts = this.state?.products?.filter(
      (product) =>
        product?.barcodes?.filter(
          (barCodeValue) => barCodeValue?.code === barCode
        )?.length > 0
    );

    const foundProductIds = foundProducts.map((product) => product.id);

    console.log(this.state?.rows, { foundProducts: foundProducts });
    if (foundProducts.length === 1) {
      this.setState({
        modelRowsIds: [
          ...new Set([...foundProductIds, ...this.state.modelRowsIds]),
        ],
      });
    } else if (foundProducts.length > 1) {
      helpers.toastNotify(
        "error",
        "More than one products with same barcode found. Consider selecting manually."
      );
    } else if (foundProducts.length === 0) {
      helpers.toastNotify("error", "No products found with given barcode.");
    }
  };

  handleSubmit = async (event) => {
    console.log(this.state.modelRowsIds, "this.state.modelRowsIds");
    // event.preventDefault();
    let newSelectedProducts =
      this.state.selectedRows != undefined ? this.state.selectedRows : [];
    let selectedRowIdsArr =
      this.state.modelRowsIds != undefined ? this.state.modelRowsIds : [];

    if (selectedRowIdsArr.length) {
      selectedRowIdsArr.forEach((rowId) => {
        const rowIdData = this.state.rows?.filter((row) => row.id === rowId)[0];

        console.log("rowIdData", rowIdData);
        newSelectedProducts = newSelectedProducts.concat(rowIdData);
      });
    }

    this.props.updateParent(
      // this.state.selectedRows,
      newSelectedProducts,
      "dbItem",
      this.state.products
    );

    this.props.handleClose("A");
  };

  //CODE BY SAM
  newHandleSubmit = () => {
    let oldSelectedProducts = this.state.selectedRows; //products which are added after pressing add
    let newSelectedProducts = this.state.newSelectedRows;

    oldSelectedProducts = oldSelectedProducts.concat(newSelectedProducts);

    console.log(oldSelectedProducts);

    this.props.updateParent(oldSelectedProducts, "dbItem", this.state.products);

    this.props.handleClose("A");
  };

  onSelectRow = (rowData) => {
    console.log("rowData", rowData);
    if (this.state.newSelectedRows.length) {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      } else {
        this.state.newSelectedRows = this.state.newSelectedRows.filter(
          (existingData) => existingData.id != rowData.data.id
        );
      }
    } else {
      if (rowData.isSelected) {
        this.state.newSelectedRows = this.state.newSelectedRows.concat(
          rowData.data
        );
      }
    }

    console.log(this.state.newSelectedRows);
  };

  onSelectProduct = (selectedProductRowsId) => {
    console.log({ selectedProductRowsId: selectedProductRowsId });
    this.setState({
      modelRowsIds: selectedProductRowsId,
    });
  };

  onDelete = (e) => {
    const id = e.productId;
    this.setState({
      selectedRows: this.state.selectedRows.filter(
        (item) => item.productId !== id
      ),
    });
  };
  handleOnSearch = (e) => {
    console.log("in handle change");
    console.log(e.target.value);
    if (e.target.value === "") {
      this.cancelSearch();
      this.setState({
        backdrop: false,
        products: this.state.previousProducts,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      const filteredList = this.dynamicSearch(
        this.props.g_active_products,
        e.target.value
      );

      console.log("here is filtered list", filteredList);

      this.setState({
        backdrop: false,
        products: filteredList,
        rows: this.populateRows(filteredList),
      });
    }
  };
  dynamicSearch = (list, searchTerm) => {
    return list.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  cancelSearch = () => {
    this.setState({ query: "", products: this.state.previousProducts });
  };
  handleSearchByName = (key, value) => {
    const filteredList = this.dynamicSearch(this.props.g_inventoryItems, value);
    if (filteredList.length !== 0) {
      this.setState({
        products: filteredList,
      });
    }
  };
  searchProductBackend = (value) => {
    this.setState({ products: [] });
    fetch(
      this.props.global_config.basePath +
        `/products/search?key=name&value=${value}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer token",
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData, "after search query");
        this.setState({
          products: responseData,
        });
      });
  };
  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 150 },
      { field: "productName", headerName: "Product Name", width: 150 },
      //{ field: "qty", headerName: "", width: 130 },
      {
        field: "price",
        headerName: "Price",
        type: "number",
        width: 150,
      },
    ];
    return (
      <div>
      {/* Search Products Field - responsive */}
      <Box sx={{ m: { xs: 1, sm: 2 } }}>
        <TextField
          fullWidth
          name="query"
          onChange={(e) => this.handleOnSearch(e)}
          id="outlined-basic"
          placeholder="Search Products"
          size="small"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <SearchTwoToneIcon
                  onClick={() => this.searchProductBackend(this.state.query)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': { color: SKY_BLUE }
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    
      {/* Barcode Scanner Input - responsive */}
      <Box
        sx={{
          mx: { xs: 1, sm: 2 },
          height: { xs: '36px', sm: '38px' },
          border: "1px solid #dfe1e5",
          borderRadius: "5px",
          backgroundColor: "white",
          color: "#212121",
          fontSize: { xs: '14px', sm: '16px' },
          fontFamily: "Arial",
          display: "flex",
          mb: { xs: 1, sm: 2 },
          '&:focus-within': {
            borderColor: SKY_BLUE,
            boxShadow: '0 0 0 2px rgba(0,123,255,0.25)'
          }
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 1
          }}
        >
          <QrCode fontSize="small" sx={{ color: grey[600] }} />
        </Box>
        <Box
          sx={{
            flex: 1,
            height: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <input
            placeholder="Scan/Enter Barcode"
            style={{
              height: "100%",
              outline: "none",
              border: "none",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.scanBarCodeAndAddToCart(e.target.value);
                e.target.value = "";
              }
            }}
          />
        </Box>
      </Box>
    
      {/* Products DataGrid - responsive */}
      <Box 
        sx={{ 
          width: "100%",
          px: { xs: 1, sm: 2 }
        }}
      >
        {this.state.rows?.length > 0 && (
          <DataGrid
            getRowHeight={() => "auto"}
            sx={{
              [`& .MuiDataGrid-cell`]: {
                whiteSpace: "normal",
                py: 1,
                px: { xs: 1, sm: 2 },
                fontSize: { xs: '12px', sm: '14px' }
              },
              [`& .MuiDataGrid-columnHeaders`]: {
                fontSize: { xs: '12px', sm: '14px' }
              },
              [`& .MuiDataGrid-footerContainer`]: {
                fontSize: { xs: '12px', sm: '14px' }
              },
              height: { xs: 'calc(100vh - 200px)', sm: 'auto' },
              mb: { xs: 7, sm: 8 }
            }}
            onSelectionModelChange={(selectedRowArrIndex) => {
              console.log("selectedRowArrIndex", selectedRowArrIndex);
              this.onSelectProduct(selectedRowArrIndex);
            }}
            checkboxSelection
            autoHeight
            loading={this.state.loader}
            scrollbarSize={1}
            rows={this.state.rows}
            columns={columns}
            selectionModel={this.state.modelRowsIds}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
          />
        )}
    
        {this.state.rows?.length === 0 && (
          <Box
            sx={{
              width: "100%",
              py: 3,
              textAlign: "center",
              mt: 2,
              mb: { xs: 7, sm: 8 }
            }}
          >
            <Typography variant="h6">No Matching Products</Typography>
          </Box>
        )}
      </Box>
    
      {/* Action Buttons - fixed at bottom */}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end" },
          flexDirection: { xs: "column", sm: "row" },
          p: 1,
          background: "white",
          position: "fixed",
          width: "100%",
          bottom: 0,
          left: 0,
          borderTop: "1px solid #e0e0e0",
          zIndex: 10,
          boxShadow: "0px -2px 4px rgba(0,0,0,0.05)"
        }}
      >
        <Box p={1}>
          <Link to="/ProductForm" style={{ textDecoration: 'none' }}>
            <Button
              fullWidth
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="outlined"
              sx={{ 
                minWidth: { xs: '100%', sm: '180px' },
                whiteSpace: 'nowrap'
              }}
            >
              Create a new product
            </Button>
          </Link>
        </Box>
        <Box p={1}>
          <Button
            fullWidth
            sx={{ 
              backgroundColor: SKY_BLUE,
              minWidth: { xs: '100%', sm: '180px' },
              whiteSpace: 'nowrap',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0069d9'
              },
              '&.Mui-disabled': {
                backgroundColor: '#cccccc'
              }
            }}
            variant="contained"
            onClick={this.handleSubmit}
            disabled={this.state.addBtn}
          >
            Add to cart
          </Button>
        </Box>
      </Box>
    </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_active_products: state.productsState.active_products,
    global_config: state.globalState,
  };
};
export default connect(mapStateToProps, null)(ProductsTable);
