import React, { Component } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

const style = (theme) => ({
  textDanger: {
    color: "red",
  },
});
class CustomerContactInfoEditWithName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      email: this.props.email,
      phone_number: this.props.phoneNumber,
      name: this.props.name,
    };
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    //Validation Logic
    if (this.validator.allValid()) {
      this.props.updateContactInfo(this.state);

      setTimeout(() => {
        this.props.history.push({
          pathname: "/Customers",
        });
      }, 1000);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  render() {
    const { classes, history } = this.props;
    return (
      <div>
        <Box display="flex" flexDirection="row" bgcolor="white">
          <Box>
            <Box>Name</Box>
            <OutlinedInput
              style={{ width: "70%", height: "50%", marginTop: "10px" }}
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
            {this.validator.message("name", this.state.name, "required|name", {
              className: classes.textDanger,
            })}
          </Box>
          <Box>
            <Box>Email</Box>
            <OutlinedInput
              style={{ width: "150%", height: "50%", marginTop: "10px" }}
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />

            {this.validator.message(
              "email",
              this.state.email,
              "required|email",
              { className: classes.textDanger }
            )}
          </Box>
        </Box>
        <Box>
          <Box>
            <Box>Phone Number</Box>
            <OutlinedInput
              style={{ width: "99%", height: 40, marginTop: "10px" }}
              name="phone_number"
              value={this.state.phone_number}
              onChange={this.handleChange}
            />

            {this.validator.message(
              "phone number",
              this.state.phone_number,
              "required|phone number",
              { className: classes.textDanger }
            )}
          </Box>
          {<hr />}
          <Button
            style={{
              textTransform: "none",
              float: "right",
            }}
            onClick={this.handleSubmit}
            variant="contained"
            color="dark"
          >
            Save
          </Button>
          <Button
            style={{
              textTransform: "none",
              marginRight: "10px",
              float: "right",
            }}
            onClick={this.props.onClickBtnClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    g_active: state.customersState.active_customers,
    g_archived_customers: state.customersState.archived_customers,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(CustomerContactInfoEditWithName));
