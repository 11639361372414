import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import helpers from "../../UtilitiesFunctions";
import GlobalConfig from "../../Config";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    customButton: {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: 48,
        padding: "0 30px",
    },
    CancelOrderBur: {
        background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
    },
    rootFirstSelect: {
        padding: "4px 0px !important",
        width: "100%",
    },
    rootSecondSelect: {
        padding: "10px 80px",
    },
});

export default function CancelOrderModal(props) {
    let [open, setOpen] = useState(false);
    let [reason, setReason] = useState("Customer changed/cancelled order");
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setVisibility(false);
    };

    useEffect(() => {
        console.log(reason);
    }, [reason]);

    const global_config = useSelector((state) => state.globalState);

    function cancelOrderFn() {
        helpers.toastNotify("success", "Order Successfully Cancelled !");
        props.setVisibility(false);

        let url = global_config.basePath + `/orders/cancel/${props.orderData.orderId}`;

        fetch(url, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer token",
            },
            body: JSON.stringify({ status: "cancelled" }),
        })
            .then((response) => response.json())
            .then((data) => {})
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
           
            <Dialog
                open={props.visibility}
                onClose={handleClose}
                // sx={{bordeRadius: '20px'}}
            >
                <DialogTitle
                    // sx={{background: '#25a18e', color: 'white', padding: '10px 10px !important', marginBottom: '10px'}}
                    sx={{
                        borderBottom: "1px solid #25a18e",
                        marginBottom: "10px",
                        padding: "10px 10px",
                        color: "white",
                        background: "#25a18e",
                    }}>
                    Cancel Order
                    {/* <hr /> */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ minWidth: "500px" }}>
                        <p>
                            <b>Void transaction</b>
                        </p>
                        <p>
                            <i className="far fa-credit-card"></i> Manual
                            <br />
                            {props.orderData.total}
                        </p>
                        <hr />
                        <p>Reason for cancelation</p>
                    </DialogContentText>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={reason}
                        label="Age"
                        onChange={(e) => {
                            setReason(e.target.value);
                        }}
                        // style={{width: '100%', padding: '2px !important', margin: '0px !important'}}
                        // classes={{ root: classes.rootFirstSelect }}
                        sx={{ width: "100%" }}>
                        <MenuItem value={"Customer changed/cancelled order"}>
                            Customer changed/cancelled order
                        </MenuItem>
                        <MenuItem value={"Fraudulent order"}>Fraudulent order</MenuItem>
                        <MenuItem value={"Items unavaiable"}>Items unavailable</MenuItem>
                        <MenuItem value={"Payment declined"}>Payment declined</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        style={{
                            background: "white",
                            color: "#505252",
                            border: "1px solid rgba(0,0,0,0.4)",
                            textTransform: "capitalize",
                        }}>
                        Keep Order
                    </Button>
                    <Button
                        onClick={() => {
                            cancelOrderFn();
                        }}
                        style={{
                            background: "red",
                            color: "white",
                            border: "1px solid red",
                            textTransform: "capitalize",
                        }}>
                        Cancel Order
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
