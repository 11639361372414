import React, { Component } from "react";
import { Box, Button, OutlinedInput, withStyles } from "@material-ui/core";
import Select from "react-select";
import CountryData from "react-select-country-list";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";

const style = (theme) => ({
  textDanger: {
    color: "red",
  },
});

class CustomerAddressInfoModel extends Component {
  constructor(props) {
    super(props);

    this.options = CountryData().getData();

    this.state = {
      options: this.options,
      value: null,
      city: this.props.city,
      country: this.props.country,
      postCode: this.props.postCode,
      stateOrProvince: this.props.stateOrProvince,
      street: this.props.street,
    };
    console.log("cc:", this.state.options);
    console.log(
      "pakval:",
      this.state.options.findIndex((option) =>
        option.label.includes(this.state.country)
      )
    );
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  changeHandler = (value) => {
    this.setState({
      country: value,
    });
  };
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  handleSubmit = (event) => {
    console.log("country state111:", this.state.country);
    //Validation Logic
    if (this.validator.allValid()) {
      this.props.updateAddressInfo(this.state);

      this.forceUpdate();

      setTimeout(() => {
        this.props.history.push({
          pathname: "/Customers",
        });
      }, 1000);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { classes, history } = this.props;
    return (
      <>
        <form>
          <Box margin="0px 0px 10px 10px">
            <Box margin="10px 0px 0px 0px">
              <Box>Address</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "Address",
                this.state.street,
                "required|address",
                { className: classes.textDanger }
              )}
            </Box>
            <Box margin="10px 0px 0px 0px">
              <Box>State Or Province</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="stateOrProvince"
                type="text"
                value={this.state.stateOrProvince}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "state",
                this.state.stateOrProvince,
                "required|state",
                { className: classes.textDanger }
              )}
            </Box>
            <Box margin="10px 0px 0px 0px">
              <Box>City</Box>
              <OutlinedInput
                required
                style={{ width: "99%", height: 40, marginTop: "10px" }}
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "city",
                this.state.city,
                "required|city",
                { className: classes.textDanger }
              )}
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              bgcolor="white"
              margin="10px 0px 0px 0px"
            >
              <Box margin="0px 0px 0px 0px" width="45%">
                <Box>Country/Region</Box>
                <Select
                  //width="100%"
                  name="country"
                  options={this.state.options}
                  value={
                    this.state.options[
                      this.state.options.findIndex((option) =>
                        option.label.includes(this.state.country)
                      )
                    ]
                  }
                  onChange={this.changeHandler}
                />
                {this.validator.message(
                  "country",
                  this.state.country,
                  "required|country",
                  { className: classes.textDanger }
                )}
              </Box>
              <Box margin="0px 0px 0px 0px">
                <Box marginLeft="50px">Postal Code</Box>
                <OutlinedInput
                  style={{
                    width: "90%",
                    height: "50%",
                    marginLeft: "50px",
                    marginTop: "5px",
                  }}
                  name="postCode"
                  type="number"
                  value={this.state.postCode}
                  onChange={this.handleChange}
                />
                {this.validator.message(
                  "postCode",
                  this.state.postCode,
                  "required|postCode",
                  { className: classes.textDanger }
                )}
              </Box>
            </Box>
            {<hr />}
            <Button
              style={{
                textTransform: "none",
                float: "right",
              }}
              onClick={this.handleSubmit}
              variant="contained"
              color="dark"
            >
              Save
            </Button>
            <Button
              style={{
                textTransform: "none",
                marginRight: "10px",
                float: "right",
              }}
              onClick={this.props.onClickBtnClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    g_products: state.productsState.products,
    g_active: state.customersState.active_customers,
    g_archived_customers: state.customersState.archived_customers,
    global_config: state.globalState,
  };
};
export default connect(
  mapStateToProps,
  undefined
)(withStyles(style)(CustomerAddressInfoModel));
