const initial_state = {
  inventoryItems: [],
};

const inventoryReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_INVENTORY_ITEMS":
      console.log(action);
      return Object.assign({}, state, {
        inventoryItems: action.inventoryItems,
      });
    case "EDIT_INVENTORY_ITEM_QUANTITY":
      const updated_inventoryItem = action.inventoryItems;
      console.log("Update Item", updated_inventoryItem);

      return {
        ...state,
        inventoryItems: state.inventoryItems.map((item) => {
          if (item.POID === updated_inventoryItem.POID) {
            return { ...item, quantity: updated_inventoryItem.quantity };
          }
          return item;
        }),
      };

    // case "EDIT_INVENTORY_ITEM_QUANTITY_PURCHASE":
    //   const updatedInventoryItems = action.inventoryItems; // Assuming it's an array
    //   console.log("Updated Items", updatedInventoryItems);

    //   return {
    //     ...state,
    //     inventoryItems: state.inventoryItems.map((item) => {
    //       // Find the corresponding updated item from the response
    //       const updatedItem = updatedInventoryItems.find(
    //         (updated) => updated.Product_Id === item.POID
    //       );

    //       console.log("inside redux update product",updatedItem)

    //       // If an updated item exists, update its quantity
    //       return updatedItem ? { ...item, quantity:  item.quantity+updatedItem.quantity } : item;
    //     }),
    //   };

    case "EDIT_INVENTORY_ITEM_QUANTITY_PURCHASE":
      const updatedInventoryItems = action.inventoryItems;
      const updatedInventoryItemqtyAccepted = action.qty;
      const singleInventoryDetail = action.singleItemInventory;
      console.log(
        "Updated Items",
        singleInventoryDetail.quantity,
        updatedInventoryItemqtyAccepted
      );

      const newInventoryItems = state.inventoryItems.map((item) => {
        const updatedItem = updatedInventoryItems.find(
          (updated) => updated.Product_Id === item.POID
        );

        console.log("inside redux update product", updatedItem);

        return updatedItem
          ? {
              ...item,
              quantity:
                Number(singleInventoryDetail.quantity) +
                Number(updatedInventoryItemqtyAccepted),
            }
          : item;
      });

      console.log("Previous State:", state.inventoryItems);
      console.log("Next State:", newInventoryItems);

      return {
        ...state,
        inventoryItems: [...newInventoryItems],
      };

    case "EDIT_INVENTORY_ITEM_QUANTITY_ON_ORDER": {
      for (let i = 0; i < action.id.length; i++) {
        let inventoryItem = state.inventoryItems.filter(
          (inventoryItem) => inventoryItem.POID == action.id[i]
        );
        inventoryItem[0].quantity =
          inventoryItem[0]?.quantity - action?.quantity[i];
      }
      return state;
    }
    case "EDIT_INVENTORY_ITEM": {
      let updated_inventoryItem = action.inventoryItem;
      let inventoryItem = state.inventoryItems.filter(
        (inventoryItem) => inventoryItem.POID === updated_inventoryItem.POID
      );
      inventoryItem = inventoryItem[0];
      if (inventoryItem.quantity !== updated_inventoryItem.quantity) {
        inventoryItem.quantity = updated_inventoryItem.quantity;
      }
      if (
        inventoryItem.unitOfMeasureId !== updated_inventoryItem.unitOfMeasureId
      ) {
        inventoryItem.unitOfMeasureId = updated_inventoryItem.unitOfMeasureId;
      }
      return state;
    }
    case "CLEAR_INVENTORY_DATA":
      return initial_state;
    default:
      return state;
  }
};

export default inventoryReducer;
